import React from "react";
import {
	Drawer,
	DrawerBody,
	DrawerHeader,
	DrawerOverlay,
	DrawerContent,
	DrawerCloseButton,
	useDisclosure,
	SimpleGrid,
	Box,
	Image,
	IconButton,
	Flex,
	Button,
	ScaleFade,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import Image1 from "../../images/ex_menu/3.jpg";
import Image2 from "../../images/ex_menu/4.jpg";
import Image3 from "../../images/ex_menu/5.jpg";
import Image4 from "../../images/ex_menu/6.jpg";
import Image5 from "../../images/ex_menu/7.jpg";
import Image6 from "../../images/ex_menu/8.jpg";
import Image7 from "../../images/ex_menu/9.jpg";
import Image8 from "../../images/ex_menu/10.jpg";
import Image9 from "../../images/ex_menu/11.jpg";
import Image10 from "../../images/ex_menu/12.jpg";
import Image11 from "../../images/ex_menu/13.jpg";
import Image12 from "../../images/ex_menu/14.jpg";
import Image13 from "../../images/ex_menu/16.png";
import Image14 from "../../images/ex_menu/15.jpg";
import Image15 from "../../images/ex_menu/17.jpg";

function ExampleDesignQRCode() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	const btnRef = React.useRef();

	const images = [
		Image1,
		Image2,
		Image3,
		Image4,
		Image5,
		Image6,
		Image7,
		Image8,
		Image9,
		Image10,
		Image11,
		Image12,
		Image13,
		Image14,
		Image15,
	];

	const downloadImage = (src) => {
		const link = document.createElement("a");
		link.href = src;
		link.download = src.substring(src.lastIndexOf("/") + 1);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<Box bgColor={"white"}>
				<Image src={Image8} />
			</Box>
			<Flex mt={2} justifyContent="center">
				<Button
					colorScheme="teal"
					w={"full"}
					ref={btnRef}
					onClick={onOpen}
					borderRadius={"none"}
				>
					ตัวอย่างป้าย
				</Button>
			</Flex>

			<Drawer
				isOpen={isOpen}
				placement="right"
				onClose={onClose}
				finalFocusRef={btnRef}
				size={"lg"}
			>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerCloseButton />
					<DrawerHeader>ตัวอย่างป้าย QR Menu</DrawerHeader>

					<DrawerBody>
						<SimpleGrid columns={{ base: 2, md: 3 }} spacing={5}>
							{images.map((src, index) => (
								<ScaleFade initialScale={0.5} in={true} key={index}>
									<Box position="relative">
										<Image src={src} alt={`Image ${index + 1}`} />
										<IconButton
											aria-label="Download Image"
											icon={<DownloadIcon />}
											size="md"
											bgColor="white"
											color="black"
											position="absolute"
											bottom="8px"
											right="8px"
											zIndex="1"
											onClick={() => downloadImage(src)}
										/>
									</Box>
								</ScaleFade>
							))}
						</SimpleGrid>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
		</>
	);
}

export default ExampleDesignQRCode;
