import React from "react";
import { Box, Image } from "@chakra-ui/react";
import rescanImg from "../images/rescan.jpg";

function SendReScan() {
	return (
		<Box
			display="flex"
			justifyContent="center"
			alignItems="center"
			height="100vh"
			width="100vw"
			overflow="hidden"
			position="relative"
		>
			<Image
				src={rescanImg}
				alt="QR Code"
				objectFit="cover"
				position="absolute"
				top="0"
				left="0"
				width="100%"
				height="100%"
			/>
		</Box>
	);
}

export default SendReScan;
