import React, { createContext, useState } from "react";

// Create a new context
const ShopContext = createContext();

// Create a provider component
export const ShopProvider = ({ children }) => {
	const [shopValueId, setShopValueId] = useState(null);

	const setShopId = (id) => {
		setShopValueId(id);
	};

	return (
		<ShopContext.Provider value={{ shopValueId, setShopId }}>
			{children}
		</ShopContext.Provider>
	);
};

export default ShopContext;
