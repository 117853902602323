import React, { useState, useEffect, useRef } from "react";
import Swal from "sweetalert2";
import {
	Box,
	Text,
	useToast,
	Switch,
	FormControl,
	FormLabel,
	Flex,
	TableContainer,
	Table,
	Thead,
	Tr,
	Th,
	Tbody,
	Td,
	Image,
	Popover,
	PopoverTrigger,
	PopoverContent,
	Link,
	Input,
	Button,
} from "@chakra-ui/react";
import { ViewIcon, RepeatIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../../config/apiConfig";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";

function Detail() {
	const [responseData, setResponseData] = useState({
		max: null,
		active: null,
		free: null,
	});
	const [isChecking, setIsChecking] = useState(false);
	const [chartData, setChartData] = useState({
		labels: [],
		datasets: [
			{
				label: "Active Connections",
				data: [],
				borderColor: "teal",
				backgroundColor: "rgba(0, 128, 128, 0.2)",
				tension: 0.4, // Smooth line
			},
		],
	});
	const [chartOptions, setChartOptions] = useState({
		scales: {
			y: {
				beginAtZero: true,
				min: 0,
			},
		},
	});
	const [maxActive, setMaxActive] = useState(null); // State to track max active
	const toast = useToast();
	const intervalRef = useRef(null);

	useEffect(() => {
		const handleGetRequest = async () => {
			try {
				const response = await fetch(`${API_BASE_URL}/monitor/checkconnection`);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				setResponseData({
					max: data.max,
					active: data.active,
					free: data.free,
				});
				updateChartData(data.active);
			} catch (error) {
				console.error("Error fetching data:", error);
				toast({
					title: "Error Fetching Data",
					description: error.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		};

		if (isChecking) {
			intervalRef.current = setInterval(handleGetRequest, 2000); // Adjust interval time as needed
		} else {
			clearInterval(intervalRef.current);
		}

		return () => clearInterval(intervalRef.current);
	}, [isChecking, toast]);

	const updateChartData = (active) => {
		setChartData((prevData) => {
			const labels = [...prevData.labels, new Date().toLocaleTimeString()];
			const data = [...prevData.datasets[0].data, active];

			// Keep only the last 20 points
			if (labels.length > 20) {
				labels.shift();
				data.shift();
			}

			// Update max active value
			setMaxActive((prevMax) => {
				return prevMax !== null ? Math.max(prevMax, active) : active;
			});

			// Calculate dynamic y-axis min and max
			const min = Math.min(...data) - 5;
			const max = Math.max(...data) + 5;

			setChartOptions({
				scales: {
					y: {
						beginAtZero: true,
						min: min,
						max: max,
					},
				},
			});

			return {
				labels,
				datasets: [
					{
						...prevData.datasets[0],
						data,
					},
				],
			};
		});
	};

	const [userPaymentData, setUserPaymentData] = useState([]);
	const handleGetRequest = async () => {
		try {
			const response = await fetch(
				`${API_BASE_URL}/monitor/checkCustomerPayment`
			);

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			setUserPaymentData(data);
		} catch (error) {
			console.error("Error fetching data:", error);
			toast({
				title: "Error Fetching Data",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	const handleRenewAccept = async (id) => {
		// alert(id);
		Swal.fire({
			text: "ตรวจสอบแล้ว",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await fetch(
						`${API_BASE_URL}/monitor/adminUpdateRenew`,
						{
							method: "POST", // Change to POST method
							headers: {
								"Content-Type": "application/json", // Set content type to JSON
							},
							body: JSON.stringify({ id }), // Add body with JSON payload
						}
					);

					if (!response.ok) {
						throw new Error("Network response was not ok");
					}

					const data = await response.json();
					if (data.message === "success") {
						toast({
							title: "Update success",
							status: "success",
							duration: 5000,
							isClosable: true,
						});
						// Remove the renewed user payment data from the state
						setUserPaymentData((prevData) =>
							prevData.filter((item) => item.id !== id)
						);
					}
				} catch (error) {
					console.error("Error fetching data:", error);
					toast({
						title: "Error Fetching Data",
						description: error.message,
						status: "error",
						duration: 5000,
						isClosable: true,
					});
				}
			}
		});
	};

	const handleBackOldDate = async (id) => {
		// alert(id);
		Swal.fire({
			text: "กลับไปวันเดิม",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await fetch(`${API_BASE_URL}/monitor/backOldDate`, {
						method: "POST", // Change to POST method
						headers: {
							"Content-Type": "application/json", // Set content type to JSON
						},
						body: JSON.stringify({ id }), // Add body with JSON payload
					});

					if (!response.ok) {
						throw new Error("Network response was not ok");
					}

					const data = await response.json();
					if (data.message === "success") {
						toast({
							title: "Update success",
							status: "success",
							duration: 5000,
							isClosable: true,
						});
						// Remove the renewed user payment data from the state
						setUserPaymentData((prevData) =>
							prevData.filter((item) => item.id !== id)
						);
					}
				} catch (error) {
					console.error("Error fetching data:", error);
					toast({
						title: "Error Fetching Data",
						description: error.message,
						status: "error",
						duration: 5000,
						isClosable: true,
					});
				}
			}
		});
	};

	// price management
	const [nowPriceData, setNowPriceData] = useState([]);
	useEffect(() => {
		const handleGetPriceData = async () => {
			try {
				const response = await fetch(`${API_BASE_URL}/monitor/getPriceData`);

				if (!response.ok) {
					throw new Error("Network response was not ok");
				}

				const data = await response.json();
				// console.log("data", data[0]);
				setNowPriceData(data[0]);
			} catch (error) {
				console.error("Error fetching data:", error);
				toast({
					title: "Error Fetching Data",
					description: error.message,
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		};
		handleGetPriceData();
	}, [toast]);

	const updatePriceData = async () => {
		try {
			const response = await fetch(`${API_BASE_URL}/monitor/updatePriceData`, {
				method: "POST", // Change to POST method
				headers: {
					"Content-Type": "application/json", // Set content type to JSON
				},
				body: JSON.stringify({ nowPriceData }), // Add body with JSON payload
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (data.message === "success") {
				toast({
					title: "Update success",
					status: "success",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error("Error fetching data:", error);
			toast({
				title: "Error Fetching Data",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			<Flex alignItems="center" justifyContent="center" w={"100%"}>
				<Flex direction="row" p={10} gap={3} w={"full"}>
					<Box w={"35%"}>
						<Box p={5}>
							<FormControl display="flex" alignItems="center" mb={3}>
								<FormLabel htmlFor="check-connection" mb="0">
									Check Connection
								</FormLabel>
								<Switch
									id="check-connection"
									isChecked={isChecking}
									onChange={() => setIsChecking(!isChecking)}
								/>
							</FormControl>
							{responseData && (
								<>
									<Box>
										<Text fontWeight="bold">Response Data:</Text>
										<Text>{JSON.stringify(responseData, null, 2)}</Text>
										<Text fontWeight="bold" mt={4}>
											Max Active Connections All Time:{" "}
											{maxActive !== null ? maxActive : "N/A"}
										</Text>
										<Box mt={4} p={3} borderWidth={1} borderRadius="md">
											<Bar
												data={chartData}
												options={chartOptions}
												height={400}
												width={800}
											/>
										</Box>
									</Box>
								</>
							)}
						</Box>
						<Box p={5} bgColor={"gray.50"} borderRadius={"1em"}>
							<Text>PRICE</Text>
							<Input
								type="number"
								min={1}
								border={"none"}
								bgColor={"white"}
								value={nowPriceData?.price}
								onChange={(e) =>
									setNowPriceData({ ...nowPriceData, price: e.target.value })
								}
							/>
							<Text>promotion_number</Text>
							<Input
								border={"none"}
								bgColor={"white"}
								value={nowPriceData?.promotion_number}
								onChange={(e) =>
									setNowPriceData({
										...nowPriceData,
										promotion_number: e.target.value,
									})
								}
							/>
							<Text>promotion_percent</Text>
							<Input
								border={"none"}
								bgColor={"white"}
								value={nowPriceData?.promotion_percent}
								onChange={(e) =>
									setNowPriceData({
										...nowPriceData,
										promotion_percent: e.target.value,
									})
								}
							/>
							<Text>count</Text>
							<Input
								border={"none"}
								bgColor={"white"}
								value={nowPriceData?.count}
								onChange={(e) =>
									setNowPriceData({
										...nowPriceData,
										count: e.target.value,
									})
								}
							/>
							<Flex justifyContent="flex-end" mt={4}>
								<Button onClick={updatePriceData}>UPDATE</Button>
							</Flex>
						</Box>
					</Box>
					<Box w={"65%"} mb={4} p={5} borderWidth={1} borderRadius="md">
						<Text fontWeight="bold" mb={4}>
							Customer Payment: <Link onClick={handleGetRequest}>UPDATE</Link>
						</Text>
						<TableContainer>
							<Table size="sm" variant="simple">
								<Thead>
									<Tr>
										<Th>ID</Th>
										<Th>Fullname</Th>
										<Th>detail payment</Th>
										<Th>Reject-Image-Accept</Th>
									</Tr>
								</Thead>
								<Tbody>
									{userPaymentData.map((row) => (
										<Tr key={row.id}>
											<Td>{row.id}</Td>
											<Td>
												<Box color={"cyan.700"}>
													{row.first_name} {row.last_name}{" "}
												</Box>
												<Flex>
													<Text fontWeight={"bold"} pr={1}>
														email:
													</Text>
													{row.email}
												</Flex>
												<Flex>
													<Text fontWeight={"bold"} pr={1}>
														วันที่สมัคร:
													</Text>
													{convertToThaiDate(row.created_datetime)}
												</Flex>
												<Flex>
													<Text fontWeight={"bold"} pr={1}>
														วันสิ้นสุดเดิม:
													</Text>
													{convertToThaiDate(row.date_before_payment)}
												</Flex>
												<Flex>
													<Text fontWeight={"bold"} pr={1}>
														วันสิ้นสุดใหม่:
													</Text>
													{convertToThaiDate(row.last_day)}
												</Flex>
											</Td>
											<Td>
												<Text fontWeight={"bold"}>แผน {row.price} บาท</Text>
												<Text fontWeight={"bold"}>ต่ออายุ {row.plan} วัน</Text>
												<Text fontWeight={"bold"}>
													ยอดโอน {row.plan * row.price} วัน
												</Text>
												<Flex>
													<Text fontWeight={"bold"} pr={1}>
														วันเวลาโอน:
													</Text>
													{convertToThaiDate(row.date_payment)}
												</Flex>
											</Td>
											<Td>
												<Flex
													justifyContent="center"
													alignItems="center"
													gap={2}
												>
													<WarningTwoIcon
														cursor="pointer"
														fontSize="xl"
														color="red"
														onClick={() => handleBackOldDate(row.id)}
													/>
													{row.image_payment && (
														<Popover trigger="hover" placement="left-start">
															<PopoverTrigger>
																<ViewIcon
																	cursor="pointer"
																	fontSize="xl"
																	color="purple"
																/>
															</PopoverTrigger>
															<PopoverContent>
																<Image
																	src={`${API_BASE_URL}/image/payment/${row.image_payment}`}
																/>
															</PopoverContent>
														</Popover>
													)}
													<RepeatIcon
														cursor="pointer"
														fontSize="xl"
														color="cyan.700"
														onClick={() => handleRenewAccept(row.id)}
													/>
												</Flex>
											</Td>
										</Tr>
									))}
								</Tbody>
							</Table>
						</TableContainer>
					</Box>
				</Flex>
			</Flex>
		</>
	);
}
const convertToThaiDate = (dateTimeString) => {
	// console.log(dateTimeString);
	const dateTime = new Date(dateTimeString);
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
	};
	const thaiDateTime = dateTime.toLocaleString("th-TH", options);
	return thaiDateTime;
};
export default Detail;
