import React, { useState, useCallback, useEffect } from "react";
import {
	Flex,
	Box,
	Text,
	Image,
	Stack,
	useBreakpointValue,
} from "@chakra-ui/react";

const SLIDE_CHANGE_THRESHOLD = 100;
const AUTO_SLIDE_INTERVAL = 5000; // 5 seconds

const arrowStyles = {
	cursor: "pointer",
	pos: "absolute",
	top: "50%",
	w: "auto",
	mt: "-22px",
	p: "16px",
	color: "white",
	fontWeight: "bold",
	fontSize: "18px",
	transition: "0.6s ease",
	borderRadius: "0 3px 3px 0",
	userSelect: "none",
	_hover: {
		opacity: 0.8,
		bg: "black",
	},
};

const convertSlides = (slides, slidesPerGroup) => {
	const groupedSlides = [];

	for (let i = 0; i < slides.length; i += slidesPerGroup) {
		groupedSlides.push(slides.slice(i, i + slidesPerGroup));
	}

	return groupedSlides;
};

const Carousels = ({ initialSlides }) => {
	const [currentSlide, setCurrentSlide] = useState(0);
	const [dragging, setDragging] = useState(false);
	const [dragStartX, setDragStartX] = useState(0);
	const [dragOffset, setDragOffset] = useState(0);
	const isMobile = useBreakpointValue({ base: 1, md: 2, lg: 3, xl: 4 });

	const slides = convertSlides(initialSlides, isMobile);

	const slidesCount = slides.length * isMobile - (isMobile - 1);

	const prevSlide = useCallback(() => {
		setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
	}, [slidesCount]);

	const nextSlide = useCallback(() => {
		setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
	}, [slidesCount]);

	const handleMouseDown = (e) => {
		setDragging(true);
		setDragStartX(e.clientX);
		e.preventDefault();
	};

	const handleMouseMove = (e) => {
		if (dragging) {
			const diffX = e.clientX - dragStartX;
			setDragOffset(diffX);
			e.preventDefault();
		}
	};

	const handleMouseUp = () => {
		if (dragging) {
			setDragging(false);

			if (Math.abs(dragOffset) > SLIDE_CHANGE_THRESHOLD) {
				const slideChange = dragOffset > 0 ? prevSlide : nextSlide;
				slideChange();
			}

			setDragOffset(0);
		}
	};

	useEffect(() => {
		const interval = setInterval(nextSlide, AUTO_SLIDE_INTERVAL);
		return () => clearInterval(interval);
	}, [nextSlide]);

	const slideOffset =
		currentSlide === 0
			? Math.min(dragOffset, 0)
			: currentSlide === slidesCount - 1
			? Math.max(dragOffset, 0)
			: dragOffset;

	const carouselStyle = {
		transition: dragging ? "none" : "all .5s",
		ml: `calc(-${(currentSlide * 100) / isMobile}% + ${slideOffset}px)`,
	};

	return (
		<Flex
			w="full"
			pt={"4em"}
			alignItems="center"
			justifyContent="center"
			style={{ cursor: dragging ? "grabbing" : "auto" }}
			onMouseLeave={handleMouseUp}
		>
			<Flex w="full" overflow="hidden" pos="relative">
				<Flex
					h={{ base: "250px", md: "300px", lg: "350px" }}
					w="full"
					onMouseUp={handleMouseUp}
					onMouseMove={handleMouseMove}
					onMouseDown={handleMouseDown}
					{...carouselStyle}
				>
					{slides.map((slideGroup, sid) => (
						<Box key={`slide-${sid}`} boxSize="full" flex="none">
							<Flex
								w="full"
								h="full"
								direction={{ base: "column", md: "row" }}
								gap={{ base: 4, md: 6, xl: 8 }}
								px={{ base: 4, md: 3, xl: 4 }}
							>
								{slideGroup.map((slide, imgIndex) => (
									<Box
										key={`slide-${sid}-${imgIndex}`}
										w={{ base: "100%", md: "50%" }}
										h="full"
										pos="relative"
									>
										<Image
											src={slide.img}
											alt={`carousel image ${sid}-${imgIndex}`}
											boxSize="full"
											objectFit="cover"
											borderRadius={{ base: "1em", md: "1.5em" }}
										/>
										<Stack
											pos="absolute"
											bottom={{ base: "15px", md: "22px" }}
											textAlign="center"
											w="full"
										>
											<Text
												fontSize="xl"
												color="white"
												textShadow={"0 0 4px black"}
											>
												{slide.label}
											</Text>
										</Stack>
									</Box>
								))}
							</Flex>
						</Box>
					))}
				</Flex>
				<Text {...arrowStyles} left="0" onClick={prevSlide}>
					&#10094;
				</Text>
				<Text {...arrowStyles} right="0" onClick={nextSlide}>
					&#10095;
				</Text>
			</Flex>
		</Flex>
	);
};

export default Carousels;
