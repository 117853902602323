import React, { useEffect, useState } from "react";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	Button,
	Flex,
	Text,
	useDisclosure,
} from "@chakra-ui/react";
import { Icon } from "@iconify/react";
import { useTranslation } from "react-i18next";

function ChangeLanguage({ setNowLanguageCorePage }) {
	const { i18n } = useTranslation();
	const [nowLanguage, setNowLanguage] = useState(
		localStorage.getItem("language") || "th"
	);

	useEffect(() => {
		i18n.changeLanguage(nowLanguage);
		localStorage.setItem("language", nowLanguage);
	}, [i18n, nowLanguage]);

	const changeLanguage = (lng) => {
		i18n.changeLanguage(lng);
		setNowLanguage(lng);
		setNowLanguageCorePage(lng);
	};

	const { isOpen, onOpen, onClose } = useDisclosure();

	const handleLanguageChange = (language) => {
		changeLanguage(language);
		onClose();
	};

	const languageIcons = {
		en: "emojione:flag-for-united-kingdom",
		th: "emojione:flag-for-thailand",
		cn: "emojione:flag-for-china",
	};

	return (
		<>
			<Button variant="link" minW={"auto"} onClick={onOpen}>
				<Icon icon={languageIcons[nowLanguage]} boxSize={6} />
			</Button>

			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent bg="#ffffff00" boxShadow={"none"} py={3}>
					<ModalBody>
						<Flex direction="column" gap={3}>
							<Button
								borderRadius={"none"}
								bgColor="orange.200"
								onClick={() => handleLanguageChange("th")}
							>
								<Flex>
									<Icon icon={languageIcons["th"]} boxSize={4} />
									<Text mt={-1} ml={2} fontWeight={"300"}>
										ภาษาไทย
									</Text>
								</Flex>
							</Button>
							<Button
								borderRadius={"none"}
								bgColor="orange.200"
								onClick={() => handleLanguageChange("en")}
							>
								<Flex>
									<Icon icon={languageIcons["en"]} boxSize={4} />
									<Text mt={-1} ml={2} fontWeight={"300"}>
										English
									</Text>
								</Flex>
							</Button>
							<Button
								onClick={() => handleLanguageChange("cn")}
								_dark={{
									colorScheme: "whiteAlpha",
								}}
								display={"none"}
							>
								<Flex>
									<Icon icon={languageIcons["cn"]} boxSize={4} />
									<Text mt={-1} ml={2} fontWeight={"300"}>
										中文
									</Text>
								</Flex>
							</Button>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default ChangeLanguage;
