import React from "react";
import { Text, Image, Box, SimpleGrid } from "@chakra-ui/react";
import customer_homepage from "../../../images/manual/customer_homepage.png";
import menu_page from "../../../images/manual/menu_page.png";
import menu_select from "../../../images/manual/menu_select.png";
import basket from "../../../images/manual/basket.png";
import alert_menu from "../../../images/manual/alert_menu.png";
import call_staff from "../../../images/manual/call_staff.png";

function CustomerTest() {
	return (
		<>
			<SimpleGrid
				columns={{ base: 2, md: 2, lg: 3, xl: 4 }}
				spacing={{ base: 3, md: 3, lg: 5, xl: 7 }}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={customer_homepage} w={"full"} />
					<Text>หน้ากรอกโค๊ต</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={menu_page} w={"full"} />
					<Text>หน้าเมนู</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={menu_select} w={"full"} />
					<Text>แสดงรายละเอียดสินค้า</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={basket} w={"full"} />
					<Text>ตะกร้าสินค้า</Text>
				</Box>
				<Box display="flex" flexDirection="column" textAlign={"center"}>
					<Image src={alert_menu} w={"full"} />
					<Text>แจ้งเตือนออเดอร์มาที่แอพ LINE</Text>
				</Box>
				<Box display="flex" flexDirection="column" textAlign={"center"}>
					<Image src={call_staff} w={"full"} />
					<Text>แจ้งเตือนกรณีเรียกพนักงาน</Text>
				</Box>
			</SimpleGrid>
		</>
	);
}

export default CustomerTest;
