import React from "react";
import { Outlet } from "react-router-dom";
import { Box } from "@chakra-ui/react";
import { ShopProvider } from "../hooks/ShopContext";

import Header from "../components/Header";
// import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const PublicRoute = () => {
	return (
		<ShopProvider>
			<Header />
			{/* <Navbar /> */}
			<Box>
				<Outlet />
			</Box>
			<Footer />
		</ShopProvider>
	);
};

export default PublicRoute;
