import { useContext, createContext, useState } from "react";
import { useToast } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { API_BASE_URL } from "../config/apiConfig";

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
	const toast = useToast();
	const [user, setUser] = useState(
		JSON.parse(localStorage.getItem("user")) || []
	);
	const navigate = useNavigate();
	const loginAction = async (data, setIsLoadingSave) => {
		// console.log(data);
		try {
			const res = await axios.post(`${API_BASE_URL}/api/authen/login`, data);
			// console.log(res.data);
			if (res.data.message === "success") {
				setUser(res.data.user);
				// console.log(res.data);
				localStorage.setItem("user", JSON.stringify(res.data.user));
				navigate("/dashboard");
				return;
			} else {
				toast({
					title: res.data.message,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
		} catch (err) {
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
			// console.error(err);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const logOut = () => {
		setUser([]);
		localStorage.removeItem("user");
		if (user.role === "admin") {
			navigate("/loginadmin");
		} else {
			navigate("/login");
		}
	};

	const updateUser = ({ first_name, last_name }) => {
		// console.log("updateUser", first_name, last_name);
		const existingUser = JSON.parse(localStorage.getItem("user"));
		const updatedUser = {
			...existingUser,
			first_name: first_name,
			last_name: last_name,
		};
		localStorage.setItem("user", JSON.stringify(updatedUser));
		setUser(updatedUser);
	};

	return (
		<AuthContext.Provider value={{ user, loginAction, logOut, updateUser }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;

export const useAuth = () => {
	return useContext(AuthContext);
};
