import React from "react";
import {
	Container,
	Stack,
	Flex,
	Box,
	Heading,
	Text,
	Image,
	Icon,
	useColorModeValue,
} from "@chakra-ui/react";
import { FcPaid } from "react-icons/fc";
import { FaLine } from "react-icons/fa6";
import line_notify from "../images/line_notify.png";
import homepage from "../images/homepage.png";
import RegisterButton from "./component/RegisterButton";

function SalePage() {
	return (
		<>
			<Container maxW={"container.xl"}>
				<Stack
					align={"center"}
					spacing={{ base: 8, md: 10 }}
					py={{ base: 8, md: 16 }}
					direction={{ base: "column", md: "row" }}
				>
					<Stack flex={1} spacing={{ base: 5, md: 10 }} position="relative">
						<Heading
							lineHeight={1.1}
							fontWeight={600}
							fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}
						>
							<Text
								as={"span"}
								position={"relative"}
								pr={20}
								_after={{
									content: "''",
									width: "full",
									height: "25%",
									position: "absolute",
									bottom: 1,
									left: 0,
									bg: "orange.300",
									zIndex: -1,
								}}
							>
								สร้างร้าน
							</Text>
							<br />
							<Text as={"span"} color={"cyan.700"}>
								บนระบบ QR Menu!
							</Text>
						</Heading>
						<Box color="gray.600" pos={"relative"}>
							เราเป็นแพลตฟอร์มการสร้างร้านออนไลน์ ภายใต้ระบบ QR Menu
							สำหรับนำเสนอสินค้าหรือบริการ โดยแจ้งเตือนออเดอร์ไปยังแอพพลิเคชั่น
							LINE
							<Text
								pos={"absolute"}
								right={10}
								top={-6}
								transform="rotate(15deg)"
								filter={"opacity(0.1)"}
								zIndex={-1}
							>
								<FaLine color="green" size={"6em"} />
							</Text>
							<Text
								pos={"absolute"}
								right={"9em"}
								top={-10}
								transform="rotate(-15deg)"
								filter={"opacity(0.15)"}
								zIndex={-1}
							>
								<Image src={line_notify} size="1em" />
							</Text>
						</Box>
						<Stack
							spacing={{ base: 4, md: 6 }}
							direction={"row"}
							alignItems="center"
							justify={{ base: "center", md: "flex-start" }}
						>
							<RegisterButton businessType={"restaurant"} />
						</Stack>
					</Stack>

					<Flex
						flex={1}
						justify={"center"}
						align={"center"}
						position={"relative"}
						w={"full"}
					>
						<Blob
							w={"100%"}
							h={"150%"}
							position={"absolute"}
							top={"-20%"}
							left={0}
							zIndex={-1}
							color={useColorModeValue("cyan.50", "cyan.400")}
						/>
						<Box
							position={"relative"}
							height={{ base: "auto", md: "450px" }}
							width={"full"}
							overflow={"hidden"}
						>
							<Image
								alt={"Hero Image"}
								fit={"scale-down"}
								align={"center"} // Center align the image horizontally
								mx={"auto"} // Center align the image horizontally
								w={{ base: "85%", md: "100%" }}
								h={"100%"}
								src={homepage}
							/>
						</Box>
					</Flex>
				</Stack>
			</Container>

			<Box py={{ base: 0, md: 4 }} px={{ base: 0, md: 4 }}>
				<Stack spacing={4} as={Container} maxW={"3xl"} textAlign={"center"}>
					<Heading fontSize={{ base: "2xl", sm: "4xl" }} fontWeight={"bold"}>
						ขั้นตอนการใช้งาน
					</Heading>
				</Stack>

				<Container maxW={"7xl"} mt={{ base: 2, md: 8 }}>
					<Flex flexWrap="wrap" gridGap={{ base: 2, md: 6 }} justify="center">
						<Card
							heading={"1. สแกน QR Menu"}
							icon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="2.5em"
									height="2.5em"
									viewBox="0 0 20 20"
								>
									<g fill="#177696">
										<g opacity="0.2">
											<path d="M5 5.5a.5.5 0 0 1 .5-.5H18a.5.5 0 0 1 .5.5V18a.5.5 0 0 1-.5.5H5.5A.5.5 0 0 1 5 18z" />
											<path
												fill-rule="evenodd"
												d="M6.5 6.5V17H17V6.5zM5.5 5a.5.5 0 0 0-.5.5V18a.5.5 0 0 0 .5.5H18a.5.5 0 0 0 .5-.5V5.5A.5.5 0 0 0 18 5z"
												clip-rule="evenodd"
											/>
										</g>
										<path
											fill-rule="evenodd"
											d="M5.5 5.5v3h3v-3zm-.5-1a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5zm6.5 1v3h3v-3zm-.5-1a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5V5a.5.5 0 0 0-.5-.5zm-5.5 7v3h3v-3zm-.5-1a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5z"
											clip-rule="evenodd"
										/>
										<path d="M10.5 11a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 .5.5v4a.5.5 0 0 1-.5.5h-4a.5.5 0 0 1-.5-.5z" />
										<path
											fill-rule="evenodd"
											d="M11.5 11.5v3h3v-3zm-.5-1a.5.5 0 0 0-.5.5v4a.5.5 0 0 0 .5.5h4a.5.5 0 0 0 .5-.5v-4a.5.5 0 0 0-.5-.5zM2.5 3a.5.5 0 0 1 .5-.5h3.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5"
											clip-rule="evenodd"
										/>
										<path
											fill-rule="evenodd"
											d="M3 2.5a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-1 0V3a.5.5 0 0 1 .5-.5m0 15a.5.5 0 0 1-.5-.5v-3.5a.5.5 0 0 1 1 0V17a.5.5 0 0 1-.5.5"
											clip-rule="evenodd"
										/>
										<path
											fill-rule="evenodd"
											d="M2.5 17a.5.5 0 0 1 .5-.5h3.5a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5m15 0a.5.5 0 0 1-.5.5h-3.5a.5.5 0 0 1 0-1H17a.5.5 0 0 1 .5.5"
											clip-rule="evenodd"
										/>
										<path
											fill-rule="evenodd"
											d="M17 17.5a.5.5 0 0 1-.5-.5v-3.5a.5.5 0 0 1 1 0V17a.5.5 0 0 1-.5.5m0-15a.5.5 0 0 1 .5.5v3.5a.5.5 0 0 1-1 0V3a.5.5 0 0 1 .5-.5"
											clip-rule="evenodd"
										/>
										<path
											fill-rule="evenodd"
											d="M17.5 3a.5.5 0 0 1-.5.5h-3.5a.5.5 0 0 1 0-1H17a.5.5 0 0 1 .5.5"
											clip-rule="evenodd"
										/>
									</g>
								</svg>
							}
							description="ร้านติดคิวอาร์โค๊ตไว้ประจำโต๊ะหรือห้องพักให้ลูกค้า"
						/>
						<Card
							heading={"2. กรอกโค๊ตโต๊ะหรือห้องพัก"}
							icon={
								<Flex fontWeight={"bold"}>
									<Text color={"red.600"}>X</Text>
									<Text color={"blue.600"}>X</Text>
									<Text color={"cyan.600"}>X</Text>
									<Text color={"red.600"}>X</Text>
								</Flex>
							}
							description="พนักงานให้โค๊ตสำหรับเข้าซื้อสินค้าแก่ลูกค้า"
						/>
						<Card
							heading={"3. เลือกสินค้าลงตะกร้า"}
							icon={<Icon as={FcPaid} w={10} h={10} />}
							description="ลูกค้าเลือกสินค้าที่ต้องการเก็บไว้ที่ตะกร้า"
						/>
						<Card
							heading={"4. ส่งคำสั่งซื้อ"}
							icon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="3em"
									height="3em"
									viewBox="0 0 24 24"
								>
									<defs>
										<path
											id="letsIconsSendDuotone0"
											fill="#c02687"
											d="m7.692 11.897l1.41.47c.932.31 1.397.466 1.731.8c.334.334.49.8.8 1.73l.47 1.41c.784 2.354 1.176 3.53 1.897 3.53c.72 0 1.113-1.176 1.897-3.53l2.838-8.512c.552-1.656.828-2.484.391-2.921c-.437-.437-1.265-.161-2.92.39l-8.515 2.84C5.34 8.887 4.162 9.279 4.162 10c0 .72 1.177 1.113 3.53 1.897"
										/>
									</defs>
									<use href="#letsIconsSendDuotone0" fill-opacity="0.25" />
									<use href="#letsIconsSendDuotone0" fill-opacity="0.25" />
									<path
										fill="#c02687"
										d="m9.526 13.842l-2.062-.687a1 1 0 0 0-.87.116l-1.09.726a.8.8 0 0 0 .25 1.442l1.955.488a.5.5 0 0 1 .364.364l.488 1.955a.8.8 0 0 0 1.442.25l.726-1.09a1 1 0 0 0 .116-.87l-.687-2.062a1 1 0 0 0-.632-.632"
									/>
								</svg>
							}
							description="ลูกค้าตรวจสอบรายการสินค้าและกดส่งคำสั่งซื้อ"
						/>
						<Card
							heading={"5. แจ้งเตือนออเดอร์"}
							icon={
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="2.5em"
									height="2.5em"
									viewBox="0 0 24 24"
								>
									<path
										fill="#ff9500"
										d="M22 12c0 4.714 0 7.071-1.465 8.535C19.072 22 16.714 22 12 22s-7.071 0-8.536-1.465C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2c4.714 0 7.071 0 8.535 1.464C22 4.93 22 7.286 22 12"
										opacity="0.5"
									/>
									<path
										fill="#ff9500"
										d="M7 16.75a.75.75 0 0 0 0 1.5h6a.75.75 0 0 0 0-1.5zm0-3.5a.75.75 0 0 0 0 1.5h9a.75.75 0 0 0 0-1.5zM22 5a3 3 0 1 1-6 0a3 3 0 0 1 6 0"
									/>
								</svg>
							}
							description="ระบบส่งรายการสั่งซื้อเข้าแอพพลิเคชั่น LINE"
						/>
					</Flex>
				</Container>
			</Box>
		</>
	);
}

const Blob = (props) => {
	return (
		<Icon
			width={"100%"}
			viewBox="0 0 578 440"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
				fill="currentColor"
			/>
		</Icon>
	);
};

const Card = ({ heading, description, icon }) => {
	return (
		<Box
			maxW={{ base: "full", md: "325px" }}
			w={"full"}
			// borderWidth={{ base: "0px", md: "1px" }}
			borderRadius="xl"
			overflow="hidden"
			px={{ base: 2, md: 5 }}
			py={{ base: 2, md: 4 }}
			bgColor={"gray.50"}
		>
			<Stack
				align={"start"}
				spacing={2}
				direction={{ base: "row", md: "column" }}
			>
				<Flex
					minW={16}
					h={16}
					align={"center"}
					justify={"center"}
					color={"white"}
					rounded={"full"}
					bg={useColorModeValue("gray.100", "gray.700")}
				>
					{icon}
				</Flex>
				<Box mt={2}>
					<Heading size={{ base: "sm", md: "md" }} color={"cyan.700"}>
						{heading}
					</Heading>
					<Text mt={1} fontSize={"sm"}>
						{description}
					</Text>
				</Box>
			</Stack>
		</Box>
	);
};

export default SalePage;
