import {
	Box,
	Flex,
	Heading,
	Text,
	Stack,
	Container,
	useColorModeValue,
} from "@chakra-ui/react";

const Testimonial = ({ children }) => {
	return <Box>{children}</Box>;
};

const TestimonialContent = ({ children }) => {
	return (
		<Stack
			bg={useColorModeValue("white", "gray.800")}
			boxShadow={"lg"}
			p={8}
			rounded={"xl"}
			align={"center"}
			pos={"relative"}
			_after={{
				content: `""`,
				w: 0,
				h: 0,
				borderLeft: "solid transparent",
				borderLeftWidth: 16,
				borderRight: "solid transparent",
				borderRightWidth: 16,
				borderTop: "solid",
				borderTopWidth: 16,
				borderTopColor: useColorModeValue("white", "gray.800"),
				pos: "absolute",
				bottom: "-16px",
				left: "50%",
				transform: "translateX(-50%)",
			}}
		>
			{children}
		</Stack>
	);
};

const TestimonialHeading = ({ children }) => {
	return (
		<Heading as={"h3"} fontSize={"xl"} color={"cyan.700"}>
			{children}
		</Heading>
	);
};

const TestimonialText = ({ children }) => {
	return (
		<Text
			textAlign={"center"}
			color={useColorModeValue("gray.600", "gray.400")}
			fontSize={"sm"}
		>
			{children}
		</Text>
	);
};

const TestimonialAvatar = ({ name, title }) => {
	return (
		<Flex align={"center"} mt={8} direction={"column"}>
			<Stack spacing={-1} align={"center"}>
				<Text fontWeight={600}>{name}</Text>
				<Text fontSize={"sm"} color={useColorModeValue("gray.600", "gray.400")}>
					{title}
				</Text>
			</Stack>
		</Flex>
	);
};

function Testimonials() {
	return (
		<Box
			bg={useColorModeValue("white", "gray.800")}
			py={{ base: 5, md: 10, lg: 14, xl: 20 }}
			mb={0}
		>
			<Container maxW={"7xl"} as={Stack} spacing={12}>
				<Stack spacing={0} textAlign={"center"}>
					<Heading>ความรู้สึกหลังจากที่ได้ใช้งาน</Heading>
					<Text mt={2}>ส่วนหนึ่งของความสุขที่ได้รู้จักและนำระบบไปใช้</Text>
				</Stack>
				<Stack
					direction={{ base: "column", md: "row" }}
					spacing={{ base: 10, md: 4, lg: 10 }}
				>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>ใช้ง่ายดี</TestimonialHeading>
							<TestimonialText>
								น้องพนักงานชอบ ตัวเองใช้งานง่าย แนะนำลูกค้าใช้งานก็ไม่ยุ่งยาก
								แยกเป็นโต๊ะดีมากค่ะ
							</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar name={"พัสรี"} title={"ลานเบียร์ศรีเมืองแพ"} />
					</Testimonial>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>รายได้เพิ่มขึ้น</TestimonialHeading>
							<TestimonialText>
								จากที่จะได้แค่ค่าห้องพัก
								เมื่อมีโปรแกรมตัวนี้แล้วสามารถขายของได้เพิ่มขึ้นด้วย
								บางห้องขายได้เยอะกว่าค่าห้องอีก
							</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar name={"นุทิน"} title={"สาทรวัลเลย์"} />
					</Testimonial>
					<Testimonial>
						<TestimonialContent>
							<TestimonialHeading>ลูกค้าชอบมาก</TestimonialHeading>
							<TestimonialText>
								ลูกค้าบอกว่าสั่งง่ายดี ไม่ต้องเรียกพนักงานเดินมาจดออเดอร์
								จะสั่งอะไรก็สแกนสั่งเองได้
							</TestimonialText>
						</TestimonialContent>
						<TestimonialAvatar name={"พรรนิกา"} title={"สวนอาหารท่าช้าง"} />
					</Testimonial>
				</Stack>
			</Container>
		</Box>
	);
}

export default Testimonials;
