import {
	Container,
	Box,
	Heading,
	Text,
	Button,
	Flex,
	ScaleFade,
} from "@chakra-ui/react";
import { FaLine } from "react-icons/fa6";
import { MdPhone, MdEmail, MdLocationOn, MdFacebook } from "react-icons/md";

export default function Contact() {
	return (
		<Flex
			w="auto"
			justifyContent="center"
			alignItems="center"
			minH="calc(100vh - 200px)"
		>
			<Container maxW="container.md">
				<ScaleFade initialScale={0.5} in={true}>
					<Box
						_dark={{ bg: "gray.900" }}
						color="gray.700"
						borderRadius="lg"
						p={{ sm: 5, md: 5, lg: 16 }}
					>
						<Box p={4} textAlign={"center"}>
							<Heading>ช่องทางการติดต่อ</Heading>
							<Text color="gray.500">
								หากมีข้อสงสัยหรือต้องการสอบถามเกี่ยวกับการใช้งาน
							</Text>
							<Flex
								py={{ base: 4, md: 6, lg: 8 }}
								gap={5}
								justifyContent="center"
								alignItems="center"
							>
								<Button
									size="md"
									height="48px"
									width="150px"
									leftIcon={<FaLine fontSize="20px" color="#0987a0" />}
									_hover={{ bg: "none" }}
									onClick={() =>
										window.open("https://lin.ee/R9RbXAm", "_blank")
									}
								>
									LINE
								</Button>
								<Button
									size="md"
									height="48px"
									width="150px"
									leftIcon={<MdFacebook fontSize="20px" color="#0987a0" />}
									_hover={{ bg: "none" }}
									onClick={() =>
										window.open(
											"https://www.facebook.com/100091347363576",
											"_blank"
										)
									}
								>
									Facebook
								</Button>
							</Flex>
							<Flex display={"none"}>
								<Button
									size="md"
									height="48px"
									width="200px"
									leftIcon={<MdPhone color="#1970F1" size="20px" />}
									_hover={"none"}
								>
									087-569-9325
								</Button>
								<Button
									size="md"
									height="48px"
									width="200px"
									variant="ghost"
									leftIcon={<MdEmail color="#1970F1" size="20px" />}
									_hover={"none"}
								>
									sevscan.info@gmail.com
								</Button>
								<Button
									size="md"
									height="48px"
									width="200px"
									variant="ghost"
									leftIcon={<MdLocationOn color="#1970F1" size="20px" />}
									_hover={"none"}
								>
									Karnavati, India
								</Button>
							</Flex>
						</Box>
					</Box>
				</ScaleFade>
			</Container>
		</Flex>
	);
}
