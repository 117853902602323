import React from "react";
import { Text, Image, Box, SimpleGrid } from "@chakra-ui/react";
import em1 from "../../../images/manual/em1.png";
import em2 from "../../../images/manual/em2.png";
import em3 from "../../../images/manual/em3.png";
import em4 from "../../../images/manual/em4.png";
import em5 from "../../../images/manual/em5.png";
import em6 from "../../../images/manual/em6.png";

function EmployeeTest() {
	return (
		<>
			<SimpleGrid
				columns={{ base: 2, md: 2, lg: 3 }}
				spacing={{ base: 3, md: 3, lg: 5, xl: 7 }}
				w={{ base: "auto", md: "auto", lg: "auto", xl: "900px" }}
			>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={em1} w={"full"} />
					<Text mt={2}>หน้าลงชื่อเข้าใช้งานของพนักงาน</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={em2} w={"full"} />
					<Text mt={2}>รายชื่อโต๊ะทั้งหมดของร้าน</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={em3} w={"full"} />
					<Text mt={2}>คลิ๊กที่โต๊ะนั้นๆ จะแสดงเมนูทั้ง 3</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={em4} w={"full"} />
					<Text mt={2}>เมนูคำสั่งแสดงหน้าสั่งซื้อสินค้า</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={em5} w={"full"} />
					<Text mt={2}>เมนูรวมบิลแสดงรายการซื้อทั้งหมด</Text>
				</Box>
				<Box
					display="flex"
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
				>
					<Image src={em6} w={"full"} />
					<Text mt={2}>เมนูเคลียร์โต๊ะ</Text>
				</Box>
			</SimpleGrid>
		</>
	);
}

export default EmployeeTest;
