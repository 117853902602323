import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import socket from "../config/socket";
import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Button,
	Heading,
	useToast,
	Text,
	Image,
	ScaleFade,
	Spacer,
	IconButton,
} from "@chakra-ui/react";
import { IoLogOut } from "react-icons/io5";
import { BeatLoader } from "react-spinners";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../config/apiConfig";
import EmployeeDisplay from "../components/component/employee/EmployeeDisplay";

import imageLoginPublic from "../images/login-public.jpg";

function EmployeeOrder() {
	const toast = useToast();
	const navigate = useNavigate();

	const [showPassword, setShowPassword] = useState(false);
	const [restaurantKey, setRestaurantKey] = useState();
	const [employeeKey, setEmployeeKey] = useState();
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [menuPageStatus, setMenuPageStatus] = useState(false);
	const [shopData, setShopData] = useState();

	useEffect(() => {
		if (localStorage.getItem("employee_login")) {
			const old_code = JSON.parse(localStorage.getItem("employee_login"));
			setRestaurantKey(old_code.restaurantKey);
			setEmployeeKey(old_code.employeeKey);
		}
	}, []);

	const handleSubmit = async () => {
		const trimmedRestaurantKey = restaurantKey.trim();
		const trimmedEmployeeKey = employeeKey.trim();
		// console.log(trimmedRestaurantKey, trimmedEmployeeKey);

		if (!trimmedRestaurantKey || !trimmedEmployeeKey) {
			toast({
				title: `กรอกข้อมูลให้ครบ`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		setIsLoadingSave(true);
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/authen/employee_login`,
				{
					trimmedRestaurantKey,
					trimmedEmployeeKey,
				},
				{}
			);
			// console.log("hi", response.data);
			if (response.data.message === "success") {
				setMenuPageStatus(true);
				setShopData(response.data);
				localStorage.setItem(
					"employee_login",
					JSON.stringify({
						restaurantKey: trimmedRestaurantKey,
						employeeKey: trimmedEmployeeKey,
					})
				);
			} else {
				toast({
					title: `ข้อมูลไม่ถูกต้อง`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
			setIsLoadingSave(false);
		} catch (error) {
			console.error("Error saving employee_login:", error);
			setIsLoadingSave(false);
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	const handleLogout = () => {
		setShopData([]);
		setMenuPageStatus(false);
		socket.emit("leave_room", shopData.shopId);
	};

	return (
		<>
			{menuPageStatus ? (
				<>
					<Flex
						as="header"
						width="100%"
						height="60px"
						alignItems="center"
						padding="0 20px"
						bg="cyan.800"
						boxShadow="sm"
						px={{ base: 4, sm: 6, md: 10, lg: 14, xl: 16 }}
						position={"fixed"}
						top={0}
						zIndex={2}
					>
						<Flex gap={3}>
							<Image
								src={
									shopData.shopLogo !== ""
										? `${API_BASE_URL}/image/logo/${shopData.shopLogo}`
										: `${API_BASE_URL}/image/logo/default.png`
								}
								alt="Logo"
								height="40px"
								filter="drop-shadow(0 0 5px white)"
							/>
							<Box color={"white"} lineHeight="1.2" pt={1}>
								<Text fontWeight={"bold"} fontSize={"lg"}>
									{shopData.shopName}
								</Text>
								<Text fontWeight={"300"} fontSize={"10px"}>
									SEVSCAN
								</Text>
							</Box>
						</Flex>
						<Spacer />
						<IconButton
							aria-label="Exit"
							variant="ghost"
							onClick={handleLogout}
							_hover={"none"}
							icon={<IoLogOut fontSize={20} color="white" />}
						/>
					</Flex>

					<ScaleFade initialScale={0.6} in={true}>
						<Box
							px={{ base: 4, md: 10, lg: 14, xl: 16 }}
							py={{ base: 5, md: 6, lg: 8, xl: 10 }}
							mt={"60px"}
							minH="calc(100vh - 60px)"
						>
							<EmployeeDisplay shopData={shopData} />
						</Box>
					</ScaleFade>
				</>
			) : (
				<Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
					<Flex flex={2}>
						<Image
							alt={"Login Image"}
							objectFit={"cover"}
							src={imageLoginPublic}
							minH={"250px"}
						/>
					</Flex>
					<Flex p={8} flex={1} align={"center"} justify={"center"}>
						<Box w={"full"} minH={"392px"}>
							<Heading
								fontSize={"2xl"}
								color={"cyan.700"}
								mb={3}
								textAlign={"center"}
							>
								ลงชื่อเช้าใช้งาน
							</Heading>
							<Flex direction="row" gap={0} width="100%" mb={3}>
								<Button
									width="100%"
									color={"cyan.700"}
									borderRadius={"none"}
									onClick={() => navigate("/login")}
								>
									เจ้าของธุรกิจ
								</Button>
								<Button
									width="100%"
									bgColor={"cyan.700"}
									color={"white"}
									_hover={"none"}
									_dark={{ bgColor: "cyan.800" }}
									borderRadius={"none"}
								>
									พนักงาน
								</Button>
							</Flex>

							<Stack spacing={4} w={"full"}>
								<FormControl id="restaurant">
									<FormLabel>รหัสร้าน</FormLabel>
									<Input
										type="text"
										value={restaurantKey}
										onChange={(e) => setRestaurantKey(e.target.value)}
									/>
								</FormControl>
								<FormControl id="password">
									<FormLabel>รหัสผ่านพนักงาน</FormLabel>
									<InputGroup>
										<Input
											type={showPassword ? "text" : "password"}
											value={employeeKey}
											onChange={(e) => setEmployeeKey(e.target.value)}
										/>
										<InputRightElement h={"full"}>
											<Button
												variant={"ghost"}
												onClick={() =>
													setShowPassword((showPassword) => !showPassword)
												}
											>
												{showPassword ? <ViewIcon /> : <ViewOffIcon />}
											</Button>
										</InputRightElement>
									</InputGroup>
								</FormControl>
								<Stack spacing={6} pt={2}>
									<Button
										bg={"cyan.700"}
										color={"white"}
										_hover={{
											bg: "cyan.800",
										}}
										onClick={handleSubmit}
										isLoading={isLoadingSave}
										spinner={<BeatLoader size={8} color="white" />}
									>
										ลงชื่อ
									</Button>
									<Box textAlign={"center"}>
										<Text
											color="cyan.700"
											cursor={"pointer"}
											onClick={() => navigate("/")}
										>
											กลับหน้าแรก
										</Text>
									</Box>
								</Stack>
							</Stack>
						</Box>
					</Flex>
				</Stack>
			)}
		</>
	);
}

export default EmployeeOrder;
