import React from "react";
import { Flex, Box, Heading, Text, Stack, Button } from "@chakra-ui/react";
import RegisterButton from "./RegisterButton";

function CardRegister({ price }) {
	const handleButtonClick = () => {
		window.open("https://lin.ee/R9RbXAm", "_blank");
	};

	return (
		<Flex
			bg="gray.100"
			_dark={{
				bg: "gray.700",
			}}
			p={50}
			w="full"
			alignItems="center"
			justifyContent="center"
		>
			<Box>
				<Box
					maxW="7xl"
					w={{ md: "3xl", lg: "4xl" }}
					mx="auto"
					display={{ lg: "flex" }}
					alignItems={{ lg: "center" }}
					justifyContent={{ lg: "space-between" }}
					px={{ base: 4, md: 8, lg: 0 }}
				>
					<Heading
						as={"h2"}
						fontSize={{
							base: "3xl",
							sm: "4xl",
						}}
						letterSpacing="tight"
						lineHeight="shorter"
						color="gray.900"
						_dark={{
							color: "gray.100",
						}}
					>
						<Text display="block" fontWeight="600">
							ลงทะเบียน
						</Text>
						<Text
							fontWeight="400"
							fontSize={26}
							color="gray.600"
							_dark={{
								color: "gray.500",
							}}
						>
							ทดลองใช้งานฟรีเต็มรูปแบบได้เลยตอนนี้
						</Text>
					</Heading>
					<Stack
						direction={{
							base: "column",
							sm: "row",
						}}
						mt={{
							base: 8,
							lg: 0,
						}}
						flexShrink={{
							lg: 0,
						}}
						spacing={3}
					>
						<Button
							color={"cyan.700"}
							bg={"white"}
							_dark={{ bg: "gray.600" }}
							fontWeight={"normal"}
							boxShadow={"md"}
							onClick={handleButtonClick}
							_hover={"none"}
						>
							ติดต่อสอบถาม
						</Button>
						<RegisterButton price={price} businessType={"restaurant"} />
					</Stack>
				</Box>
			</Box>
		</Flex>
	);
}

export default CardRegister;
