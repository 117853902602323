// NotFound.js
import React from "react";
import { Flex, Text, Button } from "@chakra-ui/react";
import { Link } from "react-router-dom"; // If you are using React Router

const NotFound = () => {
	return (
		<Flex direction="column" align="center" justify="center" height="100vh">
			<Text fontSize="6xl" fontWeight="bold">
				404
			</Text>
			<Text fontSize="2xl" fontWeight="bold" mb={4}>
				Page Not Found
			</Text>
			<Text fontSize="md" color="gray.500" mb={8} px={5} textAlign="center">
				The page you are looking for might be in another galaxy.
			</Text>
			<Link to="/">
				<Button colorScheme="teal">Go Home</Button>
			</Link>
		</Flex>
	);
};

export default NotFound;
