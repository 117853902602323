import React from "react";
import {
	Container,
	useColorModeValue,
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Flex,
	Text,
	Box,
	OrderedList,
	ListItem,
	Spacer,
	Link,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	useDisclosure,
} from "@chakra-ui/react";
import LineNotifyManual from "../component/manual/LineNotifyManual";
// import CategoryManual from "../component/manual/CategoryManual";
import CustomerTest from "../component/manual/CustomerTest";
import EmployeeTest from "../component/manual/EmployeeTest";
import manual_pdf from "../../file/Manual.pdf";
import { useAuth } from "../../hooks/AuthProvider";

function Manual() {
	const bgColor = useColorModeValue("cyan.700", "cyan.900");
	const auth = useAuth();

	const bType = auth.user.business_type;

	return (
		<>
			<Flex display={"none"}>
				<Link href={manual_pdf} isExternal>
					คู่มือ
				</Link>
			</Flex>
			<Container
				mt={{ base: "auto", md: 3 }}
				maxW={"6xl"}
				p={{ base: 3, md: 7 }}
				bgColor={"white"}
			>
				<Accordion allowToggle>
					<Box
						p={4}
						bgColor={"cyan.700"}
						color={"gray.200"}
						fontWeight={"bold"}
						textAlign={"center"}
					>
						เจ้าของธุรกิจ
					</Box>
					<OrderedList
						spacing={6}
						py={{ base: 2, md: 4 }}
						px={{ base: 2, md: 8 }}
					>
						<ListItem>เพิ่มหมวดหมู่สินค้าหรือบริการ</ListItem>
						<ListItem>เพิ่มสินค้าหรือบริการ</ListItem>
						<ListItem>
							เพิ่มรายชื่อ{bType === "hotel" ? "ห้อง" : "โต๊ะ"}
						</ListItem>
						<ListItem>
							เพิ่ม Line Notify Token พร้อมกดบันทึกและตรวจสอบ{" "}
							<AddTokenExample />
						</ListItem>
						<ListItem>
							ดาวน์โหลด QR Menu เพื่อนำไปติดตั้งตาม
							{bType === "hotel" ? "ห้อง" : "โต๊ะ"}ต่างๆ
						</ListItem>
					</OrderedList>

					<AccordionItem mt={2} border={"none"} display={"none"}>
						<AccordionButton p={0} _hover={"none"}>
							<Flex
								w={"25em"}
								p={{ base: 1, md: 2 }}
								bgColor={bgColor}
								borderRadius={"full"}
							>
								<Text
									ml={3}
									fontWeight={"400"}
									fontSize={"large"}
									color={"white"}
								>
									ตัวอย่างการใช้งานของลูกค้า
								</Text>
								<Spacer />
								<AccordionIcon color={"white"} mt={1} />
							</Flex>
						</AccordionButton>
						<AccordionPanel>
							<Box mt={1}>
								<CustomerTest />
							</Box>
						</AccordionPanel>
					</AccordionItem>

					<Box
						mt={4}
						p={4}
						bgColor={"cyan.700"}
						color={"gray.200"}
						fontWeight={"bold"}
						textAlign={"center"}
					>
						พนักงาน
					</Box>
					<OrderedList
						spacing={6}
						py={{ base: 2, md: 4 }}
						px={{ base: 2, md: 8 }}
					>
						{bType === "hotel" ? (
							""
						) : (
							<ListItem>
								พนักงานจดออเดอร์ให้ลูกค้ากรณีไม่สะดวกใช้ QR Code <br />
								หรือพนังงานจดออเดอร์แรกพร้อมกับแนะนำหากต้องการสินค้าเพิ่มเติมสามารถเลือกและสั่งเองได้ด้วย
								QR Code
							</ListItem>
						)}
						<ListItem>เช็คยอดสั่งซื้อรวม</ListItem>
						<ListItem>
							เคลียร์{bType === "hotel" ? "ห้อง" : "โต๊ะ"}เพื่อรองรับลูกค้าใหม่
						</ListItem>
					</OrderedList>
					<AccordionItem mt={2} border={"none"} display={"none"}>
						<AccordionButton p={0} _hover={"none"}>
							<Flex
								w={"25em"}
								p={{ base: 1, md: 2 }}
								bgColor={bgColor}
								borderRadius={"full"}
							>
								<Text
									ml={3}
									fontWeight={"400"}
									fontSize={"large"}
									color={"white"}
								>
									ตัวอย่างการใช้งานของพนักงาน
								</Text>
								<Spacer />
								<AccordionIcon color={"white"} mt={1} />
							</Flex>
						</AccordionButton>
						<AccordionPanel>
							<Box mt={1}>
								<EmployeeTest />
							</Box>
						</AccordionPanel>
					</AccordionItem>
				</Accordion>
			</Container>
			<Container maxW={"6xl"} px={{ base: 3, md: 7 }}>
				<Box mt={7} color={"red"}>
					*** หากมีข้อสงสัยติดต่อสอบถามเพิ่มเติมได้ที่ LINE : @sevscan
				</Box>
			</Container>
		</>
	);
}

function AddTokenExample() {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Text onClick={onOpen} color="blue.400" as="u" cursor={"pointer"}>
				<i>ตัวอย่าง</i>
			</Text>

			<Modal isOpen={isOpen} onClose={onClose} size={"full"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>ตัวอย่างสร้าง LINE Notify Token</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<LineNotifyManual />
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default Manual;
