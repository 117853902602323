import React from "react";
import {
	Card,
	CardBody,
	Image,
	Stack,
	Heading,
	Text,
	SimpleGrid,
} from "@chakra-ui/react";

function Service() {
	const handleCardClick = () => {
		alert("click card");
	};
	return (
		<>
			<SimpleGrid columns={[2, null, 4]} spacing="40px">
				<Card maxW="sm" as="button" onClick={() => handleCardClick()}>
					<CardBody p={3}>
						<Image
							src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
							alt="Green double couch with wooden legs"
							borderRadius="lg"
						/>
						<Stack mt="6" spacing="3">
							<Heading size="md">Room Service Scan</Heading>
							<Text>
								This sofa is perfect for modern tropical spaces, baroque
								inspired spaces.
							</Text>
						</Stack>
					</CardBody>
				</Card>
				<Card maxW="sm" as="button" onClick={() => handleCardClick()}>
					<CardBody p={3}>
						<Image
							src="https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
							alt="Green double couch with wooden legs"
							borderRadius="lg"
						/>
						<Stack mt="6" spacing="3">
							<Heading size="md">My Shop Scan</Heading>
							<Text>
								Earthy toned spaces and for people who love a chic design with a
								sprinkle.
							</Text>
						</Stack>
					</CardBody>
				</Card>
			</SimpleGrid>
		</>
	);
}

export default Service;
