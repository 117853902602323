import React, { useEffect, useMemo, useState, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";
import { Icon } from "@iconify/react";
import {
	Card,
	Grid,
	Box,
	Image,
	Text,
	useToast,
	Flex,
	ScaleFade,
	Spacer,
	Modal,
	ModalCloseButton,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	useDisclosure,
	FormControl,
	FormLabel,
	Button,
	Input,
	Textarea,
	NumberInput,
	NumberInputField,
	useColorModeValue,
	Spinner,
	Link,
	HStack,
	IconButton,
	InputGroup,
	InputLeftAddon,
	InputRightAddon,
	Divider,
} from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";
import {
	EditIcon,
	DeleteIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
} from "@chakra-ui/icons";
import { BeatLoader } from "react-spinners";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import ImageUploader from "../component/ImageUploader";

function Product() {
	const { shopValueId } = useContext(ShopContext);
	const bgColor = useColorModeValue("gray.50", "gray.600");
	const textColor = useColorModeValue("gray.700", "gray.400");
	const categorySelect = useColorModeValue("cyan.700", "cyan.700");
	const categoryNonSelect = useColorModeValue("gray.200", "gray.200");
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [categoryList, setCategoryList] = useState();
	const [productList, setProductList] = useState();
	const [selectCategory, setSelectCategory] = useState(null);
	const [showProductByCategory, setShowProductByCategory] = useState();

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [productData, setProductData] = useState({
		name: null,
		image: null,
		price: null,
		description: null,
	});
	const [methodStatus, setMethodStatus] = useState();
	const [selectedOptionsCategory, setSelectedOptionsCategory] = useState([]);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [checkCategoryNull, setCheckCategoryNull] = useState();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response1 = axios.post(
					`${API_BASE_URL}/api/site/category`,
					{ shopValueId },
					{ headers }
				);
				const response2 = axios.post(
					`${API_BASE_URL}/api/site/product`,
					{ shopValueId },
					{ headers }
				);

				const [data1, data2] = await axios.all([response1, response2]);
				// console.log("facth product", data1.data.results, data2.data.results);

				setIsLoading(false);
				setCategoryList(data1.data.results);
				setProductList(data2.data.results);
				setSelectCategory(data1.data.results[0]);
				setCheckCategoryNull(data1.data.results.length === 0 ? true : false);
			} catch (error) {
				console.error("Error get dat category and product:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	useEffect(() => {
		if (selectCategory !== null) {
			setShowProductByCategory([]);

			const timeout = setTimeout(() => {
				setShowProductByCategory(() => {
					const showCategoryId = selectCategory?.id; // Accessing id property
					const products = showCategoryId
						? productList?.filter(
								(product) => product.category_id === showCategoryId
						  )
						: productList;
					const sortedProducts = products?.sort((a, b) => a.no - b.no);
					return sortedProducts;
				});
			}, 10);

			return () => clearTimeout(timeout);
		}
	}, [selectCategory, productList]);

	useEffect(() => {
		if (categoryList?.length === 0) {
			setShowProductByCategory([]);
		}
	}, [categoryList]);

	const handleAdd = () => {
		setIsLoadingSave(false);
		setProductData(null);
		setMethodStatus("add");
		onOpen();
	};
	const handleEdit = (product) => {
		setFormControls(product.choice !== null ? JSON.parse(product.choice) : []);
		setIsLoadingSave(false);
		setProductData(product);
		setMethodStatus("edit");
		const categoryId = productList
			.filter((obj) => obj.id === product.id)
			.map((obj) => obj.category_id);
		const options = createOptionsFromArray(categoryList, categoryId);
		setSelectedOptionsCategory(options);
		onOpen();
	};
	function createOptionsFromArray(array, idsToCheck) {
		const filteredCategories = array.filter((category) =>
			idsToCheck.includes(category.id)
		);
		const options = filteredCategories.map((category) => ({
			value: category.id,
			label: category.name,
		}));
		return options;
	}

	const handleDelete = async (product) => {
		// console.log(categoryList);
		Swal.fire({
			text: `ลบข้อมูล "${product.name}"`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						`${API_BASE_URL}/api/site/product_delete`,
						{ product },
						{ headers }
					);
					// console.log(response);
					if (response.data.message === "success") {
						setProductList((prevProductList) =>
							prevProductList.filter((item) => item.id !== product.id)
						);
						toast({
							title: `ลบข้อมูลแล้ว`,
							position: "top",
							isClosable: true,
							status: "success",
							variant: "top-accent",
							duration: 2000,
						});
					} else {
						toast({
							title: `ไม่สามารถลบข้อมูลได้`,
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 2000,
						});
					}
				} catch (error) {
					console.error("Error product_delete:", error);
				}
			}
		});
	};

	const switchStatusSale = async (pId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/switch_status_sale`,
				{ pId },
				{ headers }
			);
			// console.log(response);
			if (response.data.message === "success") {
				const updatedProductList = productList.map((product) => {
					if (product.id === pId) {
						return {
							...product,
							sale_status: product.sale_status === "1" ? "0" : "1",
						};
					}
					return product;
				});
				setProductList(updatedProductList);
			} else {
				// console.log("error : switch_status_sale");
			}
		} catch (error) {
			// console.error("Error switch_status_sale:", error);
		}
	};

	const switchOutOfStock = async (pId) => {
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/switch_out_of_stock`,
				{ pId },
				{ headers }
			);
			// console.log(response);
			if (response.data.message === "success") {
				const updatedProductList = productList.map((product) => {
					if (product.id === pId) {
						return {
							...product,
							out_of_stock: product.out_of_stock === "1" ? "0" : "1",
						};
					}
					return product;
				});
				setProductList(updatedProductList);
			} else {
				// console.log("error : switch_status_sale");
			}
		} catch (error) {
			console.error("Error switch_status_sale:", error);
		}
	};

	const updateProductOrder = async (newProductList) => {
		try {
			// Send request to update product order in the backend
			await axios.post(
				`${API_BASE_URL}/api/site/product_reorder`,
				{
					showProductByCategory: newProductList,
				},
				{ headers }
			);
		} catch (error) {
			console.error("Error product_reorder:", error);
		}
	};

	const handleMove = async (product, direction) => {
		const index = showProductByCategory.findIndex(
			(prod) => prod.id === product.id
		);

		if (direction === "left" && index > 0) {
			const newProductList = [...showProductByCategory];
			[newProductList[index - 1], newProductList[index]] = [
				newProductList[index],
				newProductList[index - 1],
			];
			setAndReassignNo(newProductList);
		} else if (
			direction === "right" &&
			index < showProductByCategory.length - 1
		) {
			const newProductList = [...showProductByCategory];
			[newProductList[index + 1], newProductList[index]] = [
				newProductList[index],
				newProductList[index + 1],
			];
			setAndReassignNo(newProductList);
		}
	};

	const setAndReassignNo = async (newProductList) => {
		// Reassign the "no" values
		const updatedProductList = newProductList.map((product, index) => ({
			...product,
			no: index,
		}));
		// Set the new product list
		setShowProductByCategory(newProductList);
		const dataWithOutNowCategory = productList.filter(
			(item) => item.category_id !== selectCategory.id
		);
		setProductList([...dataWithOutNowCategory, ...updatedProductList]);

		// Update the product order
		await updateProductOrder(updatedProductList);
	};

	const handleMoveLeft = async (product) => {
		await handleMove(product, "left");
	};

	const handleMoveRight = async (product) => {
		await handleMove(product, "right");
	};

	const handleCloseModal = () => {
		setSelectedOptionsCategory([]);
		setFormControls([]);
		onClose();
	};

	const handleSave = async () => {
		// console.log(productData);
		const trimmedName = productData?.name?.trim().replace(/;/g, "");

		if (!productData?.image) {
			toast({
				title: `เพิ่มรูปภาพสินค้าก่อนบันทึก`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		if (!trimmedName || !productData.price) {
			toast({
				title: `กรอกข้อมูลให้ครบก่อนบันทึก`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		for (const control of formControls) {
			if (control.text.trim() === "" || control.number === "") {
				toast({
					title: `กรอกตัวเลือกให้ครบก่อนบันทึก`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
				return;
			}
		}

		if (!selectedOptionsCategory?.length) {
			toast({
				title: `เลือกหมวดหมู่ก่อนบันทึก`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		setIsLoadingSave(true);
		try {
			const categoryIds = selectedOptionsCategory.map((option) => option.value);
			const trimmedNameEn =
				productData?.name_en === undefined ? "" : productData?.name_en;
			const description =
				productData.description === undefined ? "" : productData.description;
			const description_en =
				productData.description_en === undefined
					? ""
					: productData.description_en;

			const formData = new FormData();
			formData.append("name", trimmedName);
			formData.append("name_en", trimmedNameEn);
			formData.append("price", productData.price);
			formData.append("description", description);
			formData.append("description_en", description_en);
			formData.append("categoryIds", JSON.stringify(categoryIds));
			formData.append("image", productData.image);
			formData.append("choice", JSON.stringify(formControls));

			if (methodStatus === "add") {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/product/add`,
					formData,
					{ headers }
				);
				if (response.data.message === "success") {
					onClose();
					const newProductsPromises = selectedOptionsCategory.map(
						(category) => {
							return {
								id: response.data.newId,
								name: trimmedName,
								name_en: trimmedNameEn,
								category_id: category.value,
								image: response.data.imageName,
								price: productData.price,
								description: description,
								description_en: description_en,
								sale_status: "1",
								choice: JSON.stringify(formControls),
							};
						}
					);

					// Wait for all newProductsPromises to resolve
					const newProducts = await Promise.all(newProductsPromises);

					setProductList((prevProducts) => {
						// Check if prevProducts is an array, if not, initialize it as an empty array
						const productsArray = Array.isArray(prevProducts)
							? prevProducts
							: [];
						// Concatenate the existing products with the new products
						return [...productsArray, ...newProducts];
					});
					setFormControls([]);
					setIsLoadingSave(false);
					toast({
						title: `เรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
				} else {
					console.error("Error product/add");
					toast({
						title: `ไม่สามารถเพิ่มสินค้าหรือบริการได้ ติดต่อแอดมินเพื่อทำการช่วยเหลือ`,
						position: "top",
						isClosable: true,
						status: "warning",
						variant: "top-accent",
						duration: 5000,
					});
				}
			} else if (methodStatus === "edit") {
				await new Promise((resolve) => {
					formData.append("id", productData.id);
					resolve();
				});
				const response = await axios.post(
					`${API_BASE_URL}/api/site/product/edit`, // Assuming productData.id exists for editing
					formData,
					{ headers }
				);
				if (response.data.message === "success") {
					// console.log("product_edit", response.data.categoryNoList);
					onClose(); // Close modal on successful edit

					// Remove the edited product from productList and add the updated one
					setProductList((prevProducts) => {
						// Filter out the product with the same id as the edited productData.id
						const updatedProducts = prevProducts.filter(
							(product) => product.id !== productData.id
						);

						// Add the updated product with new data to the productList
						const updatedProduct = {
							id: productData.id,
							name: trimmedName,
							name_en: trimmedNameEn,
							image:
								response.data.imageName !== null
									? response.data.imageName
									: productData.image,
							price: productData.price,
							description: description,
							description_en: description_en,
							category_id: productData.category_id,
							sale_status: productData.sale_status,
							out_of_stock: productData.out_of_stock,
							choice: JSON.stringify(formControls),
						};
						// console.log("hi", productData);

						// Loop through selectedOptionsCategory to add the newly selected categories
						selectedOptionsCategory.forEach((option) => {
							let productCategoryNo = 99;
							const foundCategory = response.data.categoryNoList.find(
								(item) => item[1] === option.value
							);
							// console.log("kk", foundCategory);

							// Check if category is found
							if (foundCategory) {
								productCategoryNo = foundCategory[2];
								// Now you can use productCategoryNo as needed
								// console.log("Product category number:", productCategoryNo);
							}
							updatedProducts.push({
								...updatedProduct,
								category_id: option.value,
								no: productCategoryNo,
							});
						});

						return updatedProducts;
					});
					setFormControls([]);
					setIsLoadingSave(false);
					toast({
						title: `เรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
				} else {
					console.error("Error product/edit");
					toast({
						title: `ไม่สามารถเพิ่มสินค้าหรือบริการได้ ติดต่อแอดมินเพื่อทำการช่วยเหลือ`,
						position: "top",
						isClosable: true,
						status: "warning",
						variant: "top-accent",
						duration: 5000,
					});
				}
			}
		} catch (error) {
			console.error("Error saving category:", error);
		}
	};

	const handleCategoryClick = (category) => {
		setSelectCategory(category);
	};

	const [formControls, setFormControls] = useState([]);

	const handleAddChoice = () => {
		setFormControls((prevFormControls) => {
			const newFormControls =
				prevFormControls.length === 0
					? [
							{ id: uuidv4(), text: "", number: 0.0 },
							{ id: uuidv4(), text: "", number: 0.0 },
					  ]
					: [...prevFormControls, { id: uuidv4(), text: "", number: 0.0 }];
			return newFormControls;
		});
	};

	const handleDeleteChoice = (id) => {
		setFormControls((prevFormControls) =>
			prevFormControls.filter((control) => control.id !== id)
		);
	};

	const handleInputChange = (id, field, value) => {
		setFormControls((prevFormControls) =>
			prevFormControls.map((control) =>
				control.id === id ? { ...control, [field]: value } : control
			)
		);
	};

	return (
		<>
			{checkCategoryNull ? (
				<Box color={"red"}>เพิ่มหมวดหมู่ก่อน</Box>
			) : (
				<Flex mb={2} justifyContent="flex-end">
					<Flex>
						<Icon
							icon="material-symbols:circle"
							width="1.2em"
							height="1.2em"
							color="#4cc260"
						/>
						<Text pl={1} mt={-0.5} fontSize={"0.9em"}>
							แสดงสินค้า
						</Text>
					</Flex>
					<Flex pl={3}>
						<Icon
							icon="material-symbols:circle"
							width="1.2em"
							height="1.2em"
							color="#ffda52"
						/>
						<Text pl={1} mt={-0.5} fontSize={"0.9em"}>
							สินค้าหมด
						</Text>
					</Flex>
				</Flex>
			)}

			{isLoading ? (
				<Flex
					justifyContent="center"
					alignItems="center"
					height="calc(100vh - 80px)"
				>
					<Spinner
						thickness="6px"
						speed="0.75s"
						emptyColor="gray.200"
						color="cyan.700"
						size="lg"
					/>
				</Flex>
			) : (
				<>
					<Box
						overflowX="auto"
						whiteSpace="nowrap"
						sx={{
							"&::-webkit-scrollbar": { display: "none" },
							scrollbarWidth: "none",
							msOverflowStyle: "none",
						}}
						pb={{ base: 3, md: 4 }}
					>
						<Box display="inline-block">
							<Flex overflowX="auto" gridGap={2}>
								{categoryList &&
									categoryList.map((category, index) => (
										<ScaleFade initialScale={0.5} in={true} key={index}>
											<Card
												py={{ base: 2, md: 3 }}
												px={{ base: 5, md: 7 }}
												key={index}
												_hover={{
													cursor: "pointer",
												}}
												bg={
													selectCategory?.id === category.id
														? categorySelect
														: categoryNonSelect
												}
												color={
													selectCategory?.id === category.id
														? categoryNonSelect
														: categorySelect
												}
												_dark={{
													color: "white",
													bgColor:
														selectCategory?.id === category.id
															? "cyan.700"
															: "gray.400",
												}}
												boxShadow={"sm"}
												borderRadius={"full"}
												onClick={() => handleCategoryClick(category)}
											>
												<Box>{category.name}</Box>
											</Card>
										</ScaleFade>
									))}
							</Flex>
						</Box>
					</Box>

					<Box mb={5} minH={"200px"} position="relative" pb={10}>
						<Grid
							templateColumns={{
								base: "repeat(2, 1fr)",
								md: "repeat(3, 1fr)",
								lg: "repeat(4, 1fr)",
								xl: "repeat(5, 1fr)",
							}}
							gap={{ base: 2, md: 3 }}
						>
							{showProductByCategory &&
								showProductByCategory.map((product, index) => (
									<ScaleFade initialScale={0.5} in={true} key={index}>
										<Card
											p={2}
											boxShadow={"sm"}
											color={"gray.600"}
											height={"100%"}
											display="flex"
											flexDirection="column"
											justifyContent="space-between"
											position="relative"
											borderRadius={"1em"}
										>
											<Box>
												<Flex alignItems="flex-start">
													<Image
														boxSize="100px"
														objectFit="cover"
														src={`${API_BASE_URL}/image/product/small/${product.image}`}
														alt={product.name}
														borderRadius={"1em"}
													/>
													<Spacer />
													<Flex flexDirection="column" gap={1}>
														<Box
															as="button"
															onClick={() => switchStatusSale(product.id)}
														>
															<Icon
																icon="material-symbols:circle"
																width="1.5em"
																height="1.5em"
																style={{
																	color:
																		product.sale_status === "1"
																			? "#4cc260"
																			: "#e2e9e3",
																}}
															/>
														</Box>
														<Box
															as="button"
															onClick={() => switchOutOfStock(product.id)}
														>
															<Icon
																icon="material-symbols:circle"
																width="1.5em"
																height="1.5em"
																style={{
																	color:
																		product.out_of_stock === "1"
																			? "#ffda52"
																			: "#e2e9e3",
																}}
															/>
														</Box>
													</Flex>
												</Flex>
												<Box pt={2} color={textColor}>
													{product.name}
												</Box>
												<Box color={"gray.500"}>{product.name_en}</Box>
											</Box>

											<Box
												bgColor={bgColor}
												px={2}
												pb={1}
												borderRadius={"0.6em"}
												color={"cyan.700"}
											>
												<Box textAlign={"right"} mb={2}>
													ราคา{" "}
													{String(product.price).endsWith(".00")
														? parseInt(String(product.price))
														: product.price}{" "}
													บาท
												</Box>
												<Flex>
													<Box>
														{index > 0 && (
															<ChevronLeftIcon
																_hover={{ cursor: "pointer" }}
																onClick={() => handleMoveLeft(product)}
																mr={2}
															/>
														)}
														{index !== showProductByCategory.length - 1 && (
															<ChevronRightIcon
																_hover={{ cursor: "pointer" }}
																onClick={() => handleMoveRight(product)}
															/>
														)}
													</Box>
													<Spacer />
													<Box>
														<EditIcon
															_hover={{ cursor: "pointer" }}
															onClick={() => handleEdit(product)}
															mr={2}
														/>
														<DeleteIcon
															_hover={{ cursor: "pointer" }}
															onClick={() => handleDelete(product)}
														/>
													</Box>
												</Flex>
											</Box>
										</Card>
									</ScaleFade>
								))}
						</Grid>
					</Box>
				</>
			)}

			{!checkCategoryNull && (
				<Button
					onClick={() => handleAdd()}
					position="fixed"
					bottom={4}
					right={4}
					boxShadow="md"
					zIndex={1}
					borderRadius={"full"}
					border={"1.5px solid"}
					borderColor={"cyan.700"}
					color={"cyan.800"}
					bgColor={"#d0e7ec"}
				>
					<Flex align="center">
						<Text fontWeight="400">เพิ่มสินค้าหรือบริการ</Text>
					</Flex>
				</Button>
			)}

			<Modal isOpen={isOpen} onClose={handleCloseModal} size={"lg"} isCentered>
				<ModalOverlay />
				<ModalContent pt={4}>
					<ModalCloseButton />
					<ModalBody>
						<Box>
							<ImageUploader
								productData={productData}
								setProductData={setProductData}
								methodStatus={methodStatus}
							/>
						</Box>
						<FormControl>
							<FormLabel>ชื่อสินค้าหรือบริการ</FormLabel>
							<Input
								mb={2}
								focusBorderColor="gray.300"
								value={productData?.name}
								placeholder="ภาษาไทย"
								onChange={(e) =>
									setProductData({ ...productData, name: e.target.value })
								}
							/>
							<Input
								focusBorderColor="gray.300"
								value={productData?.name_en}
								placeholder="ภาษาอังกฤษ (ถ้ามี)"
								onChange={(e) =>
									setProductData({ ...productData, name_en: e.target.value })
								}
							/>

							<FormLabel mt={4}>รายละเอียด</FormLabel>
							<Textarea
								mb={2}
								focusBorderColor="gray.300"
								value={productData?.description}
								placeholder="ภาษาไทย (ถ้ามี)"
								onChange={(e) =>
									setProductData({
										...productData,
										description: e.target.value,
									})
								}
							/>
							<Textarea
								focusBorderColor="gray.300"
								value={productData?.description_en}
								placeholder="ภาษาอังกฤษ (ถ้ามี)"
								onChange={(e) =>
									setProductData({
										...productData,
										description_en: e.target.value,
									})
								}
							/>

							<FormLabel mt={4}>ราคา</FormLabel>
							<NumberInput
								focusBorderColor="gray.300"
								value={productData?.price}
								onChange={(priceValue) =>
									setProductData({
										...productData,
										price: priceValue,
									})
								}
								precision={2}
								step={0.01}
								allowMouseWheel
							>
								<NumberInputField />
							</NumberInput>
							{formControls.length !== 0 && (
								<FormLabel mt={4}>ตัวเลือก</FormLabel>
							)}
							<Box>
								{formControls?.map((control) => (
									<Box key={control.id}>
										<FormControl>
											<HStack spacing={2} alignItems="center" mb={2}>
												<Input
													placeholder="ภาษาไทย"
													value={control.text}
													onChange={(e) =>
														handleInputChange(
															control.id,
															"text",
															e.target.value
														)
													}
												/>
												<Input
													placeholder="ภาษาอังกฤษ"
													value={control.text_en}
													onChange={(e) =>
														handleInputChange(
															control.id,
															"text_en",
															e.target.value
														)
													}
												/>
											</HStack>
											<InputGroup mt={2}>
												<InputLeftAddon color={"gray.300"}>
													เพิ่ม
												</InputLeftAddon>
												<NumberInput
													focusBorderColor="gray.200"
													value={control?.number}
													onChange={(valueString) =>
														handleInputChange(control.id, "number", valueString)
													}
													defaultValue={0.0}
													precision={2}
													step={0.01}
													allowMouseWheel
													w={"full"}
												>
													<NumberInputField
														textAlign="center"
														borderRadius={"none"}
													/>
												</NumberInput>
												<InputRightAddon color={"gray.300"}>
													บาท
												</InputRightAddon>
												<IconButton
													aria-label="Delete"
													variant="ghost"
													icon={<DeleteIcon color="red" />}
													onClick={() => handleDeleteChoice(control.id)}
												/>
											</InputGroup>
										</FormControl>
										<Divider pt={5} mb={5} />
									</Box>
								))}
								<Text textAlign="right" mt={2}>
									<Link
										fontSize="0.8em"
										color="cyan.600"
										onClick={handleAddChoice}
									>
										เพิ่มตัวเลือก
									</Link>
								</Text>
							</Box>

							<MultiSelect
								methodStatus={methodStatus}
								selectCategory={selectCategory}
								categoryList={categoryList}
								selectedOptionsCategory={selectedOptionsCategory}
								setSelectedOptionsCategory={setSelectedOptionsCategory}
							/>
							<Text
								py={1}
								px={2}
								mt={2}
								fontSize={"sm"}
								color={"red.400"}
								bgColor={"gray.50"}
							>
								หากยังไม่มีภาษาอังกฤษ
								และลูกค้าของทางร้านเปลี่ยนใช้ภาษาอังกฤษระบบจะแสดงเป็นภาษาไทยให้ก่อน
							</Text>
						</FormControl>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" onClick={handleCloseModal} mr={2}>
							ยกเลิก
						</Button>
						<Button
							colorScheme="telegram"
							isLoading={isLoadingSave}
							onClick={() => handleSave()}
							spinner={<BeatLoader size={8} color="white" />}
						>
							บันทึก
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

const MultiSelect = ({
	methodStatus,
	selectCategory,
	categoryList,
	selectedOptionsCategory,
	setSelectedOptionsCategory,
}) => {
	useEffect(() => {
		if (methodStatus === "add") {
			setSelectedOptionsCategory([
				{ value: selectCategory?.id, label: selectCategory?.name },
			]);
		}
	}, [selectCategory, setSelectedOptionsCategory, methodStatus]);

	const options = categoryList.map((category) => ({
		value: category.id,
		label: category.name,
	}));

	return (
		<>
			<FormLabel mt={4}>หมวดหมู่</FormLabel>
			<Select
				options={options}
				value={selectedOptionsCategory}
				// value={[
				// 	{ value: 3, label: "อาหาร" },
				// 	{ value: 4, label: "เครื่องดื่ม" },
				// ]}
				onChange={setSelectedOptionsCategory}
				placeholder={""}
				isMulti
			/>
		</>
	);
};

export default Product;
