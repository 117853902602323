import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	Button,
	Checkbox,
	Flex,
	Text,
	FormControl,
	FormLabel,
	Heading,
	Input,
	Stack,
	Image,
	useColorModeValue,
	useToast,
	Spacer,
	InputGroup,
	InputRightElement,
	Box,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useAuth } from "../hooks/AuthProvider";
import { BeatLoader } from "react-spinners";

import imageLoginAdmin from "../images/login-admin.jpg";

const Login = () => {
	const { loginAction } = useAuth();
	const [input, setInput] = useState({
		email: "",
		password: "",
		role: "user",
		rememberMe: false, // New state for remember me
	});
	const toast = useToast();
	const navigate = useNavigate();

	useEffect(() => {
		// Load saved email and password from local storage when component mounts
		const savedEmail = localStorage.getItem("rememberedEmail");
		const savedPassword = localStorage.getItem("rememberedPassword");
		if (savedEmail && savedPassword) {
			setInput((prevInput) => ({
				...prevInput,
				email: savedEmail,
				password: savedPassword,
				rememberMe: true, // If saved email and password exist, set rememberMe to true
			}));
		}
	}, []);

	const handleInputChange = (e) => {
		const { id, value } = e.target;
		setInput((prevInput) => ({
			...prevInput,
			[id]: value,
		}));
	};

	const handleRememberMeChange = (e) => {
		setInput((prevInput) => ({
			...prevInput,
			rememberMe: e.target.checked,
		}));
	};

	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const handleSubmitEvent = (e) => {
		e.preventDefault();
		setIsLoadingSave(true);
		if (input.email !== "" && input.password !== "" && input.role !== "") {
			// Dispatch action from hooks
			loginAction(
				{
					email: input.email,
					password: input.password,
					role: input.role,
				},
				setIsLoadingSave
			);
			if (input.rememberMe) {
				// If remember me is checked, save email and password to local storage
				localStorage.setItem("rememberedEmail", input.email);
				localStorage.setItem("rememberedPassword", input.password);
			} else {
				// If remember me is unchecked, clear saved email and password from local storage
				localStorage.removeItem("rememberedEmail");
				localStorage.removeItem("rememberedPassword");
			}
		} else {
			toast({
				title: `ระบุอีเมลและรหัสผ่านก่อนลงชื่อเข้าใช้`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			setIsLoadingSave(false);
		}
	};

	const [showPassword, setShowPassword] = useState(false);

	return (
		<>
			<Stack minH={"100vh"} direction={{ base: "column", md: "row" }}>
				<Flex flex={2}>
					<Image
						alt={"Login Image"}
						objectFit={"cover"}
						src={imageLoginAdmin}
						minH={"250px"}
					/>
				</Flex>
				<Flex p={8} flex={1} align={"center"} justify={"center"}>
					<Box w={"full"}>
						<Heading
							fontSize={"2xl"}
							color={"cyan.700"}
							mb={3}
							textAlign={"center"}
						>
							ลงชื่อเช้าใช้งาน
						</Heading>
						<Flex direction="row" gap={0} width="100%" mb={3}>
							<Button
								width="100%"
								bgColor={"cyan.700"}
								color={"white"}
								_hover={"none"}
								_dark={{ bgColor: "cyan.800" }}
								borderRadius={"none"}
							>
								เจ้าของธุรกิจ
							</Button>
							<Button
								width="100%"
								color={"cyan.700"}
								borderRadius={"none"}
								onClick={() => navigate("/em")}
							>
								พนักงาน
							</Button>
						</Flex>
						<Stack spacing={4} w={"full"}>
							<FormControl id="email">
								<FormLabel>อีเมล</FormLabel>
								<Input
									type="email"
									value={input.email}
									onChange={handleInputChange}
								/>
							</FormControl>
							<FormControl id="password">
								<FormLabel>รหัสผ่าน</FormLabel>
								<InputGroup>
									<Input
										type={showPassword ? "text" : "password"}
										value={input.password}
										onChange={handleInputChange}
									/>
									<InputRightElement h={"full"}>
										<Button
											variant={"ghost"}
											onClick={() =>
												setShowPassword((showPassword) => !showPassword)
											}
										>
											{showPassword ? <ViewIcon /> : <ViewOffIcon />}
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>

							<Stack spacing={6}>
								<Checkbox
									isChecked={input.rememberMe}
									onChange={handleRememberMeChange}
								>
									จดจำฉันในระบบ
								</Checkbox>
								<Button
									bg={useColorModeValue("cyan.700", "green.900")}
									_hover={{
										bg: "cyan.800",
									}}
									color={"white"}
									variant={"solid"}
									onClick={handleSubmitEvent}
									isLoading={isLoadingSave}
									spinner={<BeatLoader size={8} color="white" />}
								>
									ลงชื่อ
								</Button>

								<Flex mt={-3}>
									<Text
										pl={0}
										color="cyan.700"
										cursor={"pointer"}
										onClick={() => navigate("/forgot_pw")}
									>
										ลืมรหัสผ่าน
									</Text>
									<Spacer />
									<Text
										pr={0}
										color="cyan.700"
										cursor={"pointer"}
										onClick={() => navigate("/")}
									>
										กลับหน้าแรก
									</Text>
								</Flex>
							</Stack>
						</Stack>
					</Box>
				</Flex>
			</Stack>
		</>
	);
};

export default Login;
