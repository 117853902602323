import React, { useContext, useMemo, useEffect, useState } from "react";
import axios from "axios";
import {
	Box,
	Button,
	Table,
	Tbody,
	Tr,
	Td,
	Spinner,
	useColorModeValue,
	Spacer,
	Flex,
	Text,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalFooter,
	ModalBody,
	ScaleFade,
	useDisclosure,
} from "@chakra-ui/react";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";

function HistoryOrder() {
	const { shopValueId } = useContext(ShopContext);
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);
	const bgColor = useColorModeValue("gray.50", "gray.800");
	const [orderList, setOrderList] = useState([]);
	const [lastLoad, setLastLoad] = useState();
	const [showLoadMore, setShowLoadMore] = useState(true);
	const [loading, setLoading] = useState(false);
	const loadMore = 7;

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/order_list`,
					{ shopValueId, fromId: lastLoad, limit: loadMore }, // Pass loadedRows as limit parameter
					{ headers }
				);
				// console.log("order_list", response.data.results);
				setOrderList((prevOrderList) => [
					...prevOrderList,
					...response.data.results,
				]);
				if (response.data.results.length < loadMore) {
					setShowLoadMore(false);
				}
			} catch (error) {
				// console.log("order_list : " + error);
			} finally {
				setLoading(false); // Set loading to false after fetching data
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [headers, shopValueId, lastLoad]);

	const thaiDateTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric",
			hour: "numeric",
			minute: "numeric",
			second: "numeric",
			hour12: false,
		};
		const thaiDateTime = dateTime.toLocaleDateString("th-TH", options);
		return thaiDateTime;
	};

	// Function to load more rows
	const loadMoreRows = () => {
		if (orderList.length > 0) {
			const lastOrderId = orderList[orderList.length - 1].order_id;
			setLastLoad(lastOrderId);
		}
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [nowOrderDetail, setNowOrderDetail] = useState();
	const handleClick = (order_details) => {
		setNowOrderDetail(order_details);
		onOpen(true);
	};

	const formatValue = (value) => {
		return value.endsWith(".00") ? parseInt(value) : value;
	};

	return (
		<>
			{orderList.map((order, index) => (
				<ScaleFade initialScale={0.5} in={true} key={index}>
					<Box
						key={index}
						px={4}
						py={2}
						borderRadius={"0.8em"}
						bgColor={bgColor}
						color={"cyan.700"}
						pos={"relative"}
						fontWeight="normal"
						mb={3}
						cursor="pointer"
						onClick={() => handleClick(order.order_details)}
					>
						<Flex>
							{order.unit_name}
							<Spacer />
							<Text>
								{formatValue(
									order.order_details
										.split(";")
										.reduce((total, detail) => {
											const [, price, quantity] = detail.split(":");
											return total + parseFloat(price) * parseInt(quantity);
										}, 0)
										.toFixed(2)
								)}{" "}
								บาท
							</Text>
						</Flex>
						<Flex>
							<Text fontSize="sm" fontWeight="300" color={"gray.500"} mt={2}>
								{thaiDateTime(order.order_created_datetime)}
							</Text>
						</Flex>
					</Box>
				</ScaleFade>
			))}
			{showLoadMore && (
				<Box textAlign={"center"}>
					<Button
						onClick={loadMoreRows}
						mt={4}
						colorScheme="gray"
						size={{ base: "xs", md: "sm" }}
						fontWeight={"400"}
					>
						{loading ? <Spinner size="sm" /> : "เพิ่มเติม"}
					</Button>
				</Box>
			)}

			<Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader textAlign={"center"}>รายการ</ModalHeader>
					<ModalBody>
						<Table size={{ base: "sm", md: "md" }}>
							<Tbody fontWeight={"300"}>
								{nowOrderDetail && (
									<>
										{nowOrderDetail.split(";").map((detail, i) => {
											const [productName, price, quantity] = detail.split(":");
											const countPrice = (
												parseFloat(price) * parseInt(quantity)
											).toFixed(2);
											return (
												<Tr key={i}>
													<Td whiteSpace={"normal"}>{productName}</Td>
													<Td textAlign={"center"}>
														{price} x {quantity}
													</Td>
													<Td textAlign={"right"}>{countPrice}</Td>
												</Tr>
											);
										})}
										<Tr fontWeight={"bold"}>
											<Td border={"none"} colSpan={2}>
												รวม
											</Td>
											<Td border={"none"} textAlign={"right"}>
												{nowOrderDetail
													.split(";")
													.reduce((total, detail) => {
														const [, price, quantity] = detail.split(":");
														return (
															total + parseFloat(price) * parseInt(quantity)
														);
													}, 0)
													.toFixed(2)}{" "}
												บาท
											</Td>
										</Tr>
									</>
								)}
							</Tbody>
						</Table>
					</ModalBody>

					<ModalFooter>
						<Flex justifyContent="center" width="100%">
							<Button
								colorScheme="blue"
								mr={3}
								onClick={onClose}
								variant={"ghost"}
							>
								ปิด
							</Button>
						</Flex>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}

export default HistoryOrder;
