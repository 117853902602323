import React, { useEffect, useState, useMemo } from "react";
import {
	SimpleGrid,
	Box,
	Image,
	IconButton,
	ScaleFade,
	Container,
	chakra,
} from "@chakra-ui/react";
import { DownloadIcon } from "@chakra-ui/icons";
import Image1 from "../images/ex_menu/3.jpg";
import Image2 from "../images/ex_menu/4.jpg";
import Image3 from "../images/ex_menu/5.jpg";
import Image4 from "../images/ex_menu/6.jpg";
import Image5 from "../images/ex_menu/7.jpg";
import Image6 from "../images/ex_menu/8.jpg";
import Image7 from "../images/ex_menu/9.jpg";
import Image8 from "../images/ex_menu/10.jpg";
import Image9 from "../images/ex_menu/11.jpg";
import Image10 from "../images/ex_menu/12.jpg";
import Image11 from "../images/ex_menu/13.jpg";
import Image12 from "../images/ex_menu/14.jpg";
import Image13 from "../images/ex_menu/16.png";
import Image14 from "../images/ex_menu/15.jpg";
import Image15 from "../images/ex_menu/17.jpg";

import Design1 from "../images/ex_menu/design_menu/1.jpg";
import Design2 from "../images/ex_menu/design_menu/2.jpg";
import Design4 from "../images/ex_menu/design_menu/4.jpg";
import Design5 from "../images/ex_menu/design_menu/5.jpg";
import Design6 from "../images/ex_menu/design_menu/6.jpg";
import Design8 from "../images/ex_menu/design_menu/8.jpg";
import Design9 from "../images/ex_menu/design_menu/9.jpg";
import Design10 from "../images/ex_menu/design_menu/10.jpg";
import Design11 from "../images/ex_menu/design_menu/11.jpg";
import Design12 from "../images/ex_menu/design_menu/12.jpg";

function QRExample() {
	const images = useMemo(
		() => [
			Image1,
			Image2,
			Image3,
			Image4,
			Image5,
			Image6,
			Image7,
			Image8,
			Image9,
			Image10,
			Image11,
			Image12,
			Image13,
			Image14,
			Image15,
		],
		[]
	);

	const Design = [
		Design1,
		Design2,
		Design4,
		Design5,
		Design6,
		Design8,
		Design9,
		Design10,
		Design11,
		Design12,
	];

	const downloadImage = (src) => {
		const link = document.createElement("a");
		link.href = src;
		link.download = src.substring(src.lastIndexOf("/") + 1);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const [showButton, setShowButton] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setShowButton(true);
		}, 1500);

		return () => clearTimeout(timer); // Clean up the timer
	}, []);

	return (
		<>
			<Box minH="calc(100vh - 200px)">
				<Box pos={"relative"}>
					<SimpleGrid
						columns={10}
						spacing={0}
						boxShadow={"lg"}
						filter={"brightness(0.5)"}
					>
						{Design.map((src, index) => (
							<Box key={index}>
								<Image src={src} alt={`Image ${index + 1}`} />
							</Box>
						))}
					</SimpleGrid>
					<Box
						mb={6}
						pos="absolute"
						top="50%"
						left="50%"
						transform="translate(-50%, -50%)"
					>
						<chakra.h2
							fontSize={{
								base: "4xl",
								sm: "5xl",
							}}
							lineHeight="8"
							fontWeight="extrabold"
							letterSpacing="tight"
							color="white"
							_dark={{
								color: "gray.100",
							}}
						>
							ตัวอย่าง
						</chakra.h2>
					</Box>
				</Box>
				<Box textAlign={"center"} py={20}>
					<Container maxW="container.xl">
						<SimpleGrid columns={{ base: 2, md: 3, lg: 4, xl: 5 }} spacing={5}>
							{images.map((src, index) => (
								<ScaleFade key={index} initialScale={0.5} in={true}>
									<Box position="relative">
										<Image src={src} alt={`Image ${index + 1}`} />
										{showButton && (
											<IconButton
												aria-label="Download Image"
												icon={<DownloadIcon />}
												size={{ base: "xs", md: "md" }}
												bgColor="white"
												color="black"
												position="absolute"
												bottom="8px"
												right="8px"
												zIndex="1"
												onClick={() => downloadImage(src)}
											/>
										)}
									</Box>
								</ScaleFade>
							))}
						</SimpleGrid>
					</Container>
				</Box>
			</Box>
		</>
	);
}

export default QRExample;
