import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import {
	Button,
	FormControl,
	Flex,
	Heading,
	Input,
	Stack,
	Text,
	useColorModeValue,
	useToast,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { API_BASE_URL } from "../config/apiConfig";

function ForgotPassword() {
	const toast = useToast();
	const navigate = useNavigate();

	const [emailReset, setEmailReset] = useState("");
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const handleSubmit = async () => {
		const trimmedEmailReset = emailReset.trim();

		if (!trimmedEmailReset) {
			toast({
				title: `กรอกอีเมลก่อนยืนยัน`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		setIsLoadingSave(true);
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/authen/forgot_password`,
				{
					trimmedEmailReset,
				},
				{}
			);
			// console.log("hi", response.data);
			if (response.data.message === "success") {
				setEmailReset("");
				toast({
					title: `ส่งข้อมูลไปยังอีเมลแล้ว กรุณาตรวจสอบ`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
				setTimeout(() => {
					navigate("/login");
				}, 4000);
			} else if (response.data.message === "empty") {
				toast({
					title: `ไม่มีอีเมลนี้ในระบบ`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
			setIsLoadingSave(false);
		} catch (error) {
			setIsLoadingSave(false);
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	return (
		<>
			<Flex
				minH={"100vh"}
				align={"center"}
				justify={"center"}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack
					spacing={4}
					w={"full"}
					maxW={"md"}
					bg={useColorModeValue("white", "gray.700")}
					rounded={"xl"}
					boxShadow={"lg"}
					p={6}
					my={12}
				>
					<Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
						ลืมรหัสผ่าน?
					</Heading>
					<Text
						fontSize={{ base: "sm", sm: "md" }}
						color={useColorModeValue("gray.800", "gray.400")}
					>
						ระบบจะส่งลิงก์สำหรับรีเซ็ตรหัสผ่านไปยังอีเมล
					</Text>
					<FormControl id="email">
						<Input
							placeholder="your-email@example.com"
							_placeholder={{ color: "gray.500" }}
							type="email"
							value={emailReset}
							onChange={(e) => setEmailReset(e.target.value)}
						/>
					</FormControl>
					<Stack spacing={6}>
						<Button
							bg={"blue.400"}
							color={"white"}
							_hover={{
								bg: "blue.500",
							}}
							onClick={handleSubmit}
							isLoading={isLoadingSave}
							spinner={<BeatLoader size={8} color="white" />}
						>
							ยืนยัน
						</Button>
					</Stack>
				</Stack>
			</Flex>
		</>
	);
}

export default ForgotPassword;
