import React, { useState, useMemo, useRef, useEffect, useContext } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {
	Text,
	Card,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	ModalFooter,
	useDisclosure,
	FormControl,
	FormLabel,
	Input,
	ScaleFade,
	Flex,
	useToast,
	Box,
	Spacer,
	Grid,
	Spinner,
} from "@chakra-ui/react";
import {
	EditIcon,
	DeleteIcon,
	ChevronRightIcon,
	ChevronLeftIcon,
} from "@chakra-ui/icons";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";

function Category() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [categoryList, setCategoryList] = useState();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const [categoryData, setCategoryData] = useState(null);
	const [methodStatus, setMethodStatus] = useState();
	const inputRef = useRef(null); // Create a reference to the input field
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/category`,
					{ shopValueId },
					{ headers }
				);

				// console.log("facth product", response.data.results);

				setIsLoading(false);
				setCategoryList(response.data.results);
			} catch (error) {
				console.error("Error get dat category and product:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const handleAdd = () => {
		setCategoryData(null);
		setMethodStatus("add");
		onOpen();
	};

	const handleEdit = (category) => {
		setCategoryData(category);
		setMethodStatus("edit");
		onOpen();
	};

	const handleDelete = async (category) => {
		// console.log(category);
		Swal.fire({
			html: `ลบหมวดหมู่ "${category.name}"`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						`${API_BASE_URL}/api/site/category_delete`,
						{ category },
						{ headers }
					);
					// console.log(response);
					if (response.data.message === "success") {
						setCategoryList((prevCategoryList) =>
							prevCategoryList.filter((item) => item.id !== category.id)
						);
						toast({
							title: `ลบหมวดหมู่แล้ว`,
							position: "top",
							isClosable: true,
							status: "success",
							variant: "top-accent",
							duration: 2000,
						});
					} else {
						toast({
							title: `ไม่สามารถลบข้อมูลได้`,
							position: "top",
							isClosable: true,
							status: "warning",
							variant: "top-accent",
							duration: 2000,
						});
					}
				} catch (error) {
					console.error("Error category_delete:", error);
				}
			}
		});
	};

	const updateCategoryOrder = async (newCategoryList) => {
		try {
			// Send request to update category order in the backend
			await axios.post(
				`${API_BASE_URL}/api/site/category_reorder`,
				{
					categoryList: newCategoryList,
				},
				{ headers }
			);
		} catch (error) {
			console.error("Error updating category order:", error);
		}
	};

	const handleMove = async (category, direction) => {
		const index = categoryList.findIndex((cat) => cat.id === category.id);
		if (direction === "left" && index > 0) {
			const newCategoryList = [...categoryList];
			[newCategoryList[index - 1], newCategoryList[index]] = [
				newCategoryList[index],
				newCategoryList[index - 1],
			];
			setCategoryList(newCategoryList);
			await updateCategoryOrder(newCategoryList);
		} else if (direction === "right" && index < categoryList.length - 1) {
			const newCategoryList = [...categoryList];
			[newCategoryList[index + 1], newCategoryList[index]] = [
				newCategoryList[index],
				newCategoryList[index + 1],
			];
			setCategoryList(newCategoryList);
			await updateCategoryOrder(newCategoryList);
		}
	};

	const handleMoveLeft = async (category) => {
		await handleMove(category, "left");
	};

	const handleMoveRight = async (category) => {
		await handleMove(category, "right");
	};

	const handleSave = async () => {
		const trimmedName = categoryData?.name.trim();
		const trimmedNameEn = categoryData?.name_en;

		if (!trimmedName) {
			toast({
				title: `กรอกชื่อหมวดหมู่`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			inputRef.current.focus(); // Focus on the input field
			return; // Exit the function early if the name is empty
		}

		try {
			if (methodStatus === "add") {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/category_add`,
					{
						categoryName: trimmedName,
						categoryNameEn: trimmedNameEn,
						shopValueId,
					},
					{ headers }
				);
				if (response.data.message === "success") {
					onClose();
					const newCategory = {
						id: response.data.newId,
						name: trimmedName,
						name_en: trimmedNameEn,
					};
					setCategoryList((category) => [...category, newCategory]);
					toast({
						title: `เรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
				} else {
					console.error("Error category_add");
				}
			} else if (methodStatus === "edit") {
				const response = await axios.put(
					`${API_BASE_URL}/api/site/category_edit/${categoryData.id}`,
					{
						categoryName: trimmedName,
						categoryNameEn: trimmedNameEn,
					},
					{ headers }
				);
				if (response.data.message === "success") {
					onClose();
					setCategoryList((category) => {
						return category.map((cat) => {
							if (cat.id === categoryData.id) {
								return { ...cat, name: trimmedName, name_en: trimmedNameEn };
							}
							return cat;
						});
					});
					toast({
						title: `เรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
				} else {
					console.error("Error category_edit");
				}
			}
		} catch (error) {
			console.error("Error saving category:", error);
		}
	};

	return (
		<>
			{isLoading ? (
				<Flex
					justifyContent="center"
					alignItems="center"
					height="calc(100vh - 80px)"
				>
					<Spinner
						thickness="6px"
						speed="0.75s"
						emptyColor="gray.200"
						color="cyan.700"
						size="lg"
					/>
				</Flex>
			) : (
				<Grid
					templateColumns={{
						base: "repeat(2, 1fr)",
						md: "repeat(3, 1fr)",
						lg: "repeat(4, 1fr)",
						xl: "repeat(5, 1fr)",
					}}
					gap={{ base: 2, md: 3 }}
					mb={14}
				>
					{categoryList &&
						categoryList.map((category, index) => (
							<ScaleFade initialScale={0.5} in={true} key={index}>
								<Card
									py={3}
									px={4}
									key={index}
									boxShadow={"sm"}
									height={"100%"}
									display="flex" // Use flexbox for card layout
									flexDirection="column" // Stack items vertically
									justifyContent="space-between" // Space items evenly
									position="relative" // Position container relatively for absolute positioning
									borderRadius={"1em"}
								>
									<Box>{category.name}</Box>
									<Box mb={2} color={"gray.500"}>
										{category.name_en}
									</Box>
									<Flex>
										<Flex>
											{index > 0 && (
												<Text
													onClick={() => handleMoveLeft(category)}
													bgColor="#ffffff00"
													color={"cyan.700"}
													cursor="pointer"
													mr={3}
												>
													<ChevronLeftIcon />
												</Text>
											)}
											{index !== categoryList.length - 1 && (
												<Text
													onClick={() => handleMoveRight(category)}
													bgColor="#ffffff00"
													color={"cyan.700"}
													cursor="pointer"
												>
													<ChevronRightIcon />
												</Text>
											)}
										</Flex>
										<Spacer />
										<Flex pt={2}>
											<EditIcon
												onClick={() => handleEdit(category)}
												color="cyan.700"
												cursor="pointer"
												mr={2}
											/>
											<DeleteIcon
												onClick={() => handleDelete(category)}
												color="cyan.700"
												cursor="pointer"
											/>
										</Flex>
									</Flex>
								</Card>
							</ScaleFade>
						))}
				</Grid>
			)}

			<Button
				onClick={() => handleAdd()}
				position="fixed"
				bottom={4}
				right={4}
				boxShadow="md"
				zIndex={1} // Ensure the button appears above other content
				borderRadius={"full"}
				border={"1.5px solid"}
				borderColor={"cyan.700"}
				color={"cyan.800"}
				bgColor={"#d0e7ec"}
			>
				<Flex align="center">
					<Text fontWeight="400">เพิ่มหมวดหมู่</Text>
				</Flex>
			</Button>

			{/* modal */}
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent pt={4}>
					<ModalBody>
						<FormControl>
							<FormLabel>ชื่อหมวดหมู่</FormLabel>
							<Input
								mb={2}
								placeholder="ภาษาไทย"
								focusBorderColor="gray.300"
								value={categoryData?.name}
								onChange={(e) =>
									setCategoryData({ ...categoryData, name: e.target.value })
								}
								ref={inputRef} // Assign the ref to the input field
							/>
							<Input
								placeholder="ภาษาอังกฤษ (ถ้ามี)"
								focusBorderColor="gray.300"
								value={categoryData?.name_en}
								onChange={(e) =>
									setCategoryData({ ...categoryData, name_en: e.target.value })
								}
							/>
							<Text
								py={1}
								px={2}
								mt={2}
								fontSize={"sm"}
								color={"red.400"}
								bgColor={"gray.50"}
							>
								หากยังไม่มีภาษาอังกฤษ
								และลูกค้าของทางร้านเปลี่ยนใช้ภาษาอังกฤษระบบจะแสดงเป็นภาษาไทยให้ก่อน
							</Text>
						</FormControl>
					</ModalBody>

					<ModalFooter>
						<Button colorScheme="red" onClick={onClose} mr={2}>
							ยกเลิก
						</Button>
						<Button colorScheme="telegram" onClick={() => handleSave()}>
							ยืนยัน
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
}
export default Category;
