import React, { useMemo, useEffect, useState, useContext } from "react";
import axios from "axios";
import {
	Box,
	useToast,
	Button,
	FormControl,
	Input,
	Checkbox,
	Flex,
	useColorModeValue,
	Text,
	Stack,
	ScaleFade,
	Textarea,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { QRCode } from "react-qrcode-logo";
import { API_BASE_URL } from "../../config/apiConfig";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import ImageShopUploader from "../component/ImageShopUploader";
import ExampleDesignQRCode from "../component/ExampleDesignQRCode";

function Shop() {
	const { shopValueId } = useContext(ShopContext);
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const [urlShop, setUrlShop] = useState();
	const [shopName, setShopName] = useState();
	const [shopNameEn, setShopNameEn] = useState("");
	const [shopRecommend, setShopRecommend] = useState("");
	const [shopRecommendEn, setShopRecommendEn] = useState("");
	const [checkedCallStaff, setCheckedCallStaff] = useState();
	const [shopLogo, setShopLogo] = useState();
	useEffect(() => {
		// console.log(shopValueId);
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/shop_detail`,
					{ shopValueId },
					{ headers }
				);

				// console.log("res", response.data);
				setShopName(response.data.results[0].name);
				setShopNameEn(response.data.results[0].name_en);
				setShopRecommend(response.data.results[0].recommend);
				setShopRecommendEn(response.data.results[0].recommend_en);
				setUrlShop(
					`${window.location.origin}/demo/${response.data.results[0].code}`
				);
				setCheckedCallStaff(
					response.data.results[0].show_call_staff === 1 ? true : false
				);
				if (response.data.results[0].logo !== "") {
					setShopLogo(response.data.results[0].logo);
				} else {
					setShopLogo("default.png");
				}
			} catch (error) {
				console.error("Error qr_detail and list_unit:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const [editLogo, setEditLogo] = useState(false);
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	useEffect(() => {
		const updateShopDetail = async () => {
			// console.log(shopLogo, shopName, checkedCallStaff, shopDetail);
			const trimmedName = shopName.trim();
			const trimmedNameEn = shopNameEn;
			const trimmedRecommend = shopRecommend;
			const trimmedRecommendEn = shopRecommendEn;

			if (!trimmedName) {
				toast({
					title: `กรอกชื่อร้านก่อนบันทึก`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
				return;
			}

			setIsLoadingSave(true);
			try {
				const formData = new FormData();
				formData.append("name", trimmedName);
				formData.append("name_en", trimmedNameEn);
				formData.append("recommend", trimmedRecommend);
				formData.append("recommend_en", trimmedRecommendEn);
				formData.append("checkedCallStaff", checkedCallStaff);
				formData.append("shopValueId", shopValueId);
				if (shopLogo) {
					formData.append("logo", shopLogo);
				}

				const response = await axios.post(
					`${API_BASE_URL}/api/site/shop_update`,
					formData,
					{ headers }
				);
				if (response.data.message === "success") {
					toast({
						title: `อัพเดทเรียบร้อย`,
						position: "top",
						isClosable: true,
						status: "success",
						variant: "top-accent",
						duration: 5000,
					});
					setIsLoadingSave(false);
					setEditLogo(false);
				} else {
					console.error("Error shop detial/update");
				}
			} catch (error) {
				console.error("Error saving shop detail:", error);
			}
		};

		if (editLogo) {
			updateShopDetail();
		}
	}, [
		shopLogo,
		editLogo,
		setEditLogo,
		checkedCallStaff,
		headers,
		shopName,
		shopNameEn,
		shopRecommend,
		shopRecommendEn,
		shopValueId,
		toast,
	]);

	const handleChange = () => {
		setCheckedCallStaff(!checkedCallStaff);
	};

	const [fullLinkLogo, setFullLinkLogo] = useState();

	useEffect(() => {
		if (shopLogo) {
			setFullLinkLogo(`${API_BASE_URL}/image/logo/${shopLogo}`);
		}
	}, [shopLogo]);

	const openNewtab = () => {
		window.open(urlShop, "_blank");
	};

	return (
		<>
			<Flex direction={{ base: "column", lg: "row" }} gap={5}>
				<Box flex={{ lg: 8, xl: 9 }}>
					<ScaleFade initialScale={0.1} in={true}>
						<Box
							bgColor={useColorModeValue("white", "gray.600")}
							borderRadius={"1em"}
							p={{ base: 5, md: 10 }}
						>
							<Stack
								direction={{ base: "column", md: "row" }}
								as={"form"}
								spacing={2}
								mb={7}
							>
								<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
									<Text fontWeight={"bold"} mt={2}>
										โลโก้
									</Text>
								</FormControl>
								<FormControl>
									<ScaleFade initialScale={0.1} in={true}>
										<Box w={"200px"}>
											<ImageShopUploader
												shopLogo={shopLogo}
												setShopLogo={setShopLogo}
												setFullLinkLogo={setFullLinkLogo}
											/>
										</Box>
									</ScaleFade>
								</FormControl>
							</Stack>
							<Stack
								direction={{ base: "column", md: "row" }}
								as={"form"}
								spacing={2}
								mb={6}
							>
								<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
									<Text fontWeight={"bold"} mt={2}>
										ชื่อร้านหรือกิจการ
									</Text>
								</FormControl>
								<FormControl>
									<Input
										mb={2}
										type="text"
										placeholder="ภาษาไทย"
										value={shopName}
										onChange={(e) => setShopName(e.target.value)}
									/>
									<Input
										type="text"
										placeholder="ภาษาอังกฤษ (ถ้ามี)"
										value={shopNameEn}
										onChange={(e) => setShopNameEn(e.target.value)}
									/>
								</FormControl>
							</Stack>
							<Stack
								direction={{ base: "column", md: "row" }}
								as={"form"}
								spacing={2}
								mb={7}
							>
								<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
									<Text fontWeight={"bold"} mt={2}>
										ป้ายกำกับแนะนำลูกค้า (ถ้ามี)
									</Text>
								</FormControl>
								<FormControl>
									<Textarea
										mb={2}
										placeholder={`เช่น ส่งสินค้า 2 รอบ 12:00 และ 17:00 น. \n ${"     "} รับออเดอร์ตั้งแต่ 6.00 - 22.00 น.`}
										value={shopRecommend}
										onChange={(e) => setShopRecommend(e.target.value)}
									/>
									<Textarea
										mb={2}
										placeholder={`e.g. Delivery times: 12:00 PM and 5:00 PM \n ${"     "} Order hours: 6:00 AM to 10:00 PM`}
										value={shopRecommendEn}
										onChange={(e) => setShopRecommendEn(e.target.value)}
									/>
									<Text
										py={2}
										px={2}
										fontSize={"sm"}
										color={"red.400"}
										bgColor={"gray.50"}
									>
										หากยังไม่มีภาษาอังกฤษ
										และลูกค้าของทางร้านเปลี่ยนใช้ภาษาอังกฤษระบบจะแสดงเป็นภาษาไทยให้ก่อน
									</Text>
								</FormControl>
							</Stack>
							<Stack
								direction={{ base: "column", md: "row" }}
								as={"form"}
								spacing={2}
								mb={7}
							>
								<FormControl w={{ base: "100%", md: "40%", lg: "30%" }}>
									<Text fontWeight={"bold"}>ปุ่มเรียกพนักงาน</Text>
								</FormControl>
								<FormControl>
									<Checkbox
										isChecked={checkedCallStaff}
										onChange={handleChange}
									>
										แสดง(หน้าเลือกซื้อสินค้าของลูกค้า)
									</Checkbox>
								</FormControl>
							</Stack>
							<Box textAlign={"right"} pt={2}>
								<Button
									w={"15em"}
									size={{ base: "sm", md: "md" }}
									borderRadius={"full"}
									border={"1.5px solid"}
									borderColor={"cyan.700"}
									color={"cyan.800"}
									bgColor={"#d0e7ec"}
									isLoading={isLoadingSave}
									onClick={() => setEditLogo(true)}
									spinner={<BeatLoader size={8} color="#0987a0" />}
								>
									บันทึก
								</Button>
							</Box>
						</Box>
					</ScaleFade>
				</Box>
				<Flex
					gap={5}
					direction={{ base: "column", md: "row", lg: "column" }}
					w={{ base: 2, md: "full", lg: "200px" }}
				>
					<Box bgColor={"white"} w={"200px"}>
						<Box>
							<QRCode
								value={urlShop}
								logoImage={fullLinkLogo}
								// logoImage="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuaPhxgtyaCQStpttEXIZj4VctG9goIoOAtQ&s"
								logoWidth={50}
								logoHeight={50}
								size={180}
								// qrStyle="squares"
								enableCORS={true}
								// fgColor="#3ba0b2"
								// eyeColor={{
								// 	outer: "#3ba0b2",
								// 	inner: "#3ba0b2",
								// }}
								// eyeRadius={{
								// 	outer: 10,
								// 	inner: 5,
								// }}
								id="QR"
							/>
						</Box>

						<Flex justifyContent="center" px={2} pb={2}>
							<Button
								onClick={openNewtab}
								colorScheme="teal"
								w={"full"}
								borderRadius={"none"}
							>
								ตัวอย่าง
							</Button>
						</Flex>
					</Box>
					<Box p={2} bgColor={"white"} w={"200px"}>
						<ExampleDesignQRCode />
					</Box>
				</Flex>
			</Flex>
		</>
	);
}

export default Shop;
