import React, { useState, useMemo } from "react";
import axios from "axios";
import {
	useToast,
	Popover,
	PopoverTrigger,
	PopoverContent,
	PopoverBody,
	Button,
	Flex,
	Avatar,
	VStack,
	Box,
	HStack,
	Text,
	Stack,
	Input,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	FormControl,
	FormLabel,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useAuth } from "../../hooks/AuthProvider";
import { API_BASE_URL } from "../../config/apiConfig";
import { useNavigate } from "react-router-dom";

const ProfilePopover = () => {
	const navigate = useNavigate();
	const toast = useToast();
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	const handleLogout = () => {
		auth.logOut();
	};
	const handleDashboard = () => {
		navigate("/dashboard");
	};

	const [firstName, setFirstName] = useState(auth.user.first_name || "");
	const [lastName, setLastName] = useState(auth.user.last_name || "");
	const [password, setPassword] = useState("");
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const handleCloseModal = () => {
		setIsModalOpen(false);
	};

	const handleSaveProfile = async () => {
		if (!firstName.trim() || !lastName.trim()) {
			toast({
				title: `กรอกข้อมูล ชื่อ-สกุล ให้ครบก่อนบันทึก`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/site/edit_profile`,
				{
					first_name: firstName,
					last_name: lastName,
					password: password,
				},
				{ headers }
			);

			if (response.data.message === "success") {
				auth.updateUser({
					first_name: firstName,
					last_name: lastName,
				});
				setPassword("");
				handleCloseModal();
				toast({
					title: `บันทึกเรียบร้อย`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
			} else {
				toast({
					title: `บันทึกข้อมูลล้มเหลว`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		} catch (error) {
			console.error("Error updating profile:", error);
			toast({
				title: `เกิดข้อผิดพลาดในการบันทึกข้อมูล`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	return (
		<>
			<Popover>
				<PopoverTrigger>
					<Box minW="auto" cursor="pointer">
						<HStack>
							<Avatar size="sm" bg="teal.500" />
							<VStack
								display={{ base: "none", md: "block" }}
								alignItems="flex-start"
								spacing="1px"
								ml="0"
							>
								<Text fontSize="sm" color="gray.600">
									{auth.user?.first_name}
								</Text>
								<Text fontSize="xs" color="gray.500">
									{auth.user?.last_name}
								</Text>
							</VStack>
						</HStack>
					</Box>
				</PopoverTrigger>
				<PopoverContent border={0} boxShadow="xl" rounded="xl" zIndex={1001}>
					<PopoverBody>
						<Flex direction="column">
							<Button
								variant="ghost"
								onClick={handleDashboard}
								fontWeight={"md"}
							>
								แดชบอร์ด
							</Button>
							<Button
								variant="ghost"
								onClick={() => setIsModalOpen(true)}
								fontWeight="md"
							>
								ตั้งค่าโปรไฟล์
							</Button>
							<Button variant="ghost" onClick={handleLogout} fontWeight="md">
								ออกจากระบบ
							</Button>
						</Flex>
					</PopoverBody>
				</PopoverContent>
			</Popover>

			<Modal isOpen={isModalOpen} onClose={handleCloseModal}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>แก้ไขโปรไฟล์</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<Stack spacing={4}>
							<FormControl>
								<FormLabel>ชื่อ</FormLabel>
								<Input
									value={firstName}
									onChange={(e) => setFirstName(e.target.value)}
									placeholder=""
								/>
							</FormControl>
							<FormControl>
								<FormLabel>สกุล</FormLabel>
								<Input
									value={lastName}
									onChange={(e) => setLastName(e.target.value)}
									placeholder=""
								/>
							</FormControl>
							<FormControl>
								<FormLabel>รหัสผ่านใหม่</FormLabel>
								<InputGroup>
									<Input
										type={showPassword ? "text" : "password"}
										value={password}
										onChange={(e) => setPassword(e.target.value)}
									/>
									<InputRightElement h="full">
										<Button
											variant="ghost"
											onClick={() => setShowPassword(!showPassword)}
										>
											{showPassword ? <ViewIcon /> : <ViewOffIcon />}
										</Button>
									</InputRightElement>
								</InputGroup>
							</FormControl>
						</Stack>
					</ModalBody>
					<ModalFooter>
						<Button colorScheme="red" onClick={handleCloseModal} mr={2}>
							ยกเลิก
						</Button>
						<Button colorScheme="telegram" onClick={handleSaveProfile}>
							ยืนยัน
						</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
};

export default ProfilePopover;
