import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../hooks/AuthProvider";
import Sidebar from "../components/Sidebar";
import { ShopProvider } from "../hooks/ShopContext";

const PrivateRoute = () => {
	const auth = useAuth();
	if (!auth.user.token) return <Navigate to="/login" />;
	return (
		<>
			<ShopProvider>
				<Sidebar />
			</ShopProvider>
		</>
	);
};

export default PrivateRoute;
