import React from "react";
import { useTranslation } from "react-i18next";
import {
	Modal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalBody,
	ModalCloseButton,
	Table,
	Tbody,
	Tr,
	Td,
	Box,
	SimpleGrid,
	Text,
	Spinner,
	useColorModeValue,
} from "@chakra-ui/react";

const thaiDateTime = (dateTimeString, language) => {
	const dateTime = new Date(dateTimeString);
	const options = {
		year: "numeric",
		month: "long",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		second: "numeric",
		hour12: false,
	};
	const thaiDateTime = dateTime.toLocaleDateString(language, options);
	return thaiDateTime;
};

const BillDetailModal = ({
	isOpen,
	onClose,
	billDetail,
	loading,
	modalSize,
	nowUnitName,
	nowUnitNameEn,
	nowLanguage,
}) => {
	// console.log(billDetail);
	const { t } = useTranslation();
	const totalPrice = billDetail.reduce(
		(sum, item) => sum + parseFloat(item.total_price),
		0
	);

	const bgCardColor = useColorModeValue("gray.50", "gray.600");
	const textColor = useColorModeValue("gray.600", "gray.400");
	const textSumColor = useColorModeValue("cyan.700", "gray.100");

	const formatValue = (value) => {
		return value.endsWith(".00") ? parseInt(value) : value;
	};

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size={{ base: "full", md: modalSize }}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader pl={4} color={"cyan.800"}>
					{nowLanguage === "th"
						? nowUnitName
						: nowUnitNameEn !== ""
						? nowUnitNameEn
						: nowUnitName}
				</ModalHeader>
				<ModalCloseButton />
				<ModalBody px={2} pb={3} pt={0}>
					{loading ? (
						<Box
							gridColumn="1 / -1"
							display="flex"
							justifyContent="center"
							alignItems="center"
							minHeight="140px"
						>
							<Spinner size="sm" />
						</Box>
					) : (
						<>
							<Box mb={modalSize === "full" ? 12 : 4} minH={"40vh"}>
								<Box p={2} bgColor={bgCardColor} borderRadius={"1em"}>
									<Table size={{ base: "sm", md: "md" }}>
										<Tbody>
											{billDetail.map((order, index) => (
												<>
													<Tr
														color={"black"}
														_dard={{ color: "white" }}
														fontWeight={"bold"}
														key={index}
													>
														<Td
															style={{ "-webkit-padding-start": "0" }}
															colSpan={2}
														>
															{thaiDateTime(
																order.order_created_datetime,
																nowLanguage === "th" ? "th-TH" : "en-US"
															)}
														</Td>
														<Td
															style={{ "-webkit-padding-end": "0" }}
															textAlign={"right"}
														>
															{formatValue(
																order.order_details
																	.split(";")
																	.reduce((total, detail) => {
																		const [, , , price, quantity] =
																			detail.split(":");
																		return (
																			total +
																			parseFloat(price) * parseInt(quantity)
																		);
																	}, 0)
																	.toFixed(2)
															)}
														</Td>
													</Tr>
													{order.order_details.split(";").map((detail, i) => {
														const [
															,
															productName,
															productNameEn,
															price,
															quantity,
														] = detail.split(":");
														const countPrice = (
															parseFloat(price) * parseInt(quantity)
														).toFixed(2);
														return (
															<Tr key={i} fontWeight={"300"} color={textColor}>
																<Td
																	border={"none"}
																	style={{
																		WebkitPaddingStart: "0px",
																		paddingTop: "10px",
																		paddingBottom: "11px",
																	}}
																>
																	{nowLanguage === "th"
																		? productName
																		: productNameEn !== ""
																		? productNameEn
																		: productName}
																</Td>
																<Td border={"none"} textAlign={"right"}>
																	{formatValue(price)} x {quantity}
																</Td>
																<Td
																	border={"none"}
																	w={"10%"}
																	textAlign={"right"}
																	style={{ WebkitPaddingEnd: "0px" }}
																>
																	{formatValue(countPrice)}
																</Td>
															</Tr>
														);
													})}
												</>
											))}
										</Tbody>
									</Table>
								</Box>
							</Box>
							<Box
								position={{
									base: "fixed",
									md: modalSize === "full" ? "fixed" : "unset",
								}}
								bottom="0px"
								width={"100%"}
								borderTop={"1px solid #ededed"}
								bgColor={bgCardColor}
								py={2.5}
								pl={2}
								pr={5}
							>
								<SimpleGrid
									columns={2}
									spacing={0}
									fontWeight={"600"}
									color={textSumColor}
								>
									<Text>{t("total")}</Text>
									<Text textAlign={"right"}>
										{formatValue(totalPrice.toFixed(2))} {t("baht")}
									</Text>
								</SimpleGrid>
							</Box>
						</>
					)}
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default BillDetailModal;
