import React from "react";
import {
	Box,
	Container,
	Flex,
	Heading,
	Image,
	Text,
	List,
	ListItem,
	useColorModeValue,
} from "@chakra-ui/react";
import HomeFood from "../../images/HomeFood.jpg";

const CounterThumb = ({ number, text, subtext }) => {
	return (
		<Box>
			<Flex align="center">
				<Text
					fontSize="lg"
					fontWeight="400"
					color={useColorModeValue("gray.800", "white")}
				>
					{text}
				</Text>
				<Text
					fontSize="3xl"
					fontWeight="bold"
					color={useColorModeValue("orange.400", "white")}
					mx={3}
				>
					{number}
				</Text>
				<Text fontSize="lg" fontWeight="400">
					{subtext}
				</Text>
			</Flex>
		</Box>
	);
};

const Statistics = () => {
	return (
		<Box
			py={{ base: 8, md: 12, lg: 16, xl: 20 }}
			bg={useColorModeValue("white", "gray.800")}
			id="section_2"
		>
			<Container maxW="7xl">
				<Flex
					flexDirection={{ base: "column", lg: "row" }}
					alignItems="center"
					gap={{ lg: 10, xl: 20 }}
				>
					<Box flex="1" display={{ base: "none", lg: "block" }}>
						<Image src={HomeFood} alt="Statistics Image" borderRadius="2xl" />
					</Box>
					<Box flex="1" ml={{ lg: 8 }}>
						<Box mb={{ base: 2, md: 6 }}>
							<Heading as="h2" size="xl" mb={2}>
								สมาชิก
							</Heading>
							<Heading as="h5" size="md" mb={3} color={"cyan.700"}>
								จำนวนผู้สนใจสมัครใช้งานแล้ว
							</Heading>
							<Text>
								ยกระดับการบริการในธุรกิจของคุณด้วยเทคโนโลยี QR Menu!
								ไม่ว่าจะเป็นร้านอาหารหรือที่พักต่างๆ
								ลูกค้าของคุณสามารถสแกนเพื่อสั่งซื้อสินค้าและบริการได้ทันที
								ลดความผิดพลาด เพิ่มความสะดวกสบาย และช่วยเพิ่มรายได้ เริ่มใช้ QR
								Menu วันนี้!
							</Text>
						</Box>
						<Box>
							<List spacing={{ base: 0, md: 2 }}>
								<ListItem display="flex" alignItems="center">
									<CounterThumb
										number="60"
										text="เกินกว่า"
										subtext="ผู้ใช้งาน"
									/>
								</ListItem>
								<ListItem display="flex" alignItems="center">
									<CounterThumb
										number="4500"
										text="สั่งซื้อไปแล้วกว่า"
										subtext="รายการ"
									/>
								</ListItem>
							</List>
						</Box>
					</Box>
				</Flex>
			</Container>
		</Box>
	);
};

export default Statistics;
