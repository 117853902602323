import {
	Container,
	Stack,
	Flex,
	Box,
	Heading,
	Text,
	Button,
	Icon,
	useColorModeValue,
	useDisclosure,
	Modal,
	ModalOverlay,
	ModalContent,
} from "@chakra-ui/react";
import { FaBoxesPacking } from "react-icons/fa6";
import video_cover from "../../images/video_cover.png";
import RegisterButton from "./RegisterButton";
import ExampleButton from "./ExampleButton";

export default function Hero({ scrollToBox, price }) {
	const { isOpen, onOpen, onClose } = useDisclosure();
	return (
		<>
			<Container maxW={"7xl"} mt={10}>
				<Stack
					align={"center"}
					spacing={{ base: 8, md: 10 }}
					py={{ base: 0, md: 10, lg: 16, xl: 20 }}
					direction={{ base: "column", md: "row" }}
				>
					<Stack flex={1} spacing={{ base: 4, md: 6 }}>
						<Heading fontWeight={600}>
							<Text
								fontSize={{
									base: "1.3em",
									md: "1.1em",
									lg: "1.3em",
									xl: "1.5em",
								}}
								w="full"
								bgClip="text"
								bgGradient="linear(to-r, cyan.700,orange.300)"
								fontWeight="extrabold"
								zIndex={1}
							>
								ระบบขายหน้าร้าน POS และ QR Menu
							</Text>
						</Heading>
						<Text color={"gray.500"}>
							เปลี่ยนการสั่งอาหารให้เป็นเรื่องง่าย ใช้ QR Menu
							กับระบบจ้ดการออเดอร์ขายหน้าร้าน
							ช่วยลดภาระงานพนักงานและเพิ่มยอดขายของร้านคุณ ลองเลยตอนนี้!
						</Text>
						<Flex
							direction={{ base: "column", md: "row" }}
							gap={{ base: 4, sm: 6 }}
						>
							<RegisterButton price={price} businessType={"restaurant"} />
							<Stack spacing={{ base: 4, sm: 6 }} direction={"row"}>
								<ExampleButton />
								<Button
									fontWeight={"normal"}
									onClick={scrollToBox}
									leftIcon={<FaBoxesPacking color={"gray.300"} />}
									color={"cyan.700"}
									bg={"gray.50"}
									_dark={{ bg: "gray.600" }}
									boxShadow={"md"}
									_hover={"none"}
									w={{ base: "full", md: "auto" }}
								>
									ตัวอย่างธุรกิจ
								</Button>
							</Stack>
						</Flex>
					</Stack>
					<Flex
						flex={1}
						justify={"center"}
						align={"center"}
						position={"relative"}
						w={"full"}
					>
						<Blob
							w={"100%"}
							h={"150%"}
							position={"absolute"}
							top={"-20%"}
							zIndex={-1}
							color={useColorModeValue("cyan.50", "cyan.600")}
						/>
						<Box
							position={"relative"}
							height={{ base: "250px", md: "300px" }}
							rounded={{ base: "none", md: "xl" }}
							boxShadow={"md"}
							width={{ base: "100%", md: "90%" }}
							overflow={"hidden"}
							bgImage={`url(${video_cover})`}
							bgSize="cover"
							bgPosition="center"
							onClick={onOpen}
							cursor={"pointer"}
						></Box>
					</Flex>
				</Stack>
			</Container>
			<Modal isOpen={isOpen} onClose={onClose} isCentered>
				<ModalOverlay />
				<ModalContent
					maxW={{ base: "100vw", md: "60vw" }}
					p={0}
					bg="transparent"
					boxShadow="none"
				>
					<Box
						position="relative"
						width="100%"
						paddingTop="56.25%" // 16:9 aspect ratio
					>
						<iframe
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								width: "100%",
								height: "100%",
								border: 0,
							}}
							src="https://www.youtube.com/embed/32A1-Tb0jcY?si=bCFrBZ0AKT--Ftpo&amp;start=0"
							title="ระบบขายหน้าร้าน POS และ QR Menu"
							frameborder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
							referrerpolicy="strict-origin-when-cross-origin"
							allowfullscreen
						></iframe>
					</Box>
				</ModalContent>
			</Modal>
		</>
	);
}

const Blob = (props) => {
	return (
		<Icon
			width={"100%"}
			viewBox="0 0 578 440"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			{...props}
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
				fill="currentColor"
			/>
		</Icon>
	);
};
