import React from "react";
import {
	Container,
	useColorModeValue,
	Heading,
	Flex,
	Icon,
	Text,
	Box,
} from "@chakra-ui/react";
import { BiSolidCheckCircle } from "react-icons/bi";

function Update() {
	return (
		<>
			<Container
				mt={{ base: "auto", md: 10 }}
				maxW={"4xl"}
				p={{ base: 3, md: 7 }}
				bgColor={useColorModeValue("white", "gray.700")}
				borderRadius={"1em"}
			>
				<Heading as="h4" size="md" mb={5}>
					รายการอัพเดทใหม่
				</Heading>
				<Box pl={{ base: "auto", md: 3 }}>
					<Flex mb={3}>
						<Icon as={BiSolidCheckCircle} color="cyan.800" fontSize={"1.5em"} />
						<Text ml={3}>เพิ่มปุ่มเรียกพนักงาน</Text>
					</Flex>
					<Flex mb={3} display={"none"}>
						<Icon as={BiSolidCheckCircle} color="cyan.800" fontSize={"1.5em"} />
						<Text ml={3}>
							เพิ่มพนักสามารถรับออเดอร์ลูกค้าที่โต๊ะได้ (สำหรับร้านอาหาร)
						</Text>
					</Flex>
					{/* <Flex mb={3}>
						<Icon as={BiSolidPlusCircle} color="gray.300" fontSize={"1.5em"} />
						<Text ml={3}>กำหนดโค๊ตสถานที่เองได้</Text>
					</Flex>
					<Flex mb={3}>
						<Icon as={BiSolidPlusCircle} color="gray.300" fontSize={"1.5em"} />
						<Text ml={3}>
							ลบออเดอร์ในคำสั่งซื้อเมนูแดชบอร์ด - คำสั่งซื้อล่าสุด -
							หน้าต่างรายการได้
						</Text>
					</Flex>
					<Flex mb={3}>
						<Icon as={BiSolidPlusCircle} color="gray.300" fontSize={"1.5em"} />
						<Text ml={3}>เพิ่มภาษาอังกฤษ</Text>
					</Flex>
					<Flex mb={3}>
						<Icon as={BiSolidPlusCircle} color="gray.300" fontSize={"1.5em"} />
						<Text ml={3}>สามารถเปลี่ยนธีมสีเว็บและหน้าเมนูสั่งซื้อ</Text>
					</Flex> */}
				</Box>
			</Container>
		</>
	);
}

export default Update;
