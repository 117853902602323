import React from "react";
import {
	SimpleGrid,
	Box,
	Flex,
	Icon,
	Heading,
	GridItem,
	Text,
	Stack,
	Image,
} from "@chakra-ui/react";
import Carousels from "./component/Carousels";
import ExampleButton from "./component/ExampleButton";
import CardRegister from "./component/CardRegister";
import res_system_1 from "../images/pmanual/res_system_1.png";
import res_system_2 from "../images/pmanual/res_system_2.png";
import res_system_3 from "../images/pmanual/res_system_3.png";

import acc_slide_1 from "../images/accommodation/acc_slide_1.jpg";
import acc_slide_2 from "../images/accommodation/acc_slide_2.jpg";
import acc_slide_3 from "../images/accommodation/acc_slide_3.jpg";
import acc_slide_4 from "../images/accommodation/acc_slide_4.jpg";
import acc_slide_5 from "../images/accommodation/acc_slide_5.jpg";
import acc_slide_6 from "../images/accommodation/acc_slide_6.jpg";
import acc_slide_7 from "../images/accommodation/acc_slide_7.jpg";
import acc_slide_8 from "../images/accommodation/acc_slide_8.jpg";
import acc_slide_9 from "../images/accommodation/acc_slide_9.jpg";
import acc_slide_10 from "../images/accommodation/acc_slide_10.jpg";
import acc_slide_11 from "../images/accommodation/acc_slide_11.jpg";

const initialSlides = [
	{
		img: acc_slide_1,
		// label: "----------------------",
	},
	{
		img: acc_slide_2,
		// label: "2 สวนอาหาร",
	},
	{
		img: acc_slide_3,
		// label: "3 สวนอาหาร",
	},
	{
		img: acc_slide_4,
		// label: "4 สวนอาหาร",
	},
	{
		img: acc_slide_5,
		// label: "5 สวนอาหาร",
	},
	{
		img: acc_slide_6,
		// label: "6 สวนอาหาร",
	},
	{
		img: acc_slide_7,
		// label: "7 สวนอาหาร",
	},
	{
		img: acc_slide_8,
		// label: "8 สวนอาหาร",
	},
	{
		img: acc_slide_9,
		// label: "9 สวนอาหาร",
	},
	{
		img: acc_slide_10,
		// label: "10 สวนอาหาร",
	},
	{
		img: acc_slide_11,
		// label: "11 สวนอาหาร",
	},
	{
		img: acc_slide_1,
		// label: "12 สวนอาหาร",
	},
];

function Accommodation() {
	const Feature = (props) => {
		return (
			<Flex mb={2}>
				<Flex shrink={0}>
					<Icon
						boxSize={5}
						mt={1}
						mr={2}
						color="brand.500"
						_dark={{
							color: "brand.300",
						}}
						viewBox="0 0 20 20"
						fill="currentColor"
					>
						<path
							fillRule="evenodd"
							d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
							clipRule="evenodd"
						></path>
					</Icon>
				</Flex>
				<Heading
					fontSize="lg"
					fontWeight="500"
					lineHeight="6"
					_light={{
						color: "gray.800",
					}}
				>
					{props.title}
				</Heading>
			</Flex>
		);
	};
	const Feature2 = (props) => {
		return (
			<Box w={"300px"}>
				<Image src={props.img} mb={2} />
				<Heading
					as={"h3"}
					mb={{ base: 1, md: 3 }}
					fontSize="lg"
					lineHeight="shorter"
					fontWeight="bold"
					_light={{
						color: "cyan.700",
					}}
					textAlign={"center"}
				>
					{props.title}
				</Heading>
			</Box>
		);
	};

	return (
		<>
			<Box px={{ base: 0, md: 5 }}>
				<Carousels initialSlides={initialSlides} />

				<Flex
					px={{ base: 2, md: 10 }}
					py={{ base: 5, md: 10, lg: 16, xl: 20 }}
					w="full"
					justifyContent="center"
					alignItems="center"
				>
					<Box px={{ base: 2, md: 8 }} mx="auto">
						<SimpleGrid
							w={"full"}
							columns={{
								base: 1,
								lg: 3,
							}}
							spacingY={{
								base: 10,
								lg: 32,
							}}
							spacingX={{
								base: 0,
								lg: 24,
							}}
						>
							<Box
								alignSelf={{ base: "center", md: "start" }}
								w={"full"}
								alignItems="center"
							>
								<Text
									mb={6}
									fontSize={{ base: "md", md: "lg" }}
									textAlign={{ base: "center", sm: "left" }}
									color="gray.600"
								>
									สำหรับเจ้าของที่พักที่ต้องการเพิ่มรายได้จากการขายสินค้าหรือบริการ
									เราจะเป็นผู้สำเสนอสิ่งนั้นให้ภายใต้ระบบ QR Menu
									ที่จะแสดงรายการสินค้าและบริการเสริมต่างๆ
									ให้ลูกค้าของทางที่พักทราบ และแจ้งเตือนมาที่ LINE
									หากมีการสั่งซื้อ
								</Text>
								<Flex gap={3} justify={{ base: "center", md: "left" }}>
									<ExampleButton />
								</Flex>
							</Box>
							<GridItem colSpan={2}>
								<Stack
									spacing={{
										base: 5,
										md: 0,
									}}
									display={"grid"}
									gridTemplateColumns={{
										base: "repeat(2,1fr)",
										xl: "repeat(3,1fr)",
									}}
									gridColumnGap={{
										md: 5,
									}}
									gridRowGap={{
										md: 8,
									}}
								>
									<Feature title="อาหารตามสั่ง"></Feature>
									<Feature title="อาหารสำเร็จรูป"></Feature>
									<Feature title="เครื่องดื่ม"></Feature>
									<Feature title="ขนมขบเคี้ยว"></Feature>
									<Feature title="ให้เช่าอุปกรณ์อิเล็กทรอนิกส์"></Feature>
									<Feature title="บริการเช่ารถ"></Feature>
									<Feature title="สปาและนวดเพื่อสุขภาพ"></Feature>
									<Feature title="บริการซักรีด"></Feature>
									<Feature title="ของฝาก ของที่ละรึก"></Feature>
									<Feature title="คอร์สสอนทำอาหารท้องถิ่น"></Feature>
									<Feature title="จัดเซ็ตอาหาร"></Feature>
									<Feature title="อื่นๆ"></Feature>
								</Stack>
							</GridItem>
						</SimpleGrid>
					</Box>
				</Flex>
			</Box>

			<Box px={{ base: 0, md: 5 }} py={{ base: 4, md: 8, xl: 10 }}>
				<Flex w="auto" justifyContent="center" alignItems="center" gap={10}>
					<Feature2 title="แอดมินจัดการร้าน" img={res_system_1}></Feature2>
					<Feature2 title="ใช้งาน QR Menu" img={res_system_2}></Feature2>
					<Feature2 title="แจ้งรายการผ่าน LINE" img={res_system_3}></Feature2>
				</Flex>
			</Box>

			<Box>
				<CardRegister />
			</Box>
		</>
	);
}

export default Accommodation;
