import React, { useState, useEffect } from "react";
import {
	Stack,
	Flex,
	Box,
	Image,
	Heading,
	Text,
	SimpleGrid,
	Fade,
	ScaleFade,
	Button,
} from "@chakra-ui/react";
import RegisterButton from "./component/RegisterButton";
import BgImg from "../images/bg_monitor.jpg";
import Main1 from "../images/acc/main1.jpg";
import Main2 from "../images/acc/main2.jpg";
import Main3 from "../images/acc/main3.jpg";
import Main4 from "../images/acc/main4.jpg";
import Main5 from "../images/acc/main5.jpg";
import Main6 from "../images/acc/main6.jpg";
import Img1 from "../images/acc/1.jpg";
import Img2 from "../images/acc/2.jpg";
import Img3 from "../images/acc/3.jpg";
import Img4 from "../images/acc/4.jpg";
import Img5 from "../images/acc/5.jpg";
import Img6 from "../images/acc/6.jpg";
import Img7 from "../images/acc/7.jpg";
import Img8 from "../images/acc/8.jpg";
import Img9 from "../images/acc/9.jpg";
import Img10 from "../images/acc/10.jpg";

function Acc() {
	const [quote, setQuote] = useState("");
	const [show, setShow] = useState(true);

	useEffect(() => {
		const quotes = [
			"เราขอเป็นส่วนหนึ่งช่วยให้ท่านมีรายได้เพิ่มขึ้น!",
			"ให้เราเป็นตัวช่วยในการเติบโตธุรกิจของคุณ!",
			"เราพร้อมเดินเคียงข้างคุณสู่ความสำเร็จ!",
			"เริ่มต้นวันนี้ เพื่อผลลัพธ์ที่ดีกว่า!",
			"การลงทุนในวันนี้ คือกำไรในวันข้างหน้า!",
			"ความสำเร็จเกิดขึ้นจากการลงมือทำ!",
			"เปลี่ยนวิสัยทัศน์ของคุณเป็นการกระทำ!",
			"อนาคตที่ดีกว่า เริ่มต้นได้ที่นี่!",
			"พร้อมที่จะก้าวไปข้างหน้าเสมอ!",
			"ก้าวแรกของความสำเร็จเริ่มที่คุณ!",
		];

		const updateQuote = () => {
			const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
			setShow(false);
			setTimeout(() => {
				setQuote(randomQuote);
				setShow(true);
			}, 300);
		};

		updateQuote();

		const intervalId = setInterval(() => {
			updateQuote();
		}, 5000);

		return () => clearInterval(intervalId);
	}, []);

	const [mainImg, setMainImg] = useState("");
	const [showMain, setShowMain] = useState(true);

	useEffect(() => {
		const mainImages = [Main1, Main2, Main3, Main4, Main5, Main6];

		const updateImage = () => {
			const randomQuote =
				mainImages[Math.floor(Math.random() * mainImages.length)];
			setShowMain(false);
			setTimeout(() => {
				setMainImg(randomQuote);
				setShowMain(true);
			}, 10);
		};

		updateImage();

		const intervalId = setInterval(() => {
			updateImage();
		}, 4000);

		return () => clearInterval(intervalId);
	}, []);

	const topImages = [Img6, Img7, Img8, Img9, Img10];
	const bottomImages = [Img1, Img2, Img3, Img4, Img5];

	const renderImages = (images) =>
		images.map((src, index) => (
			<Image
				key={index}
				src={src}
				w="full"
				borderRadius={{ base: "0.5em", md: "1em" }}
			/>
		));

	return (
		<Flex
			bgImage={BgImg}
			w="full"
			alignItems="center"
			justifyContent="center"
			minH="100vh"
			py={{ base: 0, md: 10 }}
		>
			<Box
				bg="white"
				boxShadow="md"
				borderRadius={{ base: "0.5em", md: "1em" }}
			>
				<Box
					maxW="7xl"
					w={{ md: "3xl", lg: "4xl", xl: "5xl" }}
					mx="auto"
					p={{ base: 1, md: 3 }}
				>
					<SimpleGrid
						columns={{ base: 5, md: 5 }}
						pb={{ base: 1, md: 3 }}
						spacing={{ base: 1, md: 3 }}
					>
						{renderImages(topImages)}
					</SimpleGrid>
					<ScaleFade initialScale={0.9} in={showMain}>
						<Image
							src={mainImg}
							w="full"
							borderRadius={{ base: "0.5em", md: "1em" }}
						/>
					</ScaleFade>
					<SimpleGrid
						columns={{ base: 5, md: 5 }}
						pt={{ base: 1, md: 3 }}
						spacing={{ base: 1, md: 3 }}
					>
						{renderImages(bottomImages)}
					</SimpleGrid>
				</Box>

				<Box
					maxW="7xl"
					w={{ md: "3xl", lg: "4xl", xl: "5xl" }}
					py={{ base: 6, lg: 8 }}
					px={{ base: 4, lg: 8 }}
					mx="auto"
					display={{ lg: "flex" }}
					alignItems={{ lg: "center" }}
					justifyContent={{ lg: "space-between" }}
					bgColor="gray.50"
				>
					<Heading
						as="h2"
						fontSize={{ base: "2xl", sm: "2xl", md: "3xl", lg: "4xl" }}
						letterSpacing="tight"
						color="gray.900"
						_dark={{ color: "gray.100" }}
					>
						<Text display="block" fontWeight="600">
							สมัครใช้งาน
						</Text>
						<Fade in={show}>
							<Text
								fontWeight="400"
								fontSize={{ base: 20, md: 26 }}
								color="gray.600"
								_dark={{ color: "gray.500" }}
							>
								{quote}
							</Text>
						</Fade>
					</Heading>
					<Stack
						direction={{ base: "column", sm: "row" }}
						mt={{ base: 6, lg: 0 }}
						flexShrink={{ lg: 0 }}
						spacing={3}
					>
						<Button
							color={"cyan.700"}
							bg={"white"}
							_dark={{ bg: "gray.600" }}
							fontWeight={"normal"}
							boxShadow={"md"}
							_hover={{ bg: "gray.200" }}
							onClick={() => window.open("./demo/shop02demo", "_blank")}
						>
							เมนูตัวอย่าง
						</Button>
						<RegisterButton price="auto" businessType="hotel" />
					</Stack>
				</Box>
			</Box>
		</Flex>
	);
}

export default Acc;
