import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import {
	Button,
	FormControl,
	Flex,
	Heading,
	Input,
	Stack,
	Text,
	useColorModeValue,
	useToast,
	FormLabel,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { API_BASE_URL } from "../config/apiConfig";

function ResetPassword() {
	const toast = useToast();
	const params = useParams();
	const navigate = useNavigate();

	const [token, setToken] = useState("");
	const [passwordReset, setPasswordReset] = useState("");
	const [cfPasswordReset, setCfPasswordReset] = useState("");
	const [isLoadingSave, setIsLoadingSave] = useState(false);
	useEffect(() => {
		setToken(params.token);
	}, [params]);

	const handleSubmit = async () => {
		const trimmedPasswordReset = passwordReset.trim();
		const trimmedCfPasswordReset = cfPasswordReset.trim();

		if (!trimmedPasswordReset || !trimmedCfPasswordReset) {
			toast({
				title: `กรอกรหัสผ่านใหม่และยืนยันรหัสผ่านใหม่ก่อน`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}
		if (trimmedPasswordReset !== trimmedCfPasswordReset) {
			toast({
				title: `รหัสผ่านไม่ตรงกัน`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		setIsLoadingSave(true);
		// console.log("token", token);
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/authen/reset_password`,
				{
					trimmedPasswordReset,
					token,
				},
				{}
			);
			// console.log("hi", response.data);
			if (response.data.message === "success") {
				setPasswordReset("");
				toast({
					title: `เรียบร้อย`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
				setTimeout(() => {
					navigate("/login");
				}, 4000);
			} else if (response.data.message === "empty") {
				toast({
					title: `URL ลิ้งไม่ถูกต้อง กดส่งอีเมลใหม่อีกครั้ง`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
			setIsLoadingSave(false);
		} catch (error) {
			setIsLoadingSave(false);
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};

	return (
		<>
			<Flex
				minH={"100vh"}
				align={"center"}
				justify={"center"}
				bg={useColorModeValue("gray.50", "gray.800")}
			>
				<Stack
					spacing={4}
					w={"full"}
					maxW={"md"}
					bg={useColorModeValue("white", "gray.700")}
					rounded={"xl"}
					boxShadow={"lg"}
					p={6}
					my={12}
				>
					<Heading lineHeight={1.1} fontSize={{ base: "2xl", md: "3xl" }}>
						ลืมรหัสผ่าน?
					</Heading>
					<Text
						fontSize={{ base: "sm", sm: "md" }}
						color={useColorModeValue("gray.800", "gray.400")}
					>
						ระบบจะส่งลิงก์สำหรับรีเซ็ตรหัสผ่านไปยังอีเมล
					</Text>
					<FormControl id="password">
						<FormLabel fontWeight={"bold"}>รหัสผ่านใหม่</FormLabel>
						<Input
							_placeholder={{ color: "gray.500" }}
							type="password"
							value={passwordReset}
							onChange={(e) => setPasswordReset(e.target.value)}
						/>
					</FormControl>
					<FormControl id="cfpassword">
						<FormLabel fontWeight={"bold"}>ยืนยันรหัสผ่านใหม่</FormLabel>
						<Input
							_placeholder={{ color: "gray.500" }}
							type="password"
							value={cfPasswordReset}
							onChange={(e) => setCfPasswordReset(e.target.value)}
						/>
					</FormControl>
					<Stack spacing={6}>
						<Button
							bg={"blue.400"}
							color={"white"}
							_hover={{
								bg: "blue.500",
							}}
							onClick={handleSubmit}
							isLoading={isLoadingSave}
							spinner={<BeatLoader size={8} color="white" />}
						>
							ยืนยัน
						</Button>
					</Stack>
				</Stack>
			</Flex>
		</>
	);
}

export default ResetPassword;
