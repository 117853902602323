import React from "react";
import {
	Box,
	chakra,
	Container,
	Stack,
	Text,
	useColorModeValue,
	VisuallyHidden,
	Image,
} from "@chakra-ui/react";
import { FaSquareFacebook, FaLine } from "react-icons/fa6";
import logo from "../images/logo250.png";

const Logo = (props) => {
	return <Image src={logo} h={7} />;
};

const SocialButton = ({ children, label, href }) => {
	return (
		<chakra.button
			bg={useColorModeValue("blackAlpha.100", "whiteAlpha.100")}
			rounded={"full"}
			w={8}
			h={8}
			cursor={"pointer"}
			as={"a"}
			href={href}
			display={"inline-flex"}
			alignItems={"center"}
			justifyContent={"center"}
			transition={"background 0.3s ease"}
			_hover={{
				bg: useColorModeValue("blackAlpha.200", "whiteAlpha.200"),
			}}
			target="_blank"
		>
			<VisuallyHidden>{label}</VisuallyHidden>
			{children}
		</chakra.button>
	);
};

function Footer() {
	return (
		<Box bg={useColorModeValue("cyan.800", "gray.900")} color={"gray.200"}>
			<Container
				as={Stack}
				maxW={"6xl"}
				py={4}
				direction={{ base: "column", md: "row" }}
				spacing={2}
				justify={{ base: "center", md: "space-between" }}
				align={{ base: "center", md: "center" }}
			>
				<Logo />
				<Text textAlign={"center"}>
					© 2024 sevscan templates. All rights reserved
				</Text>
				<Stack direction={"row"} spacing={2}>
					<SocialButton label={"Line"} href={"https://lin.ee/R9RbXAm"}>
						<FaLine fontSize="27px" />
					</SocialButton>
					<SocialButton
						label={"Facebook"}
						href={"https://www.facebook.com/100091347363576"}
					>
						<FaSquareFacebook fontSize="30px" />
					</SocialButton>
				</Stack>
			</Container>
		</Box>
	);
}

export default Footer;

// import React from "react";
// import {
// 	Box,
// 	Flex,
// 	HStack,
// 	Link,
// 	Image,
// 	Stack,
// 	Divider,
// 	VStack,
// 	Icon,
// 	Text,
// 	Container,
// } from "@chakra-ui/react";
// import { FaFacebookF, FaLinkedinIn } from "react-icons/fa";
// import { FiTwitter } from "react-icons/fi";
// import { GrInstagram } from "react-icons/gr";
// import ImageLogo from "../images/logo2line.png";

// function Footer() {
// 	return (
// 		<Box
// 			bg="cyan.800"
// 			_dark={{
// 				bg: "gray.900",
// 			}}
// 			color={"gray.200"}
// 		>
// 			<Container maxW="container.xl" px={0}>
// 				<Stack
// 					direction={{
// 						base: "column",
// 						lg: "row",
// 					}}
// 					w="full"
// 					justify="space-between"
// 					p={10}
// 				>
// 					<Flex justify="center">
// 						<Image
// 							// src="http://placehold.jp/2a2a2c/ffffff/200x100.png?text=Company%20Logo&css=%7B%22font-size%20%22%3A%22%2016px%22%7D"
// 							src={ImageLogo}
// 							alt="Company Logo"
// 							// rounded="lg"
// 							height={{
// 								base: "75px",
// 								lg: "75px",
// 							}}
// 							my={{
// 								base: 2,
// 								lg: 0,
// 							}}
// 						/>
// 					</Flex>
// 					<HStack
// 						alignItems="start"
// 						flex={1}
// 						justify="space-around"
// 						fontSize={{
// 							base: "12px",
// 							md: "16px",
// 						}}
// 						_dark={{
// 							color: "white",
// 						}}
// 						textAlign={{
// 							base: "center",
// 							md: "left",
// 						}}
// 					>
// 						<Flex justify="start" direction="column">
// 							<Link textTransform="uppercase">Pre-Sale FAQS</Link>
// 							<Link textTransform="uppercase">Submit a ticket</Link>
// 						</Flex>
// 						<Flex justify="start" direction="column">
// 							<Link textTransform="uppercase">Services</Link>
// 							<Link textTransform="uppercase">Theme Tweak</Link>
// 						</Flex>
// 					</HStack>
// 					<HStack
// 						alignItems="start"
// 						flex={1}
// 						justify="space-around"
// 						fontSize={{
// 							base: "12px",
// 							md: "16px",
// 						}}
// 						_dark={{
// 							color: "white",
// 						}}
// 						textAlign={{
// 							base: "center",
// 							md: "left",
// 						}}
// 					>
// 						<Flex justify="start" direction="column">
// 							<Link textTransform="uppercase">Show Case</Link>
// 							<Link textTransform="uppercase">Widget Kit</Link>
// 							<Link textTransform="uppercase">Support</Link>
// 						</Flex>
// 						<Flex justify="start" direction="column">
// 							<Link textTransform="uppercase">About Us</Link>
// 							<Link textTransform="uppercase">Contact Us</Link>
// 							<Link textTransform="uppercase">Resources</Link>
// 						</Flex>
// 					</HStack>
// 				</Stack>
// 			</Container>
// 			<Divider
// 				w="95%"
// 				mx="auto"
// 				color="gray.600"
// 				_dark={{
// 					color: "#F9FAFB",
// 				}}
// 				h="3.5px"
// 				mb={2}
// 			/>
// 			<Container maxW="container.xl" px={0}>
// 				<VStack py={3}>
// 					<HStack justify="center">
// 						<Link href={"https://www.facebook.com/100091347363576"} isExternal>
// 							<Icon
// 								_dark={{
// 									color: "white",
// 								}}
// 								h="20px"
// 								w="20px"
// 								as={FaFacebookF}
// 							/>
// 						</Link>
// 						<Link>
// 							<Icon
// 								_dark={{
// 									color: "white",
// 								}}
// 								h="20px"
// 								w="20px"
// 								as={FiTwitter}
// 							/>
// 						</Link>
// 						<Link>
// 							<Icon
// 								_dark={{
// 									color: "white",
// 								}}
// 								h="20px"
// 								w="20px"
// 								as={GrInstagram}
// 							/>
// 						</Link>
// 						<Link>
// 							<Icon
// 								_dark={{
// 									color: "white",
// 								}}
// 								h="20px"
// 								w="20px"
// 								as={FaLinkedinIn}
// 							/>
// 						</Link>
// 					</HStack>

// 					<Text
// 						textAlign="center"
// 						fontSize="smaller"
// 						_dark={{
// 							color: "white",
// 						}}
// 					>
// 						&copy;Copyright. All rights reserved.
// 					</Text>
// 				</VStack>
// 			</Container>
// 		</Box>
// 	);
// }

// export default Footer;
