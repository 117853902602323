import React, { useEffect, useState, useRef } from "react";
import {
	Box,
	IconButton,
	Image,
	ScaleFade,
	Flex,
	Spinner,
	useToast,
} from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import { useDropzone } from "react-dropzone";
import { API_BASE_URL } from "../../config/apiConfig";
import imageCompression from "browser-image-compression";

const ImageUploader = ({ productData, setProductData, methodStatus }) => {
	const toast = useToast();
	const prevImageData = useRef(productData?.image);
	const [editMode, setEditMode] = useState(false);
	const [previewImage, setPreviewImage] = useState(null);
	const [isLoadingImage, setIsLoadingImage] = useState(false);

	const onDrop = async (acceptedFiles) => {
		const droppedImage = acceptedFiles[0];
		if (!droppedImage.type.startsWith("image/")) {
			toast({
				title: `เลือกเฉพาะไฟล์ภาพเท่านั้น`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}
		setIsLoadingImage(true);
		try {
			const compressedFile = await imageCompression(droppedImage, {
				maxSizeMB: 1,
				maxWidthOrHeight: 512,
			});

			setProductData({ ...productData, image: compressedFile });
			setPreviewImage(URL.createObjectURL(compressedFile));
			setEditMode(false);
		} catch (error) {
			console.error("Image compression error:", error);
		} finally {
			setIsLoadingImage(false);
		}
	};

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: "image/*",
		multiple: false,
	});

	const handleEditClick = () => {
		setProductData({ ...productData, image: null });
		setEditMode(true);
	};

	useEffect(() => {
		if (
			methodStatus === "edit" &&
			prevImageData.current === productData?.image
		) {
			prevImageData.current = productData?.image;
			setPreviewImage(
				`${API_BASE_URL}/image/product/large/${productData?.image}`
			);
			setIsLoadingImage(true);
		}
	}, [methodStatus, productData]);

	return (
		<Box minH={200}>
			{!productData?.image && (
				<Box p={20}>
					{!isLoadingImage ? (
						<Box
							{...getRootProps()}
							border="1px dashed"
							p={4}
							textAlign="center"
							cursor="pointer"
						>
							<input {...getInputProps()} />
							<p style={{ padding: "2em 0" }}>เลือกรูปภาพสินค้าหรือบริการ</p>
						</Box>
					) : (
						<Box
							display="flex"
							justifyContent="center"
							alignItems="center"
							height="100%"
						>
							<Spinner color="red.500" />
						</Box>
					)}
				</Box>
			)}
			{productData?.image && (
				<Box position="relative" align="center" justify="center">
					<ScaleFade initialScale={0.5} in={true}>
						<Image
							src={previewImage}
							alt="Uploaded"
							maxW="80%"
							maxH="400px"
							py={5}
						/>
					</ScaleFade>
					{!editMode && (
						<Flex
							position="absolute"
							bottom="0px"
							right="5px"
							align="center"
							justify="center"
						>
							<IconButton
								aria-label="Edit Image"
								icon={<EditIcon />}
								onClick={handleEditClick}
							/>
						</Flex>
					)}
				</Box>
			)}
		</Box>
	);
};

export default ImageUploader;
