import React from "react";
import {
	Flex,
	Text,
	Link,
	ListItem,
	UnorderedList,
	Image,
	Box,
} from "@chakra-ui/react";
import line_notify_add_friend from "../../../images/line_token/line_notify_add_friend.png";
import line_notify_login from "../../../images/line_token/line_notify_login.png";
import menu_profile from "../../../images/line_token/menu_profile.png";
import build_token from "../../../images/line_token/build_token.png";
import build_token_modal from "../../../images/line_token/build_token_modal.png";
import token from "../../../images/line_token/token.png";
import add_token_site from "../../../images/line_token/add_token_site.png";
import alert_example from "../../../images/line_token/alert_example.png";

function LineNotifyManual() {
	return (
		<>
			<UnorderedList spacing={20}>
				<ListItem>
					<Text>
						ไปที่เว็บไซต์{" "}
						<Link href="https://notify-bot.line.me" isExternal color={"blue"}>
							https://notify-bot.line.me
						</Link>{" "}
						เข้าแอพ LINE และใช้กล้องในแอพเพิ่ม LINE Notify เป็นเพื่อน
						และเข้าสู่ไปที่เมนู "หน้าของฉัน"
					</Text>
					<Flex
						gap={3}
						mt={2}
						mb={4}
						flexDirection={{ base: "column", md: "row" }}
						bgColor={"gray.400"}
						p={"1em"}
						borderRadius={"lg"}
					>
						<Box>
							<Image
								src={line_notify_add_friend}
								w={{ base: "full", md: "450px" }}
							/>
						</Box>
						<Box>
							<Image
								src={line_notify_login}
								w={{ base: "full", md: "450px" }}
							/>
						</Box>
						<Box>
							<Image src={menu_profile} w={{ base: "full", md: "300px" }} />
						</Box>
					</Flex>
				</ListItem>
				<ListItem>
					<Flex flexDirection={{ base: "column", md: "column", lg: "row" }}>
						<Text mr={2}>กำหนดชื่อและเลือกห้องแชทว่าจะให้แจ้งไปที่ไหน</Text>
						<Text color={"red"}>
							*หากเลือกแบบกลุ่มหรือห้องแชตต้องเพิ่ม LINE Notify เข้าไปด้วย
						</Text>
					</Flex>
					<Flex
						mt={2}
						mb={4}
						gap={3}
						flexDirection={{ base: "column", md: "row" }}
						bgColor={"gray.400"}
						p={"1em"}
						borderRadius={"lg"}
					>
						<Box>
							<Image src={build_token} w={{ base: "full", md: "450px" }} />
						</Box>
						<Box>
							<Image
								src={build_token_modal}
								w={{ base: "full", md: "450px" }}
							/>
						</Box>
						<Box>
							<Image src={token} w={{ base: "full", md: "450px" }} />
						</Box>
					</Flex>
				</ListItem>
				<ListItem>
					<Text>
						เพิ่ม LINE Notify Token โดยไปที่เมนูแจ้งเตือนในระบบ นำ Token
						ที่ได้มาเพิ่ม กดปุ่ม "บันทึก & ทดสอบ" จะแสดงตัวอย่างคำสั่งไปที่แอพ
						LINE
					</Text>
					<Flex
						mt={2}
						mb={4}
						gap={3}
						direction={{ base: "column", md: "row" }}
						bgColor={"gray.400"}
						p={"1em"}
						borderRadius={"lg"}
					>
						<Box>
							<Image src={add_token_site} w={{ base: "full", md: "450px" }} />
						</Box>
						<Box>
							<Image src={alert_example} w={{ base: "full", md: "450px" }} />
						</Box>
					</Flex>
				</ListItem>
			</UnorderedList>
		</>
	);
}

export default LineNotifyManual;
