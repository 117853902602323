import React, { useState, useEffect } from "react";
import socket from "../../../config/socket";
import axios from "axios";
import Swal from "sweetalert2";
import {
	Card,
	Grid,
	Box,
	Flex,
	ScaleFade,
	useToast,
	useColorModeValue,
	useDisclosure,
	Drawer,
	DrawerOverlay,
	DrawerContent,
	DrawerBody,
	SimpleGrid,
	GridItem,
	Button,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
} from "@chakra-ui/react";
import { MdTableRestaurant, MdMeetingRoom } from "react-icons/md";
import {
	PiShoppingCartSimpleDuotone,
	PiListDashesDuotone,
	PiQrCodeDuotone,
} from "react-icons/pi";
import { API_BASE_URL } from "../../../config/apiConfig";
import BillDetailModalAdmin from "../other/BillDetailModalAdmin";
import MenuList from "../../../mobile/MenuList";

function MonitorUnit({ shopData, shopValueId }) {
	const [unitList, setUnitList] = useState();
	const bgCardColor = useColorModeValue("gray.100", "gray.500");
	const bgCardColorSelect = useColorModeValue("cyan.700", "cyan.800");
	const textColor = useColorModeValue("cyan.700", "white");
	const textColorSelect = useColorModeValue("white", "white");
	const toast = useToast();

	const [billDetail, setBillDetail] = useState([]);
	const [isModalBillOpen, setIsModalBillOpen] = useState(false);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		const handleUnitList = (data) => {
			// console.log("unit_list:", data);
			setUnitList(data);
		};

		socket.on("list_unit", handleUnitList);

		return () => {
			socket.off("list_unit", handleUnitList);
		};
	}, []);

	useEffect(() => {
		if (shopValueId) {
			socket.emit("get_list_unit", shopValueId);
		}
	}, [shopValueId]);

	const [nowUnitName, setNowUnitName] = useState({});
	const handleBillDetail = async (unit) => {
		setIsModalBillOpen(true);
		setLoading(true);
		setBillDetail([]);
		setNowUnitName({ name: unit.name, name_en: unit.name_en });
		try {
			const unitShopResult = { shop_id: shopValueId, unit_code: unit.code };
			const response = await axios.post(
				`${API_BASE_URL}/api/shopping/check_order`,
				{ unitShopResult }
			);
			// console.log(response.data);
			setBillDetail(response.data);
			setLoading(false);
		} catch (error) {
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "error",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};
	const handleCloseBillModal = () => {
		setIsModalBillOpen(false);
	};

	const reUnitCode = async (unit) => {
		Swal.fire({
			text: "เคลียร์ออเดอร์",
			icon: "info",
			showCancelButton: true,
			confirmButtonColor: "#109ebe",
			cancelButtonColor: "#c24a4a",
			cancelButtonText: "ยกเลิก",
			confirmButtonText: "ยืนยัน",
			reverseButtons: true,
			showClass: {
				popup: "swal2-noanimation", // No animation for showing
			},
			hideClass: {
				popup: "", // No animation for hiding
			},
		}).then(async (result) => {
			// console.log("unit", unit);
			if (result.isConfirmed) {
				try {
					const response = await axios.post(
						`${API_BASE_URL}/api/employee/re_unit_code`,
						{ unit },
						{}
					);
					const updatedUnitList = unitList.map((u) => {
						if (u.id === unit.id) {
							return { ...u, code: response.data.newCode, free_status: 0 };
						}
						return u;
					});
					setUnitList(updatedUnitList);
					socket.emit("get_list_unit", shopValueId);
				} catch (error) {
					console.error("Error re_unit_code:", error);
				}
			}
		});
	};

	const { isOpen, onOpen, onClose } = useDisclosure();
	const [nowUnitSelect, setNowUnitSelect] = useState();
	const handleCardMethod = (unit) => {
		setNowUnitSelect(unit);
		onOpen();
	};

	const [isOpenRestaurantOrder, setIsOpenRestaurantOrder] = useState(false);
	const [displayContent, setDisplayContent] = useState();
	const handleSelect = async (actionType) => {
		onClose();
		if (actionType === "order") {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/shopping/check_code`,
					{
						shopValueId,
						inputValue: nowUnitSelect.code,
					}
				);
				// console.log("menu success", response.data);
				if (response.data.message === "success") {
					setDisplayContent(<MenuList data={response.data} />);
					setIsOpenRestaurantOrder(true);
				} else {
					toast({
						title: `ไม่มีโค๊ตนี้ในร้าน`,
						position: "top",
						isClosable: true,
						status: "warning",
						variant: "top-accent",
						duration: 5000,
					});
				}
			} catch (error) {
				toast({
					title: `ไม่สามารถติดต่อระบบได้`,
					position: "top",
					isClosable: true,
					status: "error",
					variant: "top-accent",
					duration: 5000,
				});
			}
		} else if (actionType === "bill") {
			handleBillDetail(nowUnitSelect);
		} else {
			reUnitCode(nowUnitSelect);
		}
	};
	const handleCloseRestaurantOrder = () => {
		setIsOpenRestaurantOrder(false);
	};

	return (
		<>
			<Grid
				templateColumns={{
					base: "repeat(2, 1fr)",
					sm: "repeat(3, 1fr)",
					md: "repeat(4, 1fr)",
					lg: "repeat(5, 1fr)",
					xl: "repeat(6, 1fr)",
				}}
				gap={{ base: 5, md: 6 }}
			>
				{unitList &&
					unitList.map((unit, index) => (
						<ScaleFade initialScale={0.5} in={true} key={index}>
							<Card
								key={index}
								py={{ base: 1, md: 2, lg: 3 }}
								px={4}
								borderRadius={{ base: "0.7em", md: "1em" }}
								bgColor={
									unit.free_status === 0 ? bgCardColor : bgCardColorSelect
								}
								boxShadow={"0px 0px 10px 0px #bbbbbb"}
								onClick={() => handleCardMethod(unit)}
								cursor={"pointer"}
								border={"2px solid white"}
							>
								<Flex
									textAlign={"center"}
									direction={"column"}
									justifyContent={"center"}
									alignItems={"center"}
								>
									<Box
										fontSize={{ base: "4xl", md: "5xl" }}
										fontWeight={"600"}
										letterSpacing="3px"
										// color={unit.free_status === 0 ? textColor : textColorSelect}
										color={"orange.200"}
										bgColor={
											unit.free_status === 0 ? bgCardColor : bgCardColorSelect
										}
									>
										{shopData.businessType === "hotel" ? (
											<MdMeetingRoom boxSize={8} />
										) : (
											<MdTableRestaurant boxSize={8} />
										)}
									</Box>
									<Box
										fontSize={{ base: "1.2em", md: "1.5em" }}
										fontWeight={"500"}
										color={unit.free_status === 0 ? "gray.500" : "gray.200"}
									>
										{unit.name}
									</Box>
								</Flex>

								<Box
									fontSize={"2xl"}
									fontWeight={"600"}
									letterSpacing="3px"
									color={unit.free_status === 0 ? textColor : textColorSelect}
									bgColor={
										unit.free_status === 0 ? bgCardColor : bgCardColorSelect
									}
									textAlign={"right"}
									display={"none"}
								>
									{unit.code}
								</Box>
							</Card>
						</ScaleFade>
					))}
			</Grid>
			<Drawer placement={"bottom"} onClose={onClose} isOpen={isOpen}>
				<DrawerOverlay />
				<DrawerContent>
					<DrawerBody p={{ base: 3, md: 5 }}>
						<Box
							textAlign={"center"}
							fontSize={"xl"}
							fontWeight={"500"}
							mb={{ base: 2, md: 4 }}
						>
							{nowUnitSelect?.name}
						</Box>
						<SimpleGrid
							columns={{ base: 2, md: 3 }}
							spacing={{ base: 3, md: 5 }}
						>
							<GridItem colSpan={{ base: 2, md: 1 }}>
								<Button
									leftIcon={<PiShoppingCartSimpleDuotone size={"1.5em"} />}
									onClick={() => handleSelect("order")}
									borderRadius={{ base: "0.7em", md: "1em" }}
									bgColor={"#0e6979"}
									color={"white"}
									_hover={"none"}
									w={"full"}
									p={{ base: "1.5em", md: "2em" }}
								>
									สั่งซื้อ
								</Button>
							</GridItem>
							<GridItem>
								<Button
									leftIcon={<PiListDashesDuotone size={"1.5em"} />}
									onClick={() => handleSelect("bill")}
									borderRadius={{ base: "0.7em", md: "1em" }}
									bgColor={"#c05621"}
									color={"white"}
									_hover={"none"}
									w={"full"}
									p={{ base: "1.5em", md: "2em" }}
								>
									รวมบิล
								</Button>
							</GridItem>
							<GridItem>
								<Button
									leftIcon={<PiQrCodeDuotone size={"1.5em"} />}
									onClick={() => handleSelect("recode")}
									borderRadius={{ base: "0.7em", md: "1em" }}
									bgColor={"#186e95"}
									color={"white"}
									_hover={"none"}
									w={"full"}
									p={{ base: "1.5em", md: "2em" }}
								>
									เคลียร์ออเดอร์
								</Button>
							</GridItem>
						</SimpleGrid>
					</DrawerBody>
				</DrawerContent>
			</Drawer>
			<Modal
				isOpen={isOpenRestaurantOrder}
				onClose={handleCloseRestaurantOrder}
				size={"full"}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalBody p={0}>{displayContent}</ModalBody>
					<Button
						pos={"fixed"}
						top={0}
						right={14}
						zIndex={1}
						onClick={() => setIsOpenRestaurantOrder(false)}
						borderTopRadius={0}
						borderBottomRadius={"0.5em"}
						bgColor={"red.600"}
						color={"white"}
						_hover={"none"}
					>
						ปิด
					</Button>
				</ModalContent>
			</Modal>
			<BillDetailModalAdmin
				isOpen={isModalBillOpen}
				onClose={handleCloseBillModal}
				billDetail={billDetail}
				loading={loading}
				modalSize={"xl"}
				nowUnitName={nowUnitName}
				setBillDetail={setBillDetail}
				shopValueId={shopValueId}
			/>
		</>
	);
}

export default MonitorUnit;
