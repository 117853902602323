import React, { useState } from "react";
import { Flex, Box, Button, Input, useToast } from "@chakra-ui/react";
import { API_BASE_URL } from "../config/apiConfig";
import Detail from "./page/Detail";

const Monitor = () => {
	const toast = useToast();
	const [adminLogin, setAdminLogin] = useState("");
	const [loginSuccess, setLoginSuccess] = useState(0);

	const handleSubmit = async (event) => {
		event.preventDefault();

		try {
			const response = await fetch(`${API_BASE_URL}/monitor/admin_login`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({ adminLogin }),
			});

			const data = await response.json();
			if (data.message === "success") {
				setLoginSuccess(1);
			} else {
				toast({
					title: "Login Failed",
					description: data.message || "An error occurred during login.",
					status: "error",
					duration: 5000,
					isClosable: true,
				});
			}
		} catch (error) {
			console.error("Error:", error);
			toast({
				title: "Error Checking Connection",
				description: error.message,
				status: "error",
				duration: 5000,
				isClosable: true,
			});
		}
	};

	return (
		<>
			{!loginSuccess ? (
				<Flex height={"100vh"} alignItems="center" justifyContent="center">
					<Box
						p={5}
						borderWidth={1}
						borderRadius="md"
						boxShadow="sm"
						textAlign={"center"}
					>
						<form onSubmit={handleSubmit}>
							<Input
								type="text"
								placeholder="Admin Key"
								textAlign={"center"}
								value={adminLogin}
								onChange={(e) => setAdminLogin(e.target.value)}
								mb={3}
							/>
							<Button type="submit" colorScheme="teal">
								LOGIN
							</Button>
						</form>
					</Box>
				</Flex>
			) : (
				<Detail />
			)}
		</>
	);
};

export default Monitor;
