import React, { useEffect } from "react";
import Chart from "chart.js/auto";

const BarChartIncome = ({ data }) => {
	// console.log("barchart_data", data);

	const thaiDateTime = (dateTimeString) => {
		const dateTime = new Date(dateTimeString);
		const options = {
			month: "short",
			day: "numeric",
		};
		const thaiDateTime = dateTime.toLocaleDateString("th-TH", options);
		return thaiDateTime;
	};

	useEffect(() => {
		if (data && data.length > 0) {
			const labels = data.map((item) => thaiDateTime(item.sales_date));
			const sales = data.map((item) => item.total_sales || 0);

			// Define chart data
			const chartData = {
				labels: labels,
				datasets: [
					{
						label: "# Total Sales",
						data: sales,
						backgroundColor: (context) => {
							const index = context.dataIndex;
							if (index === 0 || index === 7) {
								return "rgba(7, 111, 131, 1)";
							} else if (index === 14) {
								return "rgba(7, 111, 131, 1)";
							} else {
								return "rgba(7, 111, 131, 0.2)";
							}
						},
						borderColor: "rgba(7, 111, 131, 1)",
						borderWidth: 1,
					},
				],
			};

			// Define chart options
			const chartOptions = {
				scales: {
					y: {
						// display: false,
						suggestedMin: 1000,
					},
					x: {
						grid: {
							display: false,
						},
					},
				},
				plugins: {
					legend: {
						display: false,
					},
				},
			};

			// Get canvas context
			const ctx = document.getElementById("myChart").getContext("2d");

			// Create new chart instance
			const myChart = new Chart(ctx, {
				type: "bar",
				data: chartData,
				options: chartOptions,
			});

			// Cleanup function
			return () => {
				myChart.destroy(); // Destroy chart to prevent memory leaks
			};
		}
	}, [data]);

	return (
		<>
			<canvas id="myChart" style={{ width: "100%", height: "32vh" }}></canvas>
		</>
	);
};

export default BarChartIncome;
