import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
	Flex,
	Box,
	Button,
	useColorModeValue,
	Modal,
	ModalOverlay,
	ModalContent,
	ModalBody,
	useDisclosure,
	ModalHeader,
	SimpleGrid,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	useToast,
	Select,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { API_BASE_URL } from "../../config/apiConfig";

function RegisterButton({ price, businessType }) {
	const toast = useToast();
	const navigate = useNavigate();
	const { isOpen, onOpen, onClose } = useDisclosure();
	const bgColor = useColorModeValue("gray.50", "gray.600");

	const initialRef = React.useRef(null);
	const [show, setShow] = useState(false);
	const handleClickShowPW = () => setShow(!show);

	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [businessName, setBusinessName] = useState("");
	const [busType, setBusType] = useState(businessType);

	const handleSave = async () => {
		if (
			firstName.trim() === "" ||
			lastName.trim() === "" ||
			email.trim() === "" ||
			password.trim() === "" ||
			businessName.trim() === ""
		) {
			toast({
				title: `โปรดกรอกข้อมูลให้ครบ`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		if (!emailRegex.test(email)) {
			toast({
				title: `รูปแบบอีเมลไม่ถูกต้อง`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}
		// console.log(
		// 	firstName,
		// 	lastName,
		// 	email,
		// 	password,
		// 	businessName,
		// );
		setIsLoadingSave(true);
		try {
			const response = await axios.post(
				`${API_BASE_URL}/api/marketing/register_from_princing`,
				{
					firstName,
					lastName,
					email,
					password,
					businessName,
					businessType: busType,
				}
			);
			// console.log("response register_from_princing", response);
			setIsLoadingSave(false);
			if (response.data.message === "success") {
				window.fbq("track", "Register_success");
				onClose();
				toast({
					title: `เรียบร้อย`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 5000,
				});
				setTimeout(() => {
					navigate("/login");
				}, 1000);
			}
		} catch (error) {
			console.error("Error register_from_princing:", error);
			setIsLoadingSave(false);
			if (
				error.response &&
				error.response.status === 400 &&
				error.response.data === "Email already exists"
			) {
				toast({
					title: `มีอีเมลนี้ในระบบแล้ว`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
		}
	};

	return (
		<>
			<Button
				as={Button}
				fontWeight={"normal"}
				bgColor={"cyan.700"}
				color="white"
				_hover={{ bgColor: "cyan.800" }}
				onClick={onOpen}
			>
				สมัครใช้งาน
			</Button>

			<Modal
				initialFocusRef={initialRef}
				isOpen={isOpen}
				onClose={onClose}
				size={{ base: "full", md: "lg" }}
			>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader
						mb={0}
						pb={0}
						textAlign={"center"}
						fontWeight="500"
						fontSize="2xl"
						color={"cyan.900"}
					>
						สมัครใช้งาน
					</ModalHeader>
					<ModalBody pb={4} pt={2}>
						<SimpleGrid columns={1} spacing={5}>
							{/* <Box bgColor={bgColor} p={4} borderRadius={"1em"}>
								<FormControl mb={4} display={"none"}>
									<FormLabel>ระบบที่ต้องการ</FormLabel>
									<RadioGroup defaultValue="restaurant">
										<Stack spacing={2} direction="column">
											<Radio
												value="restaurant"
												colorScheme="blue"
												onChange={(e) => setBusinessType(e.target.value)}
											>
												<Text>สำหรับธุรกิจร้านอาหาร</Text>
											</Radio>
											<Radio
												value="hotel"
												colorScheme="blue"
												onChange={(e) => setBusinessType(e.target.value)}
											>
												<Text>สำหรับธุรกิจที่พัก</Text>
											</Radio>
										</Stack>
									</RadioGroup>
								</FormControl>
								<FormControl mb={0}>
									<FormLabel>จำนวนโต๊ะหรือห้องพัก</FormLabel>
									<RadioGroup defaultValue="ขนาดเริ่มต้น">
										<Grid templateColumns="repeat(3, 1fr)" gap={6}>
											<Radio
												value={"ขนาดเริ่มต้น"}
												colorScheme="blue"
												onChange={(e) => setSelectPlan(e.target.value)}
											>
												<Text fontSize={15}>1 - 15</Text>
											</Radio>
											<Radio
												value={"ขนาดกลาง"}
												colorScheme="blue"
												onChange={(e) => setSelectPlan(e.target.value)}
											>
												<Text fontSize={15}>16 - 30</Text>
											</Radio>
											<Radio
												value={"รองรับการเติบโต"}
												colorScheme="blue"
												onChange={(e) => setSelectPlan(e.target.value)}
											>
												<Text fontSize={15}>31 - 50</Text>
											</Radio>
										</Grid>
									</RadioGroup>
								</FormControl>
							</Box> */}
							<Box bgColor={bgColor} p={4} borderRadius={"1em"}>
								<Flex mb={4}>
									<FormControl mr={4}>
										<FormLabel>ชื่อ</FormLabel>
										<Input
											placeholder=""
											value={firstName}
											onChange={(e) => setFirstName(e.target.value)}
										/>
									</FormControl>
									<FormControl>
										<FormLabel>นามสกุล</FormLabel>
										<Input
											placeholder=""
											value={lastName}
											onChange={(e) => setLastName(e.target.value)}
										/>
									</FormControl>
								</Flex>
								<FormControl mb={4}>
									<FormLabel>อีเมล</FormLabel>
									<Input
										placeholder=""
										value={email}
										onChange={(e) => setEmail(e.target.value)}
									/>
								</FormControl>
								<FormControl mb={4}>
									<FormLabel>รหัสผ่าน</FormLabel>
									<InputGroup size="md">
										<Input
											pr="4.5rem"
											type={show ? "text" : "password"}
											placeholder=""
											value={password}
											onChange={(e) => setPassword(e.target.value)}
										/>
										<InputRightElement width="4.5rem">
											<Button h="1.75rem" size="sm" onClick={handleClickShowPW}>
												{show ? "Hide" : "Show"}
											</Button>
										</InputRightElement>
									</InputGroup>
								</FormControl>

								<FormControl mb={0}>
									<FormLabel>ชื่อร้านหรือกิจการ</FormLabel>
									<Input
										placeholder=""
										value={businessName}
										onChange={(e) => setBusinessName(e.target.value)}
									/>
								</FormControl>

								<FormControl mb={0}>
									<FormLabel>ประเภทธุรกิจ</FormLabel>
									<Select
										value={busType}
										onChange={(e) => setBusType(e.target.value)}
									>
										<option value="restaurant">ร้านอาหาร</option>
										<option value="hotel">ที่พัก</option>
									</Select>
								</FormControl>
							</Box>
						</SimpleGrid>
						<Flex justifyContent="center" width="100%" mt={5}>
							<Button colorScheme="red" onClick={onClose} mr={2}>
								ยกเลิก
							</Button>
							<Button
								colorScheme="telegram"
								isLoading={isLoadingSave}
								onClick={() => handleSave()}
								spinner={<BeatLoader size={8} color="white" />}
							>
								สมัคร
							</Button>
						</Flex>
					</ModalBody>
				</ModalContent>
			</Modal>
		</>
	);
}

export default RegisterButton;
