import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../config/apiConfig";
import axios from "axios";
import { Box, Spinner } from "@chakra-ui/react";
import MenuList from "./MenuList";

const CustomerNoCode = () => {
	const searchParams = new URLSearchParams(window.location.search);
	const shopcode = searchParams.get("shop");
	const unitcode = searchParams.get("unit");

	const navigate = useNavigate();
	const [isLoading, setIsLoading] = useState(true);
	const [error, setError] = useState(null);
	const [displayContent, setDisplayContent] = useState(null);
	const [checkLastDate, setCheckLastDate] = useState();

	useEffect(() => {
		const fetchShopData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/shopping/shop_mobile_home`,
					{ shopIdentifire: shopcode }
				);
				if (response.data.results.length === 0) {
					setError(true);
				} else if (response.data.results[0].last_day === 1) {
					setCheckLastDate(true);
				} else if (response.data.results[0].last_day === 0) {
					fetchCheckCode();
				}
			} catch (error) {
				console.error("Error fetching shop data:", error);
				setError(true);
			} finally {
				setIsLoading(false);
				fetchLimitTime();
			}
		};

		const fetchCheckCode = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/shopping/check_code`,
					{ shopIdentifire: shopcode, unit: unitcode }
				);
				if (response.data.message === "success") {
					setDisplayContent(<MenuList data={response.data} />);
				} else {
					setError(true);
				}
			} catch (err) {
				console.error("Error checking code:", err);
				setError(true);
			} finally {
				setIsLoading(false);
				fetchLimitTime();
			}
		};

		const fetchLimitTime = () => {
			window.history.replaceState({}, "", "/menu");
			const timer = setTimeout(() => {
				window.location.reload();
			}, 600000);
			return () => clearTimeout(timer);
		};

		// console.log("ss", shopcode);
		if (shopcode) {
			fetchShopData();
		}
		if (isLoading && !shopcode && !unitcode) {
			navigate("/rescan", { replace: true });
		}
	}, [isLoading, shopcode, unitcode, navigate]);

	if (isLoading) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
			>
				<Spinner size="xl" />
			</Box>
		);
	}

	if (checkLastDate) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
			>
				หมดอายุการใช้งานแล้ว
			</Box>
		);
	}

	if (error) {
		return (
			<Box
				display="flex"
				justifyContent="center"
				alignItems="center"
				height="100vh"
			>
				QR Code ไม่ถูกต้อง
			</Box>
		);
	}

	return <Box>{displayContent}</Box>;
};

export default CustomerNoCode;
