import React, { useState, useContext, useMemo, useEffect } from "react";
import axios from "axios";
import {
	Card,
	Box,
	Container,
	Input,
	Button,
	useToast,
	ScaleFade,
	Flex,
} from "@chakra-ui/react";
import { BeatLoader } from "react-spinners";
import { useAuth } from "../../hooks/AuthProvider";
import ShopContext from "../../hooks/ShopContext";
import { API_BASE_URL } from "../../config/apiConfig";

function Notification() {
	const toast = useToast();
	const { shopValueId } = useContext(ShopContext);
	const [inputTokenValue, setInputTokenValue] = useState("");
	const auth = useAuth();
	const headers = useMemo(
		() => ({
			Authorization: auth.user.token,
		}),
		[auth.user.token]
	);

	useEffect(() => {
		// console.log(shopValueId);
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/site/shop_detail`,
					{ shopValueId },
					{ headers }
				);

				// console.log(response.data);
				setInputTokenValue(response.data.results[0].token);
			} catch (error) {
				console.error("Error shop_detail and list_unit:", error);
			}
		};

		if (shopValueId && headers) {
			fetchData();
		}
	}, [toast, headers, shopValueId]);

	const handleInputChange = (event) => {
		setInputTokenValue(event.target.value);
	};

	const [isLoadingSave, setIsLoadingSave] = useState(false);
	const handleClickSave = async () => {
		const updateToken = inputTokenValue?.trim();
		// console.log("tt", updateToken);
		if (
			updateToken === null ||
			updateToken === "" ||
			updateToken === undefined
		) {
			toast({
				title: `เพิ่ม token ก่อน`,
				position: "top",
				isClosable: true,
				status: "warning",
				variant: "top-accent",
				duration: 5000,
			});
			return;
		}
		try {
			setIsLoadingSave(true);
			const response = await axios.post(
				`${API_BASE_URL}/api/site/update_token`,
				{ shopValueId, updateToken },
				{ headers }
			);
			setIsLoadingSave(false);
			// console.log(response);

			if (response.data.status === 200) {
				toast({
					title: `อัพเดท token เรียบร้อย`,
					description: `โปรดตรวจสอบที่แอพ LINE มีข้อความตัวอย่างแจ้งไปหรือไม่`,
					position: "top",
					isClosable: true,
					status: "success",
					variant: "top-accent",
					duration: 6000,
				});
			} else if (response.data.status === 400) {
				toast({
					title: `ยังไม่ได้เพิ่ม LINE Notify เข้ากลุ่มหรือเป็นเพื่อน`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			} else if (response.data.status === 401) {
				toast({
					title: `token ไม่ถูกต้อง`,
					position: "top",
					isClosable: true,
					status: "warning",
					variant: "top-accent",
					duration: 5000,
				});
			}
		} catch (error) {
			toast({
				title: `ไม่สามารถติดต่อระบบได้`,
				position: "top",
				isClosable: true,
				status: "danger",
				variant: "top-accent",
				duration: 5000,
			});
		}
	};
	return (
		<>
			<Flex
				height="calc(100vh - 10em)"
				justifyContent="center"
				alignItems="center"
			>
				<Container maxW={"3xl"} px={"none"}>
					<ScaleFade initialScale={0.1} in={true}>
						<Card boxShadow={"none"} borderRadius={"1em"}>
							<Box
								fontSize="1em"
								mb={4}
								color={"white"}
								py={3}
								borderTopRadius={"1em"}
								textAlign={"center"}
								bgColor={"cyan.700"}
							>
								LINE Notify Token
							</Box>
							<Box p={{ base: 6, md: 10 }}>
								<Input
									type="text"
									placeholder=""
									fontSize={"90%"}
									borderColor={"gray.200"}
									focusBorderColor="gray.300"
									textAlign={"center"}
									value={inputTokenValue}
									onChange={handleInputChange}
								/>
							</Box>
							<Box align="center" pb={6} pt={2}>
								<Button
									size={{ base: "sm", md: "md" }}
									borderRadius={"full"}
									border={"1.5px solid"}
									borderColor={"cyan.700"}
									color={"cyan.800"}
									bgColor={"#d0e7ec"}
									onClick={handleClickSave}
									isLoading={isLoadingSave}
									spinner={<BeatLoader size={8} color="#0987a0" />}
								>
									บันทึก & ทดสอบ
								</Button>
							</Box>
						</Card>
					</ScaleFade>
				</Container>
			</Flex>
		</>
	);
}

export default Notification;
