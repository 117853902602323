import React from "react";
import {
	Container,
	Heading,
	Text,
	Box,
	Link,
	ListItem,
	UnorderedList,
} from "@chakra-ui/react";

const PrivacyPolicy = () => {
	return (
		<Box bgColor={"gray.400"}>
			<Container maxW="container.lg" py={{ base: 10, md: 14, xl: 20 }}>
				<Box boxShadow={"xl"} bgColor={"white"} padding={{ base: 10, md: 20 }}>
					<Heading as="h1" size="md" mb={5} textAlign={"center"}>
						นโยบายความเป็นส่วนตัว
					</Heading>
					<Text mb={5}>ปรับปรุงล่าสุด: 8 กันยายน พ.ศ.2567</Text>

					<Text mb={5}>
						บริการเว็บไซต์และโมบายแอพพลิเคชั่นของเรา
						ให้ความสำคัญกับความเป็นส่วนตัวของผู้ใช้งาน
						เอกสารนี้ระบุวิธีการที่เรารวบรวมและปกป้องข้อมูลส่วนบุคคลที่ได้จากการใช้งานระบบของเรา
					</Text>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							1. ข้อมูลที่เราเก็บรวบรวม
						</Heading>
						<UnorderedList>
							<ListItem>ข้อมูลลูกค้า: ชื่อ อีเมล รหัสผ่าน ชื่อร้าน</ListItem>
							<ListItem>
								ข้อมูลร้านค้าและธุรกรรม: รายการสินค้า ประวัติการขาย
								ข้อมูลการใช้งานระบบ
							</ListItem>
						</UnorderedList>
					</Box>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							2. การใช้ข้อมูลที่เก็บรวบรวม
						</Heading>
						<Text>ข้อมูลที่เราเก็บรวบรวมจะถูกนำไปใช้ในกรณีต่าง ๆ ดังนี้:</Text>
						<UnorderedList>
							<ListItem>
								ประมวลผลคำสั่งซื้อพร้อมนำเสนอต่อลูกค้าในรูปแบบสถิติ
							</ListItem>
							<ListItem>วิเคราะห์และพัฒนาประสบการณ์การใช้งานของผู้ใช้</ListItem>
							<ListItem>จัดการและติดตามประวัติการขาย</ListItem>
							<ListItem>ให้การสนับสนุนลูกค้าและปรับปรุงบริการ</ListItem>
							<ListItem>ปฏิบัติตามข้อกำหนดทางกฎหมายและความปลอดภัย</ListItem>
						</UnorderedList>
					</Box>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							3. การแบ่งปันข้อมูลกับบุคคลที่สาม
						</Heading>
						<Text>
							เราอาจแบ่งปันข้อมูลของคุณกับบุคคลที่สามเฉพาะในกรณีที่จำเป็นต่อการให้บริการ
							เช่น:
						</Text>
						<UnorderedList>
							<ListItem>ผู้ให้บริการระบบคลาวด์และเทคโนโลยีสนับสนุน</ListItem>
							<ListItem>
								ผู้ให้บริการวิเคราะห์ข้อมูลเพื่อการพัฒนาบริการ
							</ListItem>
						</UnorderedList>
					</Box>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							4. การรักษาความปลอดภัยของข้อมูล
						</Heading>
						<Text>
							เรามีมาตรการความปลอดภัยเพื่อปกป้องข้อมูลส่วนบุคคลของคุณจากการเข้าถึงหรือใช้โดยไม่ได้รับอนุญาต
							เช่น การเข้ารหัสข้อมูล การควบคุมการเข้าถึง และการสำรองข้อมูล
						</Text>
					</Box>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							5. สิทธิของผู้ใช้
						</Heading>
						<Text>
							คุณมีสิทธิ์ในการเข้าถึง แก้ไข
							หรือลบข้อมูลส่วนบุคคลหรือข้อมูลร้านของคุณที่เราเก็บรวบรวม
						</Text>
					</Box>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							6. ระยะเวลาการเก็บรักษาข้อมูล
						</Heading>
						<Text>
							เราจะเก็บข้อมูลส่วนบุคคลของคุณไว้ตราบเท่าที่จำเป็นเพื่อวัตถุประสงค์ในการให้บริการ
							และตามระยะเวลาที่กฎหมายกำหนด
						</Text>
					</Box>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							7. การเปลี่ยนแปลงนโยบาย
						</Heading>
						<Text>
							นโยบายความเป็นส่วนตัวนี้อาจมีการเปลี่ยนแปลงตามความเหมาะสม
							หากมีการเปลี่ยนแปลงใด ๆ
							เราจะแจ้งให้คุณทราบผ่านทางแอปพลิเคชันหรือเว็บไซต์ของเรา
						</Text>
					</Box>

					<Box mb={8}>
						<Heading as="h2" size="md" mb={3}>
							8. การติดต่อเรา
						</Heading>
						<Text mb={5}>
							หากคุณมีคำถามเกี่ยวกับนโยบายความเป็นส่วนตัวนี้หรือวิธีการจัดการข้อมูลของคุณ
							กรุณาติดต่อเราได้ที่:
						</Text>
						<Text>
							อีเมล:{" "}
							<Link href="mailto:sevscan.info@gmail.com" color="blue.500">
								sevscan.info@gmail.com
							</Link>
							<br />
							หมายเลขโทรศัพท์: 087-569-9325
						</Text>
					</Box>
				</Box>
			</Container>
		</Box>
	);
};

export default PrivacyPolicy;
