import React, { useState, useEffect } from "react";
import axios from "axios";
import socket from "../../../config/socket";
import { Box, IconButton } from "@chakra-ui/react";
import { AddIcon, ViewIcon } from "@chakra-ui/icons";
import { API_BASE_URL } from "../../../config/apiConfig";
import HistoryOrder from "./HistoryOrder";
import MonitorUnit from "./MonitorUnit";

function EmployeeDisplay({ shopData }) {
	// console.log(shopValueId);

	const shopValueId = shopData.shopId;

	const [flashLights, setFlashLights] = useState(
		window.navigator.onLine ? true : false
	);
	const [checkLastDate, setCheckLastDate] = useState();
	const [displayOrder, setDisplayOrder] = useState(true);

	useEffect(() => {
		socket.emit("join_room", shopData.shopId);

		socket.on("connect", () => {
			setFlashLights(true);
			socket.emit("join_room", shopData.shopId);
		});
		socket.on("disconnect", () => {
			socket.emit("leave_room", shopData.shopId);
			setFlashLights(false);
		});
	}, [shopData]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await axios.post(
					`${API_BASE_URL}/api/employee/check_last_day`,
					{ shopValueId },
					{}
				);
				// console.log("last_day", response.data);
				setCheckLastDate(response.data.results[0].last_day);
			} catch (error) {
				setFlashLights(false);
			}
		};

		if (shopValueId) {
			fetchData();
		}
	}, [shopValueId]);

	if (!flashLights) {
		return (
			<Box
				px={2}
				fontSize={"md"}
				color={"red"}
				borderRadius={"full"}
				pos={"absolute"}
				top={"50%"}
				left={"50%"}
				transform={"translateX(-50%)"}
				whiteSpace={"nowrap"}
			>
				ไม่สามารถเชื่อมต่อระบบได้
			</Box>
		);
	}

	if (checkLastDate) {
		return (
			<Box
				px={2}
				fontSize={"md"}
				color={"red"}
				borderRadius={"full"}
				pos={"absolute"}
				top={"50%"}
				left={"50%"}
				transform={"translateX(-50%)"}
				whiteSpace={"nowrap"}
			>
				หมดอายุการใช้งานแล้ว
			</Box>
		);
	}

	return (
		<>
			{displayOrder ? (
				<HistoryOrder shopValueId={shopValueId} />
			) : (
				<MonitorUnit shopData={shopData} shopValueId={shopValueId} />
			)}

			<IconButton
				color="white"
				bgColor="cyan.800"
				_hover={"none"}
				boxShadow={"md"}
				size="lg"
				borderRadius={"full"}
				icon={displayOrder ? <AddIcon /> : <ViewIcon />}
				pos={"fixed"}
				right={{ base: 3, md: 4, xl: 5 }}
				bottom={{ base: 3, md: 4, xl: 5 }}
				onClick={() => {
					setDisplayOrder(!displayOrder);
				}}
			/>
		</>
	);
}

export default EmployeeDisplay;
